import { AxiosInstance } from 'axios';
import { IAcceptInvitation, ICreateUser, IUserMe, IUserOrganization } from '../models/User';
import { BaseHTTP } from './base.http'


class UserApi {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getAll() {
    return this.http.get<IUserOrganization[]>('v1/users/all')
  }

  getMe() {
    return this.http.get<IUserMe>('v1/users/me')
  }

  createUser(userPayload: ICreateUser) {
    return this.http.post<{ token: string }>('v1/auth/register', userPayload)
  }

  acceptInvitation(acceptInvitation: IAcceptInvitation) {
    return this.http.post<{ token: string }>('v1/auth/invitations', acceptInvitation)
  }

  signIn(firebaseToken: string) {
    return this.http.post<{ token: string }>('v1/auth/login', { token: firebaseToken })
  }
}

export default new UserApi();
