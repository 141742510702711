import { useState, Fragment } from 'react'

// mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

//data
import {
  colsPresupuesto as colsPresupuesto,
  colsAnualPresupuesto as colsAnual
} from '../../../../data/headersTables'
import { dataTableDefault } from '../../../../data/dataPresupuestos'

const ExpandableTable = ({ cols, isAnual, data }) => {
  const [expandedRows, setExpandedRows] = useState({})
  const [inputData, setInputData] = useState({})

  const currentMonth = new Date().getMonth()

  const handleToggleExpand = rowKey => {
    setExpandedRows(prev => ({
      ...prev,
      [rowKey]: !prev[rowKey]
    }))
  }

  const handleInputChange = (key, column, value) => {
    setInputData(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [column]: value
      }
    }))
  }

  const renderSecondLevelRows = (rows, parentKey) => {
    return rows.map(row => {
      const currentKey = `${parentKey}-${row.name}`

      // Aquí llenamos los inputs de la fila 'Ingresos del Giro' con los valores de organizedData
      const isIngresosDelGiro = row.name === 'Ingresos del Giro'

      return (
        <tr key={currentKey} className='bg-white'>
          <td className='pl-8 p-2 border border-white'>{row.name}</td>
          {cols.slice(1).map((col, index) => {
            const isMonthColumn = cols.includes(col) && col !== 'Total' && col !== 'Cuentas'
            const isPastMonth = isMonthColumn && index < currentMonth + 1

            // Si es la fila 'Ingresos del Giro', tomamos el valor correspondiente de organizedData
            const inputValue = (isIngresosDelGiro && data[index]?.valorAleatorio) || ''

            return (
              <td key={col} className='p-2 border border-white '>
                <input
                  type='text'
                  value={inputData[currentKey]?.[col] || inputValue}
                  onChange={e =>
                    handleInputChange(currentKey, col, e.target.value.replace(/[^0-9]/g, ''))
                  }
                  disabled={!isAnual && isPastMonth}
                  className={`w-full p-1 border-none bg-white ${
                    !isAnual && isPastMonth ? ' cursor-not-allowed' : ' focus:bg-white'
                  }`}
                />
              </td>
            )
          })}
        </tr>
      )
    })
  }

  const renderRows = (rows, parentKey = '', level = 0) => {
    return rows.map(row => {
      const currentKey = parentKey ? `${parentKey}-${row.name}` : row.name
      const isParent = !parentKey
      const rowClass = isParent
        ? 'bg-primary text-white border border-white font-bold uppercase'
        : level === 1
          ? 'bg-[#CCCCCC] border border-white'
          : level === 2
            ? 'bg-[#EAEAEA] border border-white'
            : 'bg-white border border-white'
      return (
        <Fragment key={currentKey}>
          <tr className={rowClass}>
            <td className='p-2 border border-white'>
              {row.subRows && row.subRows.length > 0 && (
                <>
                  <button
                    className={isParent ? 'float-right' : ''}
                    onClick={() => handleToggleExpand(currentKey)}
                  >
                    {expandedRows[currentKey] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </button>
                </>
              )}
              {row.name}
            </td>
            {cols.slice(1).map(col => (
              <td key={col} className='p-2 border border-white'></td>
            ))}
          </tr>
          {expandedRows[currentKey] && row.subRows && row.subRows.length > 0 && (
            <Fragment>
              {row.subRows.some(subRow => subRow.subRows && subRow.subRows.length > 0)
                ? renderRows(row.subRows, currentKey, level + 1)
                : renderSecondLevelRows(row.subRows, currentKey)}
            </Fragment>
          )}
        </Fragment>
      )
    })
  }

  return (
    <div className='overflow-auto'>
      <table className='min-w-max border border-white w-full'>
        <thead className='bg-primary text-white'>
          <tr>
            {cols.map(col => (
              <th key={col} className={`p-2 border text-left border-white`}>
                {col}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>{renderRows(dataTableDefault)}</tbody>
      </table>
    </div>
  )
}

const TablePresupuesto = ({ data }) => (
  <ExpandableTable cols={colsPresupuesto} isAnual={false} data={data} />
)
const TableAnualPresupuesto = ({ data }) => (
  <ExpandableTable cols={colsAnual} isAnual={true} data={data} />
)

export { TablePresupuesto, TableAnualPresupuesto }
