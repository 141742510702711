import { useContext, useState, ReactNode } from 'react';
import GlobalContext from './global-context';
import {IGlobalContext, ILoader, IDialog, IDialogForm} from '../models/Global-Context'
import { IBusiness } from '../models/Business';

interface GlobalContextProviderProps {
  children: ReactNode;
}

const GlobalContextProvider: React.FC<GlobalContextProviderProps> = (props) => {
  const [business, setBusiness] = useState<IBusiness | null>(null);
  const [currentBusiness, setCurrentBusiness] = useState<IBusiness | undefined>(undefined);
  const [loader, setLoader] = useState<ILoader>({ visible: false, text: '' });
  const [currentBank, setCurrentBank] = useState<string | undefined>(undefined);

  const [dialog, setDialog] = useState<IDialog>({
    title: '',
    body: '',
    btnText: '',
    open: false,
  });

  const [dialogForm, setDialogForm] = useState<IDialogForm>({
    title: '',
    body: '',
    btnTextClose: '',
    btnTextOpen: '',
    open: false,
  });

  const contextValue: IGlobalContext = {
    ui: {
      loader,
      setLoader,
      dialog,
      setDialog,
      dialogForm,
      setDialogForm,
    },
    business,
    setBusiness,
    currentBusiness,
    setCurrentBusiness,
    currentBank,
    setCurrentBank,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;

export const useGlobalContext = () => useContext(GlobalContext);

