// Components
import SideBar from './sideBar/SideBar'
import { Header } from './header/Header'

/**
 *
 * @todo change in the future for <Outlet/> instead of children
 */
const Layout = ({ children }) => {
  return (
    <div className='flex min-h-screen bg-slate-100/70'>
      <SideBar />
      <div className='flex-1 overflow-x-hidden'>
        <Header />
        <div className='mx-auto px-14 py-5'>{children}</div>
      </div>
    </div>
  )
}

export default Layout
