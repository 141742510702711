import { useState, useContext } from 'react'
import { dataInputsBanks } from '../data'
import GlobalContext from '../../../../../../../context/global-context'

//asset
import iconLupa from '../../../../../../../assets/iconos/iconLupa.png'

// componets
import { Button, Text } from '../../../../../ui/index'

import ModalDialogIndexConect from './ModalDialogIndexConect'

const DashboardConnectBank = ({ setConnectBank }) => {
  const { currentBank } = useContext(GlobalContext)
  const [bankName, setBankName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [dataBankDialog, setDataBankDialog] = useState({
    nameBank: '',
    iconBank: '',
    idBank: '',
    nameBankBD: ''
  })

  const handleOpenModal = (icon, bank, id, nameBD) => {
    setDataBankDialog({
      nameBank: bank,
      iconBank: icon,
      idBank: id,
      nameBankBD: nameBD
    })
    setOpenModal(true)
  }

  return (
    <div>
      <Text className='text-black text-4xl mb-6'>
        Conecta el banco de tu empresa para cargar tus transacciones
      </Text>
      <Text className='text-black text-2xl'>y mostrarte algunos trucos...</Text>
      {currentBank && (
        <div className='flex justify-end'>
          <Button
            onClick={setConnectBank}
            className='flex shadow-5xl border-none px-8 py-3 mt-5 rounded-2xl uppercase bg-accent2 text-white font-bold text-xs'
            title='Ir a la tabla de transacciones'
          />
        </div>
      )}
      <div className='relative mt-5'>
        <input
          className='border rounded-full pl-4 pr-10 py-4 w-full h-16 shadow-5xl border-none '
          type='text'
          value={bankName}
          onChange={e => setBankName(e.target.value)}
        />
        <img
          src={iconLupa}
          alt='Icono Banco'
          className='absolute right-3 top-1/2 transform -translate-y-1/2 w-12 h-12 filter invert'
        />
      </div>
      <div className='flex flex-col justify-center items-center sm:grid sm:grid-cols-3 gap-12 my-16'>
        {dataInputsBanks.map(bank => (
          <button
            key={bank.id}
            className='flex shadow-5xl border-none p-4 rounded-2xl'
            onClick={() => handleOpenModal(bank.icono, bank.name, bank.id, bank.nameBD)}
          >
            <div className='flex items-center justify-center gap-8'>
              <img className='w-20 h-20' src={bank.icono} alt={bank.nameInput} />
              <p>{bank.name}</p>
            </div>
          </button>
        ))}
      </div>
      <hr className='w-[100%] bg-[#CCCCCC] h-1' />
      <div className='mt-16'>
        <Text className='text-black text-2xl mb-6 font-bold'>Preguntas Frecuentes</Text>
        <Text className='text-black text-lg italic font-bold'>
          ¿Por qué tengo que registrar mis credenciales bancarias en Luca?
        </Text>
        <Text className='text-black text-lg italic font-bold'>
          ¿Que pasa si tengo más de una cuenta bancaria, como las registro?
        </Text>
      </div>
      {/* Diálogo modal */}
      <ModalDialogIndexConect
        open={openModal}
        setOpenModal={setOpenModal}
        dataBankDialog={dataBankDialog}
      />
    </div>
  )
}

export default DashboardConnectBank
