import { Text } from '../ui'
import { formatCurrency, formatInitalMayus, formatRut } from '../../utils/formatter'
import clsx from 'clsx'
import estrellaIcon from '../../../assets/svgIcons/estrella.svg'

const styles = {
  th: 'first:pl-12 last:pr-12 py-2 title-table text-left',
  td: 'first:pl-14 last:pl-2 last:pr-14 py-2 text-left',
  firstLine: 'first-line-table',
  secondLine: 'second-line-table'
}

const TableTop5 = ({ data, title, header1, header2 }) => {
  const emptyRows = Math.max(0, 5 - data?.length)

  return (
    <div className='relative overflow-hidden w-full bg-primary py-5 rounded-3xl mx-auto text-white max-w-5xl'>
      <div className='relative w-full'>
        <img 
          src={estrellaIcon} 
          alt='Estrella' 
          className='absolute -top-14 -right-10 w-32 h-32' 
          style={{ clipPath: 'inset(0 0 0 0)' }} // Esto asegura que la estrella se recorte en el contenedor
        />
        <div className='flex flex-col h-full justify-center'>
          <h2 className='text-yellow-300 text-3xl font-semibold mb-4 px-12'>{title}</h2>
          <table className='table-auto w-full'>
            <thead>
              <tr className='border-b-4 border-yellow-300'>
                <th className={styles.th}>{header1}</th>
                <th className={styles.th}>{header2}</th>
              </tr>
            </thead>
            <tbody>
              {data?.[0] ? (
                <>
                  {data?.map((cliente, index) => (
                    <tr key={index} className='gap-6 even:bg-secondary/20'>
                      <td className={clsx('overflow-x-scroll no-scrollbar', styles.td)}>
                        <Text className={clsx(styles.firstLine, 'max-w-40 whitespace-nowrap')}>
                          {cliente.razonSocial && formatInitalMayus(cliente.razonSocial)}
                        </Text>
                        <Text className={styles.secondLine}>{formatRut(cliente.rut)}</Text>
                      </td>

                      <td className={clsx('w-44 text-right', styles.td)}>
                        <Text className={clsx(styles.firstLine, 'whitespace-nowrap')}>
                          {formatCurrency(cliente.amount)}
                        </Text>
                        <Text className={styles.secondLine}>{cliente.count + ' Facturas'}</Text>
                      </td>
                    </tr>
                  ))}
                  {[...Array(emptyRows)].map((_, index) => (
                    <tr key={`empty-${index}`} className='gap-6 even:bg-secondary/20'>
                      <td className={styles.td}>
                        <Text className={clsx(styles.firstLine, 'pl-8')}>-</Text>
                        <Text className={clsx(styles.secondLine, 'pl-8 text-transparent')}>-</Text>
                      </td>
                      <td className={styles.td}>
                        <Text className={clsx(styles.firstLine, 'text-right mr-4')}>-</Text>
                        <Text className={clsx(styles.secondLine, 'text-right mr-4 text-transparent')}>
                          -
                        </Text>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td className={styles.td} colSpan='2'>
                      <Text className='text-lg text-center my-1.5'>No hay datos</Text>
                    </td>
                  </tr>
                  {[...Array(emptyRows).slice(1)].map((_, index) => (
                    <tr key={`empty-${index}`} className='gap-6 even:bg-secondary/20'>
                      <td className={styles.td}>
                        <Text className={clsx(styles.firstLine, 'pl-8')}>-</Text>
                        <Text className={clsx(styles.secondLine, 'pl-8 text-transparent')}>-</Text>
                      </td>
                      <td className={styles.td}>
                        <Text className={clsx(styles.firstLine, 'text-right mr-4')}>-</Text>
                        <Text className={clsx(styles.secondLine, 'text-right mr-4 text-transparent')}>
                          -
                        </Text>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TableTop5
