import { FC } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Form, Formik, FormikHelpers } from "formik"
import { Link } from "react-router-dom"
import * as Yup from 'yup'
import { ICreateUser } from "../../../../models/User"
import { FieldWithErrorMessage } from "../../ui/FieldWithErrorMsg"


export interface Step1Values extends Omit<ICreateUser, 'token'> {
  password: string;
  rePassword: string;
}

const step1ValidationSchema = Yup.object<Step1Values>({
  firstname: Yup.string().required('Ingresa tu nombre'),
  lastname: Yup.string().required('Ingresa tu apellido'),
  phoneNumber: Yup.string()
    .required('Ingresa tu teléfono')
    .max(12, 'Número de teléfono inválido')
    .min(12, 'Número de teléfono inválido')
    .test('minLength', 'Ingresa un número válido', value => value.length >= 5)
    .test(
      'startsWith+569',
      'Debe comenzar con +569',
      value => value.startsWith('+569') || value.startsWith('569')
    ),
  email: Yup.string().email('Ingresa un correo válido').required('Ingresa tu correo'),
  password: Yup.string()
    .required('Ingresa tu contraseña')
    .test('minLength', 'Debe tener al menos 8 caracteres', value => value.length >= 8)
    .test('oneUppercase', 'Debe incluir al menos una letra mayúscula', value => /[A-Z]/.test(value))
    .test('oneLowercase', 'Debe incluir al menos una letra minúscula', value =>
      /[a-z]/.test(value)
    ),
  rePassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Las contraseñas no coinciden')
    .required('Repite tu contraseña')
})


interface Props {
  title: string
  isLoading: boolean,
  initialValues: Step1Values,
  onSubmit: (values: Step1Values, actions: FormikHelpers<Step1Values>) => void,
  isInvitation?: boolean
}

export const BaseFormCreateUser: FC<Props> = ({ isLoading, initialValues, onSubmit, title, isInvitation = false }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={step1ValidationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className='px-1'>
          <div className='mb-6'>
            <h3 className='mb-3 text-3xl font-bold text-center'>{title}</h3>
            {!isInvitation && (
              <p>
                ¿Ya tienes una cuenta?{' '}
                <Link to='/auth/signin' className='text-accent1'>
                  Ingresa aquí
                </Link>
              </p>
            )}
          </div>
          <ul className='grid grid-cols-2 gap-x-8 gap-y-3'>
            <li>
              <FieldWithErrorMessage
                name='firstname'
                type='text'
                placeholder='Ingresa tu nombre'
                label='Nombre'
              />
            </li>

            <li>
              <FieldWithErrorMessage
                name='lastname'
                type='text'
                placeholder='Ingresa tu apellido'
                label='Apellido'
              />
            </li>

            <li>
              <FieldWithErrorMessage
                name='phoneNumber'
                type='text'
                placeholder='+569'
                label='TELÉFONO'
              />
            </li>

            <li>
              <FieldWithErrorMessage
                disabled={isInvitation}
                name='email'
                type='email'
                placeholder='Ingresa tu correo'
                label='CORREO'
              />
            </li>

            <li>
              <FieldWithErrorMessage
                tooltip={true}
                name='password'
                type='password'
                placeholder='Ingresa tu contraseña'
                label='Contraseña'
              />
            </li>

            <li>
              <FieldWithErrorMessage
                name='rePassword'
                type='password'
                placeholder='Repite tu contraseña'
                label='Confirmar contraseña'
              />
            </li>
          </ul>

          <div className='flex flex-col gap-2 mt-6 w-2/3 mx-auto'>
            <div>
              <button disabled={isSubmitting} type='submit' className='btn-primary'>
                {isLoading ? (
                  <span>Creando...</span>
                ) : (
                  <>
                    <span>Continuar</span>
                    <FontAwesomeIcon size='lg' icon={faArrowRight} />
                  </>
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
