import { useMutation, useQuery } from "@tanstack/react-query"
import { IAcceptInvitation, ICreateInvitation } from "../models/User"
import rolesApi from "../api/roles.api"
import userApi from "../api/user.api"
import { QueryKeys } from "./querykeys"

export const useQueryGetUserMe = () => {
  return (
    useQuery({
      queryKey: [QueryKeys.GET_USER_ME],
      queryFn: async () => {
        try {
          const res = await userApi.getMe()
          if (res.status === 200) {
            return res.data
          }
        } catch (error) {
          return null
        }
      }
    })
  )
}


export const useQueryGetUsersAll = () => {
  return useQuery({
    queryKey: [QueryKeys.GET_USERS_ALL],
    queryFn: async () => {
      try {
        const res = await userApi.getAll()
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationInviteUser = () => {
  return useMutation({
    mutationFn: async (data: ICreateInvitation) => {
      try {
        const res = await rolesApi.invitateUserWithEmail(data)
        if (res.status === 200 || res.status === 201) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationAcceptInvitation = () => {
  return useMutation({
    mutationFn: async (data: IAcceptInvitation) => {
      try {
        const res = await userApi.acceptInvitation(data)
        if (res.status === 200 || res.status === 201) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationSignIn = () => {
  return useMutation({
    mutationFn: async (firebaseToken: string) => {
      try {
        const res = await userApi.signIn(firebaseToken)
        if (res.status === 200 || res.status === 201) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}

