export const formatRut = rut => {
  let cleanRut = String(rut).replace(/[^0-9Kk-]+/g, '')
  if (!cleanRut.includes('-')) {
    cleanRut = cleanRut.slice(0, -1) + '-' + cleanRut.slice(-1)
  }
  let reversedRut = cleanRut.split('').reverse().join('')
  reversedRut = reversedRut.replace(/(\d{3})(?=\d)/g, '$1.')

  return reversedRut.split('').reverse().join('')
}

export const formatRutForBackend = rut => {
  let cleanRut = String(rut).replace(/[^0-9Kk]+/g, '')

  if (!cleanRut.includes('-')) {
    cleanRut = cleanRut.slice(0, -1) + '-' + cleanRut.slice(-1)
  }
  return cleanRut
}

export const formatCurrency = amount => {
  const formatted = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    currencyDisplay: 'symbol'
  }).format(amount)

  return formatted.replace('$', '$ ')
}

export const formatDate = dateString => {
  const date = new Date(dateString)
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const year = date.getUTCFullYear()
  return `${day}-${month}-${year}`
}

// necesito un formatDate que devuelva el formato "dd/mm/aa"
export const formatDateShort = dateString => {
  const date = new Date(dateString)
  if (isNaN(date.getTime())) {
    return 'Fecha inválida'
  }
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString().slice(-2)
  return `${day}/${month}/${year}`
}

export const formatInitalMayus = phrase => {
  return phrase.toLowerCase().replace(/(?:^|\s)\S/g, letter => letter.toUpperCase())
}
