import { AxiosInstance } from 'axios'
import { BaseHTTP } from './base.http'


export class BankAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  postBank(payload, id) {
    const path = `v1/business/${id}/bank-account`
    return this.http.post(path, payload)
  }

  postAcount(payload, id, idAccount) {
    const path = `v1/producto-bancario/business/${id}/bank-account/${idAccount}/products`
    return this.http.post(path, payload)
  }

  getTotalDeudasPagos(id) {
    const path = `v1/business/${id}/pagar-cobrar`
    return this.http.get(path)
  }

  getListBank(id) {
    const path = `v1/business/${id}/bank-account`
    return this.http.get(path)
  }

  getListAcountBank(id) {
    const path = `v1/producto-bancario/business/${id}`
    return this.http.get(path)
  }

  getTransactionsAcount(id, curretAcountId, limit, year, month) {
    const path = `v1/business/${id}/producto-bancario/${curretAcountId}/summary-movements?month=${month}&year=${year}&limit=${limit}`
    return this.http.get(path)
  }
}
