import { create } from 'zustand'

export const useContentTypeReportes = create(set => ({
  typeContent: '0',
  setTypeContent: value => set(() => ({ typeContent: value })),
  resetTypeContent: () => set(() => ({ typeContent: '0' })),
  favoriteContent: null,
  setFavoriteContent: value => set(() => ({ favoriteContent: value })),
  favoriteIcons: JSON.parse(localStorage.getItem('favoriteIcons')) || [],
  clickedIcons: new Set(JSON.parse(localStorage.getItem('clickedIcons')) || []),
  addFavoriteIcon: icon =>
    set(state => {
      if (!state.clickedIcons.has(icon.id)) {
        const updatedFavoriteIcons = [...state.favoriteIcons, icon]
        const sortedFavoriteIcons = updatedFavoriteIcons.sort((a, b) => a.id - b.id)
        const updatedClickedIcons = new Set([...state.clickedIcons, icon.id])
        localStorage.setItem('favoriteIcons', JSON.stringify(sortedFavoriteIcons))
        localStorage.setItem('clickedIcons', JSON.stringify([...updatedClickedIcons]))

        return {
          favoriteIcons: sortedFavoriteIcons,
          clickedIcons: updatedClickedIcons
        }
      }
      return state
    }),
  removeFavoriteIcon: iconId =>
    set(state => {
      const updatedFavoriteIcons = state.favoriteIcons.filter(icon => icon.id !== iconId)
      const updatedClickedIcons = new Set([...state.clickedIcons].filter(id => id !== iconId))
      localStorage.setItem('favoriteIcons', JSON.stringify(updatedFavoriteIcons))
      localStorage.setItem('clickedIcons', JSON.stringify([...updatedClickedIcons]))

      return {
        favoriteIcons: updatedFavoriteIcons,
        clickedIcons: updatedClickedIcons
      }
    })
}))
