import { FormikHelpers } from 'formik'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, } from '../../../../../firebase'
import { FC, useState } from 'react'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { BaseFormCreateUser, Step1Values } from '../../components/BaseFormCreateUser'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutationAcceptInvitation } from '../../../../../queries/userQueries'
import { UserAuth } from '../../../../../context/AuthContext'
import { BaseHTTP } from '../../../../../api/base.http'


export const FormCompleteInvitation: FC = () => {
  const acceptInvitation = useMutationAcceptInvitation()
  const [isLoading, setIsLoading] = useState(false)
  const { setIsAuthenticated } = UserAuth()
  const navigate = useNavigate()
  const [urlParams] = useSearchParams()

  const step1InitialValues: Step1Values = {
    firstname: '',
    lastname: '',
    phoneNumber: '+569',
    email: urlParams.get('email') || '',
    password: '',
    rePassword: ''
  }

  const handleSlideNextStep = async (values: Step1Values, actions: FormikHelpers<Step1Values>) => {
    setIsLoading(true)
    const formValues = {
      ...values,
      phoneNumber: values.phoneNumber?.startsWith('+') ?
        values.phoneNumber.slice(1) : values.phoneNumber
    }
    try {
      await toast.promise(handleCreateUserAccount(formValues), {
        pending: 'Creando usuario... 🚀',
        success: {
          render() {
            return 'Estamos Listos 👌'
          },
          onClose() {
            toast.success('Bienvenido a Luca')
          },
        },
        error: {
          render(error) {
            const myError = error.data as AxiosError<{ message: string }>;
            if (myError.response?.status) {
              return myError.response?.data.message;
            }
            return 'No se pudo crear el usario 🤯';
          }
        }
      })
      actions.setSubmitting(true)
    }
    catch (error) {
      actions.setFieldError('email', 'Correo ya existe')
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleCreateUserAccount = async (values: Step1Values) => {
    const userCredential = await createUserWithEmailAndPassword(auth, values?.email, values.password)
    const firebaseToken = await userCredential.user.getIdToken()
    const payload = {
      email: values.email,
      firebaseToken,
      invitationToken: urlParams.get('token') ?? '',
      firstname: values.firstname,
      lastname: values.lastname,
      phoneNumber: values.phoneNumber
    }
    const res = await acceptInvitation.mutateAsync(payload)
    BaseHTTP.getInstance().setBackendToken(res?.token ?? '')
    setIsAuthenticated(true)
    navigate('/', { replace: true })
  }

  return (
    <div
      className='relative px-7 py-9 w-[570px] h-min[460px] bg-white rounded-lg shadow-md'
    >
      <BaseFormCreateUser
        title='Completa tu registro en Luca'
        initialValues={step1InitialValues}
        onSubmit={handleSlideNextStep}
        isLoading={isLoading}
        isInvitation={urlParams.get('email') ? true : false}
      />
    </div>
  )
}
