import { useMutation } from '@tanstack/react-query'
import organizationApi from '../../../../../api/organization.api'
import { ICreateOrganization } from '../../../../../models/Organization'


export const useMutationCreateOrganization = () => {
  return useMutation({
    mutationFn: async (payload: ICreateOrganization) => {
      const res = await organizationApi.createOrganization(payload)
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        throw new Error('Error al crear la organizaciónn')
      }
    },
    retry: 2,
    retryDelay: 1_000 * 6
  })
}
