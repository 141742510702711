import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faLink, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useMemo, useState } from 'react'
import { useGlobalContext } from '../../../../../../context/GlobalState'

//assets
import imgAbono from '../../../../../../assets/iconos/icos28.png'
import imgCargo from '../../../../../../assets/iconos/icos29.png'

//components
import { ImpuestosMensuales, colors } from './VisionDelNegocio'

//mui
import { Skeleton } from '@mui/material'

//utils
import { REACT_APP_BASE_URL } from '../../../../../utils/constant'
import { BaseHTTP } from '../../../../../../api/base.http'


export default function FooterVisionDelNegocio() {
  const dataBancos = {
    labels: [
      'Banco de Bogotá',
      'Banco de Occidente',
      'Banco Santander',
      'Banco Pichincha',
      'Banco del Austro'
    ],
    datasets: [
      {
        data: [3000000, 2000000, 5000000, 1000000, 4000000],
        fill: true,
        backgroundColor: [colors.accent1, colors.accent2, colors.accent1, colors.accent2],
        tension: 0.1
      }
    ]
  }

  return (
    <div className='relative min-h-screen flex flex-col gap-10 mb-16'>
      <div className='bg-slate-200 h-20 mt-10'></div>

      <section className='grid grid-cols-5  auto-rows-auto gap-8 mt-1'>
        <div className='col-span-2 h-[320px]'>
          <AccionesRapidas />
        </div>

        <div className='col-span-3 h-[320px]'>
          <Tareas />
        </div>

        <div className='col-span-3 h-[250px]'>
          <UltimosDocumentos />
        </div>

        <div className='col-span-2 row-span-2'>
          <MovimientosBancarios />
        </div>

        <div className='col-span-3 h-[250px]'>
          <ImpuestosMensuales
            title='Impuestos mensuales'
            total={10000000}
            data={{ ...dataBancos }}
          />
        </div>
      </section>
    </div>
  )
}

const accionesRapidasLinks = [
  {
    name: 'Registrar ingreso',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  },
  {
    name: 'Registrar Gasto',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  },
  {
    name: 'Agregar Cliente',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  },
  {
    name: 'Agregar Proveedor',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  },
  {
    name: 'Plan de cuentas',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  },
  {
    name: 'Crear obligaciones',
    icon: <FontAwesomeIcon icon={faLink} size='lg' />,
    link: '/business-insights'
  }
]

const AccionesRapidas = () => {
  return (
    <div className='bg-primary h-full w-full rounded-3xl text-white/70 p-6 grid grid-cols-3 place-content-between gap-x-7 shadow-lg'>
      <h2 className='col-span-3'>Acciones rápidas</h2>

      {accionesRapidasLinks.map((link, index) => (
        <div key={index} className='flex flex-col items-center justify-center gap-1 cursor-pointer'>
          <figure className='w-12 h-12 rounded-full bg-white/30 flex items-center justify-center'>
            {link.icon}
          </figure>
          <div className='text-xs text-center'>{link.name}</div>
        </div>
      ))}
    </div>
  )
}

const tareasList = [
  {
    name: 'Conciliar factura #123 con movimientos del banco',
    status: 'pendiente',
    icon: <FontAwesomeIcon icon={faPaperPlane} size='sm' />
  },
  {
    name: 'Completar rendición de gasto #32',
    status: 'pendiente',
    icon: <FontAwesomeIcon icon={faPaperPlane} size='sm' />
  },
  {
    name: 'Conciliar factura #124 con movimientos del banco',
    status: 'pendiente',
    icon: <FontAwesomeIcon icon={faPaperPlane} size='sm' />
  },
  {
    name: 'Revisar estado de pago de facturas por cobrar',
    status: 'pendiente',
    icon: <FontAwesomeIcon icon={faPaperPlane} size='sm' />
  },
  {
    name: 'Agregar obligación para ingreso de $1.234.530',
    status: 'pendiente',
    icon: <FontAwesomeIcon icon={faPaperPlane} size='sm' />
  }
]

const Tareas = () => {
  return (
    <div className='rounded-3xl bg-white w-full h-full flex flex-col justify-between shadow-md'>
      <div className='p-6'>
        <h2 className='text-xl font-bold'>Tareas</h2>
        <p className='text-sm italic'>
          Necesitamos que nos ayudes a entender algunos de los movimientos del negocio
        </p>
      </div>

      <ul
        style={{ scrollbarWidth: 'thin', scrollbarColor: '#c7c7c7 #f5f5f5' }}
        className='flex flex-col h-[190px] overflow-y-auto pb-2 mb-4'
      >
        {tareasList.map((tarea, index) => (
          <li key={index} className='px-9 py-2 flex items-center justify-between even:bg-slate-200'>
            <div className='text-[14px] font-medium'>{tarea.name}</div>

            <div className='w-7 h-7 rounded-full bg-white shadow flex items-center justify-center text-gray-600/60 mr-1'>
              {tarea.icon}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

const movimientosList = [
  { quantity: '$150.000', type: 'Mercado Pago', transaction: 'ingreso' },
  { quantity: '$970.000', type: 'Supermercado D', transaction: 'egreso' },
  { quantity: '$150.000', type: 'Mercado Pago', transaction: 'egreso' },
  { quantity: '$970.000', type: 'Supermercado D', transaction: 'ingreso' },
  { quantity: '$150.000', type: 'Mercado Pago', transaction: 'ingreso' },
  { quantity: '$970.000', type: 'Supermercado D', transaction: 'egreso' },
  { quantity: '$150.000', type: 'Mercado Pago', transaction: 'egreso' },
  { quantity: '$970.000', type: 'Supermercado D', transaction: 'ingreso' },
  { quantity: '$150.000', type: 'Mercado Pago', transaction: 'ingreso' },
  { quantity: '$970.000', type: 'Supermercado D', transaction: 'egreso' }
]

const MovimientosBancarios = () => {
  return (
    <div className='bg-white rounded-3xl w-full h-full p-8 shadow-md'>
      <h3 className='mb-5 text-xl font-semibold'>Movimientos bancarios</h3>

      <ul className='flex flex-col gap-4'>
        {movimientosList.map((movimiento, index) => (
          <li key={index} className='flex items-center justify-between'>
            <img
              className='w-7 h-7'
              src={movimiento.transaction === 'ingreso' ? imgAbono : imgCargo}
            />
            <div
              className={`${movimiento.transaction === 'ingreso' ? 'text-green-500' : 'text-red-500'
                } text-[14px] font-semibold`}
            >
              {movimiento.quantity}
            </div>

            <div className='flex items-center justify-center text-sm'>{movimiento.type}</div>

            <FontAwesomeIcon className='text-slate-400' icon={faChevronRight} size='sm' />
          </li>
        ))}
      </ul>
    </div>
  )
}

const KEYS = {
  porCobrar: {
    title: 'Por cobrar',
    params: { type: 'boleta', state: 'por-pagar', order: 'DESC', limit: 5 }
  },
  porPagar: {
    title: 'Por pagar',
    params: { type: 'factura', state: 'por-pagar', order: 'DESC', limit: 5 }
  },
  emitidos: {
    title: 'Emitidos',
    params: { type: 'boleta', state: 'todo', order: 'DESC', limit: 5 }
  },
  recibidos: {
    title: 'Recibidos',
    params: { type: 'factura', state: 'todo', order: 'DESC', limit: 5 }
  }
}

export const SkeletonGrid = ({ minHeight = 22, length = 5 }) => (
  <>
    {length &&
      Array.from({ length }).map((_, i) => (
        <Skeleton
          sx={{ borderRadius: 5 }}
          key={i}
          variant='rounded'
          style={{ minHeight: minHeight }}
        />
      ))}
  </>
)

const UltimosDocumentos = () => {
  const { currentBusiness } = useGlobalContext()
  const [currentOptionKey, setCurrentOptionKey] = useState('porCobrar')
  const [result, setResult] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const config = {
      params: KEYS[currentOptionKey].params
    }
    setIsLoading(true)
    BaseHTTP.getInstance().http
      .get(`${REACT_APP_BASE_URL}/v1/business/${currentBusiness.id}/multi-boleta-factura`, config)
      .then(({ data }) => {
        setResult(data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }, [currentOptionKey])

  /**
   *
   * @typedef {Object} BarProps
   * @property {string} currentOptionKey - The default starting Index of the bar.
   * @property {(option: string) => void} onSelectOptionKey - A callback that fires when the user selects an option
   *
   * A component that selects an option on click.
   * @param {BarProps} props - The props for the component.
   * @returns {JSX.Element} The rendered component.
   */
  const BarFilter = ({ currentOptionKey, onSelectOptionKey }) => {
    const optionsKeys = Object.keys(KEYS)
    const memoizedOptionsKeys = useMemo(() => optionsKeys, [currentOptionKey])

    return (
      <ul
        className={`absolute flex gap-1 text-sm justify-between items-center px-5 shadow-xl right-1/2 -bottom-0 translate-x-1/2 translate-y-1/2 bg-primary text-slate-50 h-[36px] rounded-full`}
      >
        {memoizedOptionsKeys.map((selectedFilterKey, i) => (
          <li key={i}>
            <button
              type='button'
              onClick={() => onSelectOptionKey(selectedFilterKey)}
              className={`min-w-[86px] transition-all focus:font-bold active:text-primary p-[6px] rounded-full ${currentOptionKey === selectedFilterKey
                ? 'bg-white/40 font-medium'
                : 'hover:bg-white/35 active:scale-100'
                }`}
            >
              {KEYS[selectedFilterKey].title}
            </button>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className='bg-white rounded-3xl p-6 shadow-md h-full w-full relative'>
      <h3 className='font-bold text-xl mb-3'>Ultimos documentos</h3>

      <table className='w-full block'>
        <thead className='w-full block border-b-4 border-slate-200/70'>
          <tr className='flex justify-between items-center'>
            <th>N°</th>
            <th>Razón Social </th>
            <th>Rut</th>
            <th>Monto Total</th>
            <th></th>
          </tr>
        </thead>

        <tbody className='w-full mt-2 text-sm flex flex-col gap-[2px]'>
          {isLoading ? (
            <SkeletonGrid length={5} />
          ) : (
            result.data?.map(({ total, rut, razonSocial }, index) => (
              <tr key={index} className='flex justify-between items-center'>
                <td>{index + 1}</td>
                <td className='w-[90px] text-ellipsis text-nowrap overflow-hidden'>
                  {razonSocial}
                </td>
                <td>{rut}</td>
                <td>{total}</td>
                <td>
                  {' '}
                  <FontAwesomeIcon className='text-slate-400' icon={faChevronRight} size='sm' />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <BarFilter
        currentOptionKey={currentOptionKey}
        onSelectOptionKey={key => setCurrentOptionKey(key)}
      />
    </div>
  )
}
