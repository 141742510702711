import { useContentTypeReportes } from '../../../../../../pages/Reportes/useReportes.hook'
import FavoriteNavBar from './FavoriteNavBar'
import ListLibroDiario from '../../../../../../pages/Reportes/libroDiario/ListLibroDiario'
import ListLibroMayor from '../../../../../../pages/Reportes/libroMayor/ListLibroMayor'

const Index = () => {
  const favoriteContent = useContentTypeReportes(state => state.favoriteContent)
  const favoriteIcons = useContentTypeReportes(state => state.favoriteIcons)

  const renderFavoriteContent = () => {
    switch (favoriteContent) {
      case '1':
        return <div></div>
      case '2':
        return <div></div>
      case '3':
        return <div></div>
      case '4':
        return <div></div>
      case '5':
        return <div></div>
      case '6':
        return <ListLibroDiario />
      case '7':
        return <ListLibroMayor />
      case '8':
        return <div></div>
      case '9':
        return <div></div>
      default:
        return <div></div>
    }
  }

  return (
    <div>
      <FavoriteNavBar />
      {favoriteIcons.length > 0 && favoriteContent && renderFavoriteContent()}
    </div>
  )
}

export default Index
