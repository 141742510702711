import { AxiosInstance } from 'axios'
import { ICreateOrganization } from '../models/Organization'
import { BaseHTTP } from './base.http'


export class OrganizationAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  createOrganization(payload: ICreateOrganization) {
    const path = 'v1/organizations'
    return this.http.post<{ token: string }>(path, payload)
  }

  // obtener datos de un ingreso / egreso en particular desde la tabla
  getDataLibroContable(businessId, comprobanteId, type) {
    const path = `v1/business/${businessId}/libros-contables/${comprobanteId}?type=${type}`
    return this.http.get(path)
  }

  // editar  libro contable de una organización
  patchDataLibroContable(businessId, comprobanteId, payload) {
    const path = `v1/business/${businessId}/libros-contables/${comprobanteId}`
    return this.http.patch(path, payload)
  }

  //obtener el comprobante de un dato (ingreso / egreso) del libro contable
  getComprobante(params) {
    const { businessId, comprobanteId, type } = params
    const path = `v1/business/${businessId}/libro-comprobante/${comprobanteId}?type=${type}`
    return this.http.get(path)
  }

  //obtener todas cuentas de un (ingreso / egreso) del libro contable
  getAllCuentas() {
    const path = 'v1/cuenta-contable'
    return this.http.get(path)
  }

  patchEditBusiness(businessId, payload) {
    const path = `v1/business/${businessId}`
    return this.http.patch(path, payload)
  }

  getUpdatePreguntonSingleBusiness(businessId) {
    const path = `v1/business/${businessId}/update`
    return this.http.get(path)
  }

  getNotification(params){
    const { businessId } = params
    const path = `v1/business/${businessId}/notification`
    return this.http.get(path)
  }
  
}

export default new OrganizationAPI();
