import { ErrorMessage, Field, useFormikContext } from 'formik';
import { TooltipButton } from '../authentication/SignUp/components/Tooltip';
import { FC, useState } from 'react';


interface FieldWithErrorMessageProps {
  name: string;
  type: string;
  placeholder: string;
  label: string;
  tooltip?: boolean;
  disabled?: boolean;
}

export const FieldWithErrorMessage: FC<FieldWithErrorMessageProps> =
  ({ name, type, placeholder, label, tooltip = false, disabled }) => {

    const { errors, touched } = useFormikContext<Record<string, object>>();
    const [showPassword, setShowPassword] = useState(false);
    return (
      <>
        <div className='flex items-center gap-2'>
          <label htmlFor={name} className='input-label'>
            <span>{label}</span>
          </label>

          <div>{tooltip && <TooltipButton />}</div>
        </div>

        <div className='relative'>
          <Field
            disabled={disabled}
            type={showPassword ? 'text' : type}
            id={name}
            name={name}
            className={`${touched[name] && errors[name] ? 'input-error' : 'input-primary'}`}
            placeholder={placeholder}
          />
          {type === 'password' && (
            <button
              type='button'
              className='absolute right-2 top-1/2 translate-y-[-50%] text-sm font-bold'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          )}
        </div>

        <div className='h-4'>
          <ErrorMessage name={name}>
            {msg => <p className='text-orange-400 text-sm'>{msg}</p>}
          </ErrorMessage>
        </div>
      </>
    );
  }
