import { Stack, Typography, MenuItem, Select } from '@mui/material'

const FilterPagination = ({ rowsPerPage, handleChangeRowsPerPage }) => {
    return (
    <Stack direction='row' spacing={1} alignItems='center'>
        <Typography variant='body2'>Filas por página:</Typography>
        <Select
          value={rowsPerPage}
          onChange={e => handleChangeRowsPerPage(e.target.value)}
          label="Filas por página:"
          variant='outlined'
          color='primary'
          sx={{ height: '36px' }}
        >
          {[5, 10, 25, 50, 100, -1].map(value => (
            <MenuItem key={value} value={value}>
              {value === -1 ? 'Todos' : value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    )
  }

export default FilterPagination
