import clsx from 'clsx'
import { Text } from '../../../../../../ui'
import { formatCurrency } from '../../../../../../../utils/formatter'

const color = {
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  accent1: 'bg-accent1',
  accent2: 'bg-accent2',
  accent3: 'bg-accent3',
  accent4: 'bg-accent4',
  accent5: 'bg-accent5',
  accent6: 'bg-accent6'
}

const VerticalBar = ({ value = 0, height, width, label = false, barColor, textColor, stacked }) => {
  if (value === 0) {
    return (
      <span
        className={clsx(
          {
            [color[barColor]]: barColor
          },
          'rounded-t transition-all duration-500 ease-in-out'
        )}
        style={{ height: 5, width, marginLeft: stacked && -width / 2 }}
      />
    )
  }
  return (
    <div className='relative flex justify-end items-end'>
      <span
        className={clsx(
          {
            [color[barColor]]: barColor
          },
          'rounded-t transition-all duration-500 ease-in-out'
        )}
        style={{ height: height > 5 ? height : 5, width, marginLeft: stacked && -width / 2 }}
      />
      {label && (
        <Text
          className={clsx(
            'absolute bottom-4 -right-2.5 w-10 -rotate-90 text-lg font-bold text-shadow whitespace-nowrap',
            textColor || 'text-white'
          )}
        >
          {formatCurrency(value)}
        </Text>
      )}
    </div>
  )
}

export { VerticalBar }
