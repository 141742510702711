import { Paper } from '../../../../../ui'
import TableTop5 from '../../../../../table/TableTop5'
import MirrorTable from '../../../../../table/MirrorTable'
import { useGlobalContext } from '../../../../../../../context/GlobalState'
import { BusinessAPI } from '../../../../../../../api/business.api'
import { useEffect, useState } from 'react'
import ChartEgresos from './ChartEgresos'
import MirrorChart from '../../../../../charts/MirrorChart'
import { BarFilterHistoricalSearch } from '../../../../../ui/BarFilterHistoricalSearch'
import { HISTORICAL_SEARCH_KEYS } from '../../../../../data/dataHistoricalSearchKeys'

const defaultData = {
  rankingEgresos: [],
  topProveedores: [],
  porPagar: [],
  pagadas: [],
  totalPorPagar: [],
  totalPagadas: []
}

/**
 * Renders the Egresos Insights component.
 * This component displays insights related to expenses.
 */
const EgresosInsights = () => {
  const { currentBusiness } = useGlobalContext()
  const [allData, setAllData] = useState(defaultData)
  const [period, setPeriod] = useState(HISTORICAL_SEARCH_KEYS['1M'])

  const getData = async () => {
    if (currentBusiness?.id) {
      const businessAPI = new BusinessAPI()
      Promise.all([
        businessAPI.getRankingEgresos({
          businessId: currentBusiness.id,
          period: period
        }),
        businessAPI.getTopFive({
          businessId: currentBusiness.id,
          type: 'proveedor',
          period: '1M'
        }),
        businessAPI.getPorPagarPagadas({
          businessId: currentBusiness.id,
          limit: 3,
          type: 'factura',
          state: 'por-pagar',
          order: 'ASC'
        }),
        businessAPI.getPorPagarPagadas({
          businessId: currentBusiness.id,
          limit: 3,
          type: 'factura',
          state: 'pagada',
          order: 'DESC'
        }),
        businessAPI.getPorPagarPagadas({
          businessId: currentBusiness.id,
          limit: 1000,
          type: 'factura',
          state: 'por-pagar',
          order: 'ASC'
        }),
        businessAPI.getPorPagarPagadas({
          businessId: currentBusiness.id,
          limit: 1000,
          type: 'factura',
          state: 'pagada',
          order: 'DESC'
        })
      ])
        .then(
          ([rankingEgresos, topProveedores, porPagar, pagadas, totalPorPagar, totalPagadas]) => {
            setAllData({
              rankingEgresos: rankingEgresos?.data,
              topProveedores: topProveedores?.data,
              porPagar: porPagar?.data,
              pagadas: pagadas?.data,
              totalPorPagar: totalPorPagar?.data,
              totalPagadas: totalPagadas?.data
            })
          }
        )
        .catch(error => {
          console.error(error)
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  useEffect(() => {
    setAllData(defaultData)
    getData()
  }, [currentBusiness?.id, period])

  return (
    <div className='mt-16'>
      <Paper>
        <div className='relative'>
          <BarFilterHistoricalSearch
            position='top'
            short
            currentOptionKey={period}
            onSelectOptionKey={setPeriod}
          />
        </div>
        <div className='max-xl:flex-1 xl:grid xl:grid-cols-2 gap-6 max-xl:space-y-6 -mb-3 xl:-mb-11'>
          <ChartEgresos data={allData?.rankingEgresos} />
          <MirrorChart />
        </div>
      </Paper>
      <div className='max-xl:flex-1 xl:grid xl:grid-cols-2 gap-6 max-xl:space-y-6 my-6'>
        <TableTop5
          data={allData?.topProveedores}
          title='Proveedores frecuentes'
          header1='Proveedor'
          header2='Total compras'
        />
        <MirrorTable
          totalPorCobrarPagar={allData?.totalPorPagar}
          totalCobradasPagadas={allData?.totalPagadas}
          topData={allData?.porPagar}
          bottomData={allData?.pagadas}
          title1='Por pagar'
          title2='Pagadas'
        />
      </div>
    </div>
  )
}

export default EgresosInsights
