const Text = ({ variant, className, children, ...restProps }) => {
  const variantStyles = {
    none: 'text-auto',
    h1: {class: 'text-4xl font-bold', element: <h1>{children}</h1>},
    h2: {class: 'text-3xl font-semibold', element: <h2>{children}</h2>},
    h3: {class: 'text-2xl font-medium', element: <h3>{children}</h3>},
    h4: {class: 'text-xl font-medium', element: <h4>{children}</h4>},
    h5: {class: 'text-lg font-medium', element: <h5>{children}</h5>},
    h6: {class: 'text-base font-medium', element: <h6>{children}</h6>},
    subtitle: {class: 'text-lg font-normal', element: <p>{children}</p>},
    body: {class: 'text-base font-normal', element: <p>{children}</p>},
    caption: {class: 'text-sm font-normal', element: <p>{children}</p>},
    overline: {class: 'text-xs font-normal', element: <p>{children}</p>}
  }

  const typography = ` ${variantStyles[variant || ' body ']?.class} ${className}`

  return (
    <div className={typography} {...restProps}>
      {variantStyles[variant]?.element || <p>{children}</p>}
    </div>
  )
}

export default Text
