/* eslint-disable */
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigDevelopment = {
  apiKey: 'AIzaSyAFtGWnTxQqk8TyFduhuimBl-5f52NwroM',
  authDomain: 'experiments-397021.firebaseapp.com',
  projectId: 'experiments-397021',
  storageBucket: 'experiments-397021.appspot.com',
  messagingSenderId: '966022302433',
  appId: '1:966022302433:web:a0f6764c4fc57e5854c5a0',
  measurementId: 'G-XM4B02CVCX'
}

const firebaseConfigProduction = {
  apiKey: 'AIzaSyDMCMETW41D75nDEn9deULtV4DfohBA29M',
  authDomain: 'luca-app-main.firebaseapp.com',
  projectId: 'luca-app-main',
  storageBucket: 'luca-app-main.appspot.com',
  messagingSenderId: '218622650936',
  appId: '1:218622650936:web:3ab2cfdfe84bd21c0a94cd',
  measurementId: 'G-P53EPVTJKH'
}

// Determine the environment and choose the appropriate configuration
const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? firebaseConfigProduction
    : firebaseConfigDevelopment

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const analytics = getAnalytics(app)
export const auth = getAuth(app)

export default app
