import { Link, useLocation } from 'react-router-dom'
import { menuItemsDefaults } from './data.js'
import { ReactComponent as LogoLuca } from '../../../assets/logoLuca.svg'
import { ReactComponent as IsoLogo } from '../../../assets/isoLogo1.svg'
import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { useGlobalContext } from '../../../context/GlobalState'
import { toast } from 'react-toastify'
import { BaseHTTP } from '../../../api/base.http'


const Sidebar = () => {
  const { currentBusiness } = useGlobalContext()
  const location = useLocation()
  const currentPath = location.pathname

  const selectedStyle = path => currentPath !== path && 'opacity-70';


  const NavLinks = () => (
    menuItemsDefaults.map(menuItem => (
      <Link
        className={clsx(
          'flex items-center gap-4 mb-2 hover:opacity-100 py-2 px-4 border-b-2 border-secondary',
          selectedStyle(menuItem.path),
          currentPath === menuItem.path && 'cursor-default'
        )}
        key={menuItem.id}
        to={currentPath !== menuItem.path ? menuItem.path : '#'}
      >
        <menuItem.icon
          className={clsx('min-w-7 w-7', selectedStyle(menuItem.path))}
          alt={menuItem.name}
        />
        <p className={clsx('text-sm font-bold max-lg:hidden', selectedStyle(menuItem.path))}>
          {menuItem.name}
        </p>
      </Link>
    ))
  )


  const ContactSection = () => {
    return (
      <div className='flex flex-col gap-4 text-white/70'>
        <Formik initialValues={{ buscar: '' }} onSubmit={(values) => console.log(values)}>
          <Field className="w-full bg-white/30" placeholder="Buscador" name="buscar" />
        </Formik>

        <Formik
          initialValues={{ message: '' }}
          onSubmit={async (values, actions) => {
            await toast.promise(
              BaseHTTP.getInstance().http.post(
                'https://formsubmit.co/ajax/symmtec@icloud.com',
                {
                  from: currentBusiness.email,
                  organizationId: currentBusiness.id,
                  bussinesName: currentBusiness.name,
                  bussinesRut: currentBusiness.rut,
                  bussinesPhone: currentBusiness.phone,
                  message: values.message
                }
              ),
              {
                pending: 'Enviando mensaje...',
                success: 'Mensaje enviado con éxito',
                error: 'Ocurrió un error al enviar el mensaje'
              }
            )
            actions.setSubmitting(false)
            actions.resetForm()
          }
          } >
          <Form className='w-full'>
            <ul className='w-full flex flex-col gap-3'>
              <li className='flex gap-2 items-center'>
                <FontAwesomeIcon icon={faLightbulb} size='xl' />
                <h4 className='font-semibold'>¿Te gustaría sugerirnos algo?</h4>
              </li>

              <p className='text-sm'>
                Escribenos tu idea, sugerencia o cambio y con el
                equipo de desarrolladores lo haremos para tí.
              </p>

              <Field
                className="w-full bg-white/30"
                as="textarea"
                placeholder="Escribe aquí lo que te gustaría..."
                name="message"
              />

              <button type='submit' className='ml-auto w-fit btn-light text-black py-1 font-extrabold text-sm'>Enviar</button>
            </ul>

          </Form>
        </Formik>

        <div className='mt-4 border-t border-white/80 w-1/2 py-2'>Lucapp.ai &copy;</div>
      </div>
    )
  }

  return (
    <div className='transition-all sticky top-0 h-screen flex flex-col justify-between items-stretch w-36 lg:w-[18rem] p-6 pt-8 bg-primary text-secondary'>
      <div className='mb-4'>
        <div className='flex mb-12 lg:mb-12 items-center justify-center'>
          <IsoLogo className='text-secondary min-w-14 max-w-14 h-16 max-h-18' alt='Isologo' />
          <LogoLuca
            className='text-secondary max-lg:hidden w-[150px] min-w-[150px] ml-2'
            alt='Logo'
          />
        </div>

        <NavLinks />
      </div>

      <ContactSection />
    </div>
  )
}

export default Sidebar
