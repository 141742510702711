//components
import { Text } from '../../../../../ui/index'

//asset
import tiempoespera from '../../../../../../../assets/tiempoespera.png'
import escudo from '../../../../../../../assets/escudo.png'
import isoLogo1 from '../../../../../../../assets/isoLogo1.svg'
import isologosuccefull from '../../../../../../../assets/isologosuccefull.png'

export const contentModalDialogFooter = type => {
  let contentFooter
  if (type === 'successConnect') {
    contentFooter = {
      image: tiempoespera,
      firstText: 'Necesitamos un momento...',
      secondText:
        'Luca necesita unos minutos para procesar la nueva información de tu empresa y mostrarte algunos de sus trucos.',
      thirdText: '¡Vale la pena la espera!.'
    }
  } else {
    contentFooter = {
      image: escudo,
      firstText: 'Tus datos están encriptados',
      secondText:
        'Luca solo puede leer tus movimientos bancarios y cargarlos en tu sesión. No podemos hacer ningún cambio sin segunda claves.',
      thirdText: 'Nunca pediremos tu segunda clave y recomendamos no compartirla.'
    }
  }
  return (
    <div className='bg-[#CDD5DA] py-5 px-10 rounded-2xl border-none shadow-5xl flex flex-col gap-4'>
      <div className='flex items-center gap-2'>
        <img className='w-10 h-10' src={contentFooter.image} alt='Escudo' />
        <Text className='text-black text-xl text-left'>{contentFooter.firstText}</Text>
      </div>
      <Text className='text-black text-m text-left'>{contentFooter.secondText}</Text>
      <Text className='text-black text-m text-left'>{contentFooter.thirdText}</Text>
    </div>
  )
}

export const contentModalDialogHeader = type => {
  let contentHeader
  if (type === 'successConnect') {
    contentHeader = {
      image: isologosuccefull,
      firstText: '¡Conexión exitosa!'
    }
  } else {
    contentHeader = {
      image: isoLogo1,
      firstText: ' conectando'
    }
  }
  return (
    <div className='flex flex-col items-center justify-center mt-28 gap-8'>
      <Text className='text-black text-4xl text-center uppercase'>{contentHeader.firstText}</Text>
      <img
        className={`${type === 'successConnect' ? 'h-70 w-80' : 'h-80 w-80'}`}
        src={contentHeader.image}
      ></img>
    </div>
  )
}
