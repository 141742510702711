import IngresosInsights from './ingresosInsights/IngresosInsights'
import ListIngresos from './List'

const IngresosPage = () => {
  return (
    <>
      <ListIngresos />
      <IngresosInsights />
    </>
  )
}

export default IngresosPage
