import { useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

//components
import { contentModalDialogFooter } from './contentModalDialog'

//api
import { BankAPI } from '../../../../../../../api/bancos.api'

//material
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const ContentModialDialogAddBank = ({ formData, setFormData, dataBankDialog, setPage }) => {
  const { idBank, nameBankBD } = dataBankDialog
  const bankAPI = new BankAPI()
  const [showPassword, setShowPassword] = useState(false)
  const [formErrors, setFormErrors] = useState('')
  const { currentBusiness } = useContext(GlobalContext)

  // funciones para cambiar estados de banco y cuenta

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
      banco: nameBankBD
    })
    setFormErrors('')
  }

  const isFormDataValid = () => {
    // Validar campos requeridos
    const requiredFieldsEmpty =
      formData.rutUsuario.trim() === '' ||
      formData.password.trim() === '' ||
      formData.accountNumber.trim() === '' ||
      formData.accountType.trim() === ''
    const otherFieldEmpty =
      idBank === '3'
        ? formData.rol.trim() === ''
        : idBank === '1'
          ? formData.agreement.trim() === ''
          : false
    if (requiredFieldsEmpty || otherFieldEmpty) {
      setFormErrors('Por favor completa todos los campos.')
      return false
    }

    return true
  }

  // mostrar inputs dependiendo de los bancos
  const renderComponentDependenceBank = () => {
    if (idBank === '3' || idBank === '10')
      return (
        <input
          className='border-[#CCCCCC] border bg-white w-full rounded-lg'
          placeholder={idBank === '3' ? 'Rol' : 'Nombre de la Empresa'}
          name='rol'
          value={formData.rol}
          onChange={handleInputChange}
        />
      )
    if (idBank === '1')
      return (
        <input
          className='border-[#CCCCCC] border bg-white w-full rounded-lg'
          placeholder='Contrato'
          name='agreement'
          value={formData.agreement}
          onChange={handleInputChange}
        />
      )
    return null
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  // funcion submit
  const handleSubmitForm = async e => {
    e.preventDefault()
    if (!isFormDataValid()) {
      return
    }
    toastMessageBank()
  }

  // funcion crear banco
  const createBank = async () => {
    let newAccountNumber = formData.accountNumber.split('-').join('')
    let newFromData = formData
    newFromData.accountNumber = newAccountNumber

    try {
      setPage(2)
      const responseAddBank = await bankAPI.postBank(newFromData, currentBusiness.id)
      if (
        (responseAddBank.status === 200 || responseAddBank.status === 201) &&
        responseAddBank?.data
      ) {
        toast.success('Banco agregado exitosamente... 👌')
        const { id: idAccount } = responseAddBank.data
        console.log(idAccount)
        // idAccount && createAcount(idAccount)
      }
      // } else {
      //   throw new Error('Error al agregar el banco 🤯' + JSON.stringify(responseAddBank.message))
      // }
      // Si fue exitosa, realiza la segunda llamada para agregar la cuenta
    } catch (error) {
      setFormData({
        rutUsuario: '',
        password: '',
        rol: '',
        agreement: '',
        accountNumber: '',
        accountType: ''
      })
      toast.error('Error al agregar el banco 🤯' + JSON.stringify(error.response.data.message))
      console.log(error)
      setPage(1)
    }
  }

  const toastMessageBank = async () => {
    await toast.promise(createBank(), {
      pending: 'Agregando banco... 🚀'
    })
  }

  return (
    <div>
      <form className='flex flex-col items-center gap-8 p-10' onSubmit={handleSubmitForm}>
        <input
          className='border-[#CCCCCC] border bg-white w-full rounded-lg'
          placeholder='Rut Usuario'
          name='rutUsuario'
          value={formData.rutUsuario}
          onChange={handleInputChange}
        />
        {renderComponentDependenceBank()}
        <div className='relative w-full'>
          <input
            className='border-[#CCCCCC] border bg-white  rounded-lg pr-10 w-full'
            placeholder='Clave internet'
            type={showPassword ? 'text' : 'password'}
            name='password'
            value={formData.password}
            onChange={handleInputChange}
          />
          {showPassword ? (
            <VisibilityOff
              className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
              onClick={togglePasswordVisibility}
            />
          ) : (
            <Visibility
              className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
              onClick={togglePasswordVisibility}
            />
          )}
        </div>
        <input
          className='border-[#CCCCCC] border bg-white w-full rounded-lg'
          placeholder='Cuenta'
          name='accountNumber'
          type='text'
          value={formData.accountNumber}
          onChange={handleInputChange}
        />
        <select name='accountType' onChange={handleInputChange}>
          <option value='' disabled selected>
            Elija su tipo de cuenta
          </option>
          <option value='corriente'>Corriente</option>
          <option value='otro'>Otro</option>
        </select>
        {formErrors && <p className='text-red-500'>{formErrors}</p>}
        <input
          className='bg-[#002A46] text-white font-bold uppercase cursor-pointer w-48 py-2 shadow hover:shadow-lg hover:bg-opacity-90'
          type='submit'
          value='CONECTAR'
        />
      </form>
      {contentModalDialogFooter()}
    </div>
  )
}

export default ContentModialDialogAddBank
