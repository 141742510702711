import { Doughnut } from 'react-chartjs-2'

const CircleCharts = ({ data }) => {
  const chartData = {
    labels: data.map((_, index) => `Centro de costo ${index}`),
    datasets: [
      {
        data: data,
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)'
        ],
        borderWidth: 1
      }
    ]
  }

  const options = {
    cutout: '80%',
    rotation: -200,
    circumference: 360,
    plugins: {
      legend: {
        display: false
      }
    }
  }

  const styles = {
    width: '200px',
    height: '200px'
  }

  return (
    <div style={styles}>
      <Doughnut data={chartData} options={options} />
    </div>
  )
}

export default CircleCharts
