const CustomSelect = ({ label, options, className, ...props }) => {
  const selectClass = `appearance-none focus:outline-none focus:ring-0 block w-full border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-3xl shadow leading-tight${
    className || ''
  }`

  return (
    <div>
      <label htmlFor={props.id || ''} className='block text-gray-700 text-sm font-bold mb-2 ml-5'>
        {label}
      </label>
      <select id={props.id || ''} className={selectClass} {...props}>
        {options.map(({ value, label, id, disabled }, i) => (
          <option key={id || i} value={value} disabled={disabled}>
            {label || value}
          </option>
        ))}
      </select>
    </div>
  )
}

export default CustomSelect
