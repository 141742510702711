/* eslint-disable */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import 'swiper/css'
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'

const { REACT_APP_SENTRY_ENVIRONMENT, REACT_APP_SENTRY_DNS, REACT_APP_ENVIRONMENT } = process.env

if (REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DNS,
    integrations: [
      ...REACT_APP_SENTRY_ENVIRONMENT ? [new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [REACT_APP_SENTRY_ENVIRONMENT]
      })] : [],
    ],
    environment: REACT_APP_ENVIRONMENT ? REACT_APP_ENVIRONMENT : 'development',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
