import { create } from 'zustand'

export const useBanks = create(set => ({
  banksCount: 0,
  some: 1,
  setBanksCount: value => set(state => ({ ...state, banksCount: value })),
  addBankCount: () => set(state => ({ ...state, banksCount: state.banksCount + 1 }))
}))

export const useOnlyAcount = create(set => ({
  onlyAcount: false,
  setOnlyAcount: value => set(() => ({ onlyAcount: value }))
}))
