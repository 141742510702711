import { useState } from 'react'
import { UserAuth } from '../../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

// material
import { Alert, FormControl, FormHelperText, IconButton, InputAdornment } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

//components
import Input from '../ui/Input'
import CustomButton from '../ui/CustomButton'
import { useGlobalContext } from '../../../context/GlobalState'
import { toast } from 'react-toastify'

const SignInForm = () => {
  const { login } = UserAuth()
  const { ui } = useGlobalContext()
  const navigate = useNavigate()
  const [showAlert, setShowAlert] = useState(false)
  const [formValues, setFormValues] = useState({
    email: '',
    password: ''
  })
  const [errors, setErrors] = useState({
    email: '',
    password: ''
  })
  const [showPassword, setShowPassword] = useState(false)

  const handleChange = event => {
    const { name, value } = event.target
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }))
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const handleMouseDownPassword = event => event.preventDefault()

  const validateFields = () => {
    let errors = {}

    if (!formValues.email) {
      errors.email = 'Ingrese su correo electrónico.'
    }

    if (!formValues.password) {
      errors.password = 'Ingrese su contraseña.'
    }

    return errors
  }

  const simpleAlert = message => {
    setShowAlert(message)
    setTimeout(() => setShowAlert(null), 5000)
  }

  const handleErrors = response => {
    if (response.status === 500) {
      simpleAlert('Algo a pasado, contacta a soporte técnico')
    }
    if (response.status === 401) {
      setErrors({ email: 'Correo o contraseña incorrectos' })
      simpleAlert('Correo o contraseña incorrectos')
    }
    if (response.status === 404) {
      setErrors({ email: 'Usuario no encontrado' })
      simpleAlert('No se encontró el usuario en la base de datos')
    }
    if (response.status === 400) {
      setErrors({ email: 'Correo inválido' })
      simpleAlert('Correo inválido')
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const errors = validateFields()
    if (Object.keys(errors).length === 0) {
      try {
        ui.setLoader({ visible: true, text: 'Iniciando sesión...' })
        const response = await login(formValues.email, formValues.password)
        if (response.status === 200) {
          toast.success('Sesión iniciada con exito')
          return navigate('/') // refresh
        } else {
          handleErrors(response)
        }
      } finally {
        ui.setLoader({ visible: false, text: '' })
      }
    } else {
      setErrors(errors)
    }
  }

  return (
    <div className='w-[460px]'>
      <form onSubmit={handleSubmit} className='flex flex-col gap-4 p-12 shadow-md rounded-lg'>
        <div className='mb-4'>
          <h1 className='text-3xl font-bold'>Ingresa con tu cuenta en Luca</h1>

          <Link to='/auth/reset-password' className='text-accent1 hover:underline'>
            ¿Olvidaste tu contraseña?
          </Link>
        </div>

        <FormControl error={!!errors.email}>
          <Input
            name='email'
            label='CORREO'
            value={formValues.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
        </FormControl>
        <FormControl error={!!errors.password}>
          <Input
            name='password'
            label='CONTRASEÑA'
            type={showPassword ? 'text' : 'password'}
            value={formValues.password}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position='end' className='mr-2'>
                <IconButton
                  size='small'
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? (
                    <VisibilityOff fontSize='small' />
                  ) : (
                    <Visibility fontSize='small' />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
        </FormControl>
        <p>
          ¿No tienes cuenta?{' '}
          <Link to='/auth/signup' className='text-accent1 hover:underline'>
            Regístrate aquí
          </Link>
        </p>
        <CustomButton
          type='submit'
          className='w-36 py-2 bg-primary active:translate-y-[2px] text-white font-bold'
          title='Iniciar Sesión'
        />
      </form>
      {showAlert && (
        <div className='fixed inset-x-0 bottom-12 flex items-center justify-center'>
          <Alert severity='error'>{showAlert}</Alert>
        </div>
      )}
    </div>
  )
}

export default SignInForm
