import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useState } from 'react';
import { BaseEmptyComponentProps } from './BaseEmptyTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { ChildrenForEach, ItemForEach } from '../../../../utils/ForEach';


interface TRowProps {
  children: ReactNode,
  isFocused?: boolean,
  onClick?: () => void
}

interface TDataWithChevronProps<K> extends TDataProps<K> {
  toggle: boolean
}

interface TCollapsableRowProps<T> extends Omit<TRowProps, 'children'> {
  clickableRow: (toggle: boolean) => ReactNode,
  dropDownData: {
    tHeadItems: string[],
    tBodyitems: T[],
  },
  dropDown: (props: ItemForEach<T>) => ReactNode
}

interface TDataProps<K> {
  className?: React.HTMLAttributes<K>['className']
  text?: string | number,
  children?: ReactNode
  onClick?: () => void
}

interface TDataActiveProps<J> extends Omit<TDataProps<J>, 'text'> {
  onClick?: () => void
  status?: 'active' | 'inactive' | 'pending'
}

type TypedChildren<T> = {
  item: T,
  TData<K>(props: TDataProps<K>): ReactNode,
  TDataWithChevron<O>(props: TDataWithChevronProps<O>): ReactNode
  TDataActive<J>(props: TDataActiveProps<J>): ReactNode,
  TRow: FC<TRowProps>,
  TCollapsableRow<S>(props: TCollapsableRowProps<S>): ReactNode,
  TDataActions<P>(props: TDataActionsProps<P>): ReactNode,
  index?: number,
  arr?: T[]
}


export interface BaseTableProps<T> {
  data: {
    tHeadItems: string[],
    tBodyitems: T[],
  }
  // metaData?: PaginationMetaData,
  skeleton?: {
    isLoading?: boolean,
    numberOfRows?: number
  },
  itemsNotFoundMessage?: string,
  inputsHaveBeenTouched?: boolean | string,
  emptyComponent?: (props: { BaseEmptyComponent: FC<BaseEmptyComponentProps> }) => ReactNode,
  children: (props: TypedChildren<T>) => ReactNode,
  onSetPage?: Dispatch<SetStateAction<number>>
  hasFooter?: boolean,
  footer?: () => ReactNode
  isRoundedTop?: boolean,
  horizontalScroll?: boolean
}

export function BaseTableBody<T>(props: BaseTableProps<T>) {
  const {
    data: {
      tHeadItems,
      tBodyitems
    },
    skeleton = { isLoading: false, numberOfRows: 8 },
    itemsNotFoundMessage = 'No se han encontrado items para esta busqueda',
    isRoundedTop = false,
    children,
    horizontalScroll = false
  } = props;


  const TableSkeletonBodyCols = useCallback(() => {
    return (
      <>
        {

          [...Array(skeleton.numberOfRows ?? 8).keys()].map((row) => (
            <tr key={row}>
              {tHeadItems.map((tdata) => (
                <td
                  key={tdata}
                  className="bg-gray-vlg-200 overflow-hidden rounded-2xl animate-pulse h-[52px] border-[6px] border-white"
                />
              ))}
            </tr>
          )) ?? []
        }
      </>
    );
  }, []);


  const TableSkeletonHeadCols = useCallback(() => {
    return (
      <>
        {

          [...Array(tHeadItems.length).keys()].map((item) => (
            <th key={item} scope="col" className="bg-gray-vlg-200 animate-pulse h-[52px] px-6 py-7 font-semibold" />
          )) ?? []
        }
      </>
    );
  }, []);


  const TableHeadCols = useCallback(() => (
    <>
      {
        tHeadItems.map((item) => (
          <th key={item} scope="col" className="px-6 py-5 font-semibold capitalize">
            {item}
          </th>
        )) ?? []
      }
    </>
  ), []);


  const TableBodyCols = () => (
    <>
      {
        tBodyitems.map((item, index, arr) => (
          children({ item, index, arr, TRow, TCollapsableRow, TData, TDataWithChevron, TDataActive, TDataActions })
        )) ?? []
      }
    </>
  );


  const ItemsNotFound: FC = () => (
    <tr>
      <td className="px-6 py-4 font-semibold text-sm max-w-64">
        {itemsNotFoundMessage}
      </td>
    </tr>
  );

  return (
    <div className="w-full">
      <div className={`w-full ${horizontalScroll ? 'vertical-custom-scrollbar overflow-x-auto' : ''}`}>
        <table className={`${isRoundedTop && 'rounded-t-2xl overflow-hidden'} w-full text-sm text-left rtl:text-right`}>
          <thead className="bg-primary border-b border-gray-100 text-white">
            <tr>
              {skeleton?.isLoading ? <TableSkeletonHeadCols /> : <TableHeadCols />}
            </tr>
          </thead>

          <tbody className="w-full">
            {skeleton?.isLoading ? <TableSkeletonBodyCols /> : tBodyitems.length ? <TableBodyCols /> : <ItemsNotFound />}
          </tbody>
        </table>
      </div>

    </div>
  );
}


function TData<K>({ text, onClick = () => { }, children, className = '' }: TDataProps<K>): JSX.Element {
  return (
    <td onClick={onClick} className={`px-6 py-4 text-ellipsis overflow-hidden ${className}`}>
      {text ? text : children}
      {!text && children}
    </td>
  );
}


function TDataWithChevron<K>({ text, onClick = () => { }, children, className = '', toggle }: TDataWithChevronProps<K>) {
  return (
    <td onClick={onClick} className={`px-6 py-4 text-ellipsis overflow-hidden ${className}`}>
      {toggle ? <FontAwesomeIcon className="pr-3" size="sm" icon={faChevronUp} /> : <FontAwesomeIcon className="pr-3" size="sm" icon={faChevronDown} />}
      <span>
        {text ? text : children}
      </span>
    </td>
  );
}


function TDataActive<J>({ onClick = () => { }, status = 'active', className = '' }: TDataActiveProps<J>) {
  const statusStyles = (
    status === 'active' ? 'status-active'
      : status === 'inactive' ? 'status-inactive'
        : 'status-pending'
  );
  return (
    <td onClick={onClick} className={`px-6 py-4 ${className}`}>
      <span className={`${statusStyles} rounded-full py-1 px-2.5 font-semibold text-xs`}>
        {status}
      </span>
    </td>
  );
}


interface TDataActionsProps<K> {
  className?: React.HTMLAttributes<K>['className']
  items: K[],
  title?: string,
  children: ChildrenForEach<K>
}

function TDataActions<K>({ className = '', items, title = 'Acciones', children }: TDataActionsProps<K>) {
  const [toggle, setToggle] = useState(false);
  return (
    <td
      onMouseLeave={() => setToggle(false)}
      className={`relative ${className}`}
    >
      <button onClick={(e) => {
        e.stopPropagation();
        setToggle(prev => !prev);
      }}
        type="button"
        className="text-blue-vlg-500 font-medium hover:text-blue-vlg-600 px-6 py-4"
      >
        {title}
      </button>

      {toggle && (
        <div className="top-9 absolute -left-4 z-50 max-w-40 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
          <ul className="text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {items.map((...args) => (
              <li key={args[1]}>
                {children(...args)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </td>
  );
}


const TRow: FC<TRowProps> = ({ children, isFocused = false, onClick = () => { } }) => {
  return (
    <tr onClick={onClick} className={`border border-gray-200/70 ${isFocused && 'bg-gray-200'}`}>
      {children}
    </tr>
  );
};


/**
 *
 * TODO: add a second table prop that should be iterated
 */
function TCollapsableRow<S>({ clickableRow, dropDownData, dropDown, isFocused, onClick, }: TCollapsableRowProps<S>) {
  const [toggleDropDown, setToggleDropDown] = useState(false);

  const handleClick = (): void => {
    onClick && onClick();
    setToggleDropDown(prev => !prev);
  };

  return (
    <>
      <tr
        title="presiona click para expandir"
        onClick={handleClick}
        className={`border-b border-gray-vlg-200/90 ${isFocused && 'bg-gray-vlg-200'}`}
      >
        {clickableRow(toggleDropDown)}
      </tr>

      {toggleDropDown && (
        <>
          <tr className="h-4" />

          <tr className="">
            <th />
            {dropDownData.tHeadItems.map((thead, index) => (
              <th
                key={thead}
                className={
                  `${index === 0 ? 'rounded-tl-2xl' : index === (dropDownData.tHeadItems?.length - 1) ? 'rounded-tr-2xl' : ''}
                     bg-gray-vlg-100 px-6 py-2 text-ellipsis overflow-hidden border-b-2 border-gray-vlg-200`
                }
              >
                {thead + 2}
              </th>
            ))}
          </tr>

          {dropDownData.tBodyitems.map((item, index, arr) => (
            dropDown({ item, index, arr })
          ))}
          <tr className="h-4" />
        </>
      )}
    </>
  );
}
