const InputCheckBox = ({ label, id, name, value, className, classNameConteiner, ...rest }) => {
  const defaultClassName =
    'border border-gray-300 hover:border-gray-400 focus:border-gray-400 rounded-full shadow focus:outline-none focus:ring-0'

  const defaultClassNameConteiner = 'flex gap-2 items-center justify-center'

  return (
    <div className={`${classNameConteiner || defaultClassNameConteiner}`}>
      <label htmlFor={id}>{label}</label>
      <input
        className={`${defaultClassName} ${className}`}
        type='checkbox'
        id={id}
        name={name}
        value={value}
        {...rest}
      />
    </div>
  )
}

export default InputCheckBox
