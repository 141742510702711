import { useTransition, animated } from '@react-spring/web';
import { createPortal } from 'react-dom';


const DEFAULT_TRANSITION_CONFIG = {
  from: { y: '100%', opacity: 0 },
  enter: { y: '0%', opacity: 1 },
  leave: { y: '120%', opacity: 0 },
  config: { tension: 190, mass: 1.8 }
};


/**
 * @typedef {Object} ModalProps
 * @property {boolean} isOpen - Whether the modal is open.
 * @property {Function} onClose - Function to call when the modal is closed.
 * @property {React.ReactNode} children - The children to render inside the modal.
 */

/**
 * @function Modal
 * @param {ModalProps} props - The properties for the Modal.
 */
export const Modal = ({ isOpen, onClose, children }) => {

  const transition = useTransition(isOpen, DEFAULT_TRANSITION_CONFIG);

  /**
   * @function AnimatedModal
   * @returns {React.ReactNode} The AnimatedModal component.
   */
  const AnimatedModal = () => transition((style, isOpen) => (
    isOpen && (
      <animated.div
        onClick={onClose}
        style={{ opacity: style.opacity }}
        className="backdrop-blur-[1px] bg-black/60 fixed w-screen h-screen z-50 flex items-center justify-center">
        <animated.div
          onClick={(e) => e.stopPropagation()}
          style={{ transform: style.y.to(y => `translateY(${y})`) }}
        >
          {children}
        </animated.div>
      </animated.div>
    )
  ));

  return (
    createPortal(<AnimatedModal />, document.getElementById('modal-root'))
  );
};
