import { create } from 'zustand'

const localApiToken = localStorage.getItem('backendToken') ?? ''
const localOrganizationToken = localStorage.getItem('organizationToken') ?? ''


interface IAccountProfile {
  firebaseToken: string;
  apiToken: string;
  organizationToken: string;
}

interface ILucaAuthState {
  accountProfile: IAccountProfile;
  setApiToken: (apiToken: string) => void;
  deleteApiToken: () => void;
  setOrganizationToken: (organizationToken: string) => void;
  deleteOrganizationToken: () => void;
}

/**
 *
 * @description This hook is used to manage the authentication state of the user after login or sign up.
 */
export const useLucaAuth = create<ILucaAuthState>(set => ({
  accountProfile: {
    firebaseToken: '', // not necessary when signing up

    apiToken: localApiToken, // read token from localStorage
    organizationToken: localOrganizationToken
  },

  setApiToken: apiToken => {
    localStorage.setItem('backendToken', apiToken)

    set(prev => ({ ...prev, accountProfile: { ...prev.accountProfile, apiToken } }))
  },

  deleteApiToken: () => {
    localStorage.removeItem('backendToken')

    set(prev => ({ ...prev, accountProfile: { ...prev.accountProfile, apiToken: '' } }))
  },

  setOrganizationToken: organizationToken => {
    localStorage.setItem('organizationToken', organizationToken)

    set(prev => ({ ...prev, accountProfile: { ...prev.accountProfile, organizationToken } }))
  },

  deleteOrganizationToken: () => {
    localStorage.removeItem('organizationToken')

    set(prev => ({ ...prev, accountProfile: { ...prev.accountProfile, organizationToken: '' } }))
  }
}))
