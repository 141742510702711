import ListEgresos from './List'
import EgresosInsights from './egresosInsights/EgresosInsights'

const EgresosPage = () => {
  return (
    <>
      <ListEgresos />
      <EgresosInsights />
    </>
  )
}

export default EgresosPage
