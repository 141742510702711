import { useMemo } from "react"
import { HISTORICAL_SEARCH_KEYS } from "../data/dataHistoricalSearchKeys"


/**
 *
  * @typedef {Object} BarProps
  * @property {number} currentOptionKey - The default starting Index of the bar.
  * @property {(option: string) => void} onSelectOptionKey - A callback that fires when the user selects an option

  * @property {boolean} short - A boolean that determines if the bar should be short or long.
  * @property {string} position - The position of the bar, either 'top' or 'bottom'.

  *
  * A component that selects an option on click.
  * @param {BarProps} props - The props for the component.
  * @returns {JSX.Element} The rendered component.
*/

export const BarFilterHistoricalSearch = ({ currentOptionKey = '1M', onSelectOptionKey, short, position = 'bottom' }) => {

  const optionsKeys = Object.values(HISTORICAL_SEARCH_KEYS).filter(key => key !== 'TD')
  const options = short ? optionsKeys.slice(2) : optionsKeys
  const memoizedOptionsKeys = useMemo(() => options, [currentOptionKey])

  return (
    <ul className={`absolute flex text-sm justify-between items-center px-5 shadow-xl right-1/2 ${position === 'top' ? '-top-[90px]' : '-bottom-[57px]'} translate-x-1/2 translate-y-1/2 bg-primary text-slate-50 ${short ? 'w-[380px]' : 'w-[500px]'} min-h-[48px] rounded-full`}>
      {memoizedOptionsKeys.map((selectedFilterKey, i) => (
        <li key={i}>
          <button
            type='button'
            onClick={() => onSelectOptionKey(selectedFilterKey)}
            className={`transition-all focus:font-bold active:text-primary p-[10px] rounded-full ${currentOptionKey === selectedFilterKey ? 'bg-white scale-105 text-primary font-semibold hover:bg-white' : 'hover:bg-white/35 hover:scale-110 active:scale-100'}`}
          >
            {selectedFilterKey}
          </button>
        </li>
      ))}

    </ul>
  )
}
