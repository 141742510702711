import { useMutation, useQuery } from "@tanstack/react-query"
import { QueryKeys } from "./querykeys"
import businessApi from "../api/business.api"
import { UserAuth } from "../context/AuthContext"
import { ICreateBusiness, ICreateRepresentanteLegal } from "../models/Business"


export const useQueryGetAllBusinesses = () => {
  const { isAuthenticated } = UserAuth()

  return (
    useQuery({
      enabled: isAuthenticated,
      queryKey: [QueryKeys.GET_BUSINESSES_ALL],
      queryFn: async () => {
        try {
          const res = await businessApi.getListBusiness()
          if (res.status === 200) {
            return res.data
          }
        } catch (error) {
          return null
        }
      }
    })
  )
}


export const useMutationCreateBusiness = () => {
  return (
    useMutation({
      mutationFn: async (payload: ICreateBusiness) => {
        try {
          const res = await businessApi.createBusiness(payload)
          if (res.status === 201 || res.status === 200) {
            return res.data
          }
        } catch (error) {
          return null
        }
      }
    })
  )
}

export const useMutationCreateRepresentanteLeal = () => {
  return (
    useMutation({
      mutationFn: async ({ businessId, payload }: { businessId: number, payload: ICreateRepresentanteLegal }) => {
        try {
          const res = await businessApi.createRepresentanteLegal(businessId, payload)
          if (res.status === 201 || res.status === 200) {
            return res.data
          }
        } catch (error) {
          return null
        }
      }
    })
  )
}
