import { useState, forwardRef, Fragment, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'
import { toast } from 'react-toastify'

//assets
import imgAbono from '../../../assets/iconos/icos28.png'
import imgCargo from '../../../assets/iconos/icos29.png'
import iconCalendario from '../../../assets/iconos/iconCalendario.png'
import iconClip from '../../../assets/iconos/image.png'
//api
import { OrganizationAPI } from '../../../api/organization.api'
import { BusinessAPI } from '../../../api/business.api'

// components
import { Button, Text, Title } from '../ui/index'
import Modal from './ModalDialogAddProps'
import { formatDate, formatCurrency } from '../../utils/formatter'

// material
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const typeMovementsAbono = [
  { id: 0, value: 0, name: 'Seleccione una opcion' },
  { id: 1, value: 1, name: 'Traspaso entre cuentas' },
  { id: 2, value: 3, name: 'Ingresos del giro' }
  // { id: 3, value: 4, name: 'Otros Ingresos' }
]

const typeMovementsCargo = [
  { id: 0, value: 0, name: 'Seleccione una opcion' },
  { id: 1, value: 2, name: 'Traspaso entre cuentas' },
  { id: 2, value: 5, name: 'Proveedores' }
  // { id: 3, value: 6, name: 'Otros Egresos' }
]
const ModalDialogMotionEditForm = ({
  open,
  setOpen,
  idLibro,
  type,
  // status,
  setSwitchGetData
  // title
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({})
  const [dataCustom, setDataCustom] = useState({ typeMovement: '1' })
  const [dataCuentas, setDataCuentas] = useState([])
  const { currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const businessAPI = new BusinessAPI()

  const handleClose = () => {
    setOpen(false)
  }

  const functionGetDataLibroContable = async () => {
    try {
      // ui.setLoader({ visible: true, text: 'cargando datos' })
      const response = await organizationAPI.getDataLibroContable(
        currentBusiness?.id,
        idLibro,
        type
      )
      // setSwitchDate(false)
      setData(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      // ui.setLoader({ visible: false, text: '' })
    }
  }

  const toastMessage = async () => {
    await toast.promise(functionGetDataLibroContable(), {
      pending: 'Cargando datos... 🚀',
      success: 'Datos cargados exitosamente... 👌',
      error: 'No se pudieron cargar los datos 🤯'
    })
  }

  const handleSubmit = async () => {
    try {
      const updateData = {
        cuentaContableId: null,
        glosa: 'glosa',
        validation: 1,
        vencimiento: 'vencimiento'
      }
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness?.id,
        idLibro,
        updateData
      )
      console.info(response)
      setSwitchGetData(true)
      functionGetDataLibroContable()
    } catch (error) {
      console.error(error)
    }
  }

  const toastMessageEdit = async () => {
    await toast.promise(handleSubmit(), {
      pending: 'Editando datos... 🚀',
      success: 'Datos Editandos exitosamente... 👌',
      error: 'No se pudieron Editanditar los datos 🤯'
    })
  }

  useEffect(() => {
    toastMessage()
  }, [])

  // useEffect(() => {
  //   console.log(dataCustom)
  // }, [dataCustom])

  // useEffect(() => {
  //   console.log(data)
  // }, [data])

  useEffect(() => {
    const dataDetalleSelect = async () => {
      try {
        // ui.setLoader({ visible: true, text: 'cargando datos' })
        let params = {
          businessId: currentBusiness.id,
          limit: 100,
          type: data.categoriaTipoMovimiento === 'abono' ? 'boleta' : 'factura',
          state: 'por-pagar',
          order: 'ASC'
        }

        const responseCuentas = await businessAPI.getPorCobrarCobradas(params)
        // setSwitchDate(false)
        setDataCuentas(responseCuentas.data.data)
      } catch (error) {
        console.error(error)
      } finally {
        // ui.setLoader({ visible: false, text: '' })
      }
    }
    dataDetalleSelect()
  }, [data])

  const handleOptionSelect = e => {
    const { name, value } = e.target
    // if (name === 'typeMovement')
    setDataCustom({ ...dataCustom, [name]: value })
    // else {
    //   setDataCustom({ ...dataCustom, [name]: value })
    // }
    // if (name === 'idCuenta') return setData({ ...data, [name]: value })
    // if (name === 'validation') return setData({ ...data, [name]: value })

    // setDataCustom({ [name]: value })
  }

  const typeMovements =
    data.categoriaTipoMovimiento === 'abono' ? typeMovementsAbono : typeMovementsCargo

  return (
    <div>
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth='lg'
          scroll='body'
          PaperProps={{
            // Utilizamos PaperProps para aplicar estilos al contenido del diálogo
            sx: {
              backgroundColor: '#f2f2f2' // Aquí aplicamos el borderRadius al contenido
            }
          }}
        >
          <DialogContent>
            <div className='bg-[#f2f2f2] flex justify-end items-center'>
              <button type='button' onClick={handleClose}>
                <ClearIcon sx={{ fontSize: 40 }} />
              </button>
            </div>
            <div className='px-10'>
              <Title className='text-3xl mb-10'>Editar movimiento</Title>
              <section className='flex flex-col bg-white shadow-5xl px-8 py-2 rounded-2xl gap-10'>
                <section className='flex justify-between items-center'>
                  <div className='flex'>
                    <img
                      className='w-8 h-8 mr-2'
                      src={data.categoriaTipoMovimiento === 'abono' ? imgAbono : imgCargo}
                      alt=''
                    />
                    <div>
                      <p className='text-m font-bold uppercase'>{data.nombreBanco}</p>
                      <p className='text-sm font-bold uppercase'>{data.codigoCuenta}</p>
                    </div>
                  </div>
                  <div className='flex justify-center items-center gap-2'>
                    <img className='w-8 h-8' src={iconCalendario} alt='' />
                    <p className='text-sm font-bold uppercase text-center'>
                      {formatDate(data.fecha)}
                    </p>
                  </div>
                  <div className='flex flex-col items-end pt-2'>
                    <p className='text-lg font-bold uppercase'>{data.categoriaTipoMovimiento}</p>
                    <Text className='text-sm  uppercase'>{formatCurrency(data.montoTotal)}</Text>
                    <Text className='text-sm'></Text>
                  </div>
                </section>
                <section className='flex justify-between items-center gap-96'>
                  <div>
                    <p className='text-m font-bold uppercase'>{data.descripcion}</p>
                  </div>
                  <Text
                    className={
                      data.status === 'PENDIENTE'
                        ? 'text-accent3 font-bold'
                        : 'text-accent2 font-bold'
                    }
                  >
                    {data.status}
                  </Text>
                </section>
              </section>
              <section className='grid grid-cols-2 py-5 mt-7 gap-10'>
                <div className='flex flex-col gap-3'>
                  <Title className='text-gray-700 text-lg'>Tipo de movimiento</Title>
                  <select
                    className='w-fit h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-3xl shadow leading-tight '
                    name='typeMovement'
                    id='typeMovement'
                    onChange={e => handleOptionSelect(e)}
                  >
                    {typeMovements.map(type => (
                      <option
                        key={type.id}
                        value={type.id}
                        selected={type.id === 0 ? true : false}
                        disabled={type.id === 0 ? true : false}
                      >
                        {type.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className='w-fit h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-3xl shadow leading-tight '
                    name='validation'
                    id='validation'
                    onChange={e => handleOptionSelect(e)}
                  >
                    <option selected disabled>
                      Seleccione temporalidad de la nueva clasificación.
                    </option>
                    <option value={1}>Aplicar sólo a este registro.</option>
                    <option value={2}>Recordar esta selección en el futuro.</option>
                    <option value={3}>Aplicar esta selección a todos los registros.</option>
                  </select>
                  <div className='flex flex-col gap-3 mt-12'>
                    <Title className='text-lg'>Comentario</Title>
                    <textarea
                      placeholder='Aqui puedes dejar un comentario de algo que quieras recordar...'
                      className='shadow-5xl border-none rounded-2xl italic'
                      rows='4'
                      cols='50'
                    ></textarea>
                  </div>
                </div>
                <div className='flex flex-col gap-3'>
                  <Title className='text-gray-700 text-lg'>Detalle</Title>
                  <select
                    className='w-[80%] h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-3xl shadow leading-tight '
                    name='idCuenta'
                    id='idCuenta'
                    onChange={e => handleOptionSelect(e)}
                  >
                    {dataCustom.typeMovement !== '1' ? (
                      dataCuentas?.map(item => (
                        <option
                          key={item.num}
                          value={item.num}
                          // selected={item.id === data.idCuenta ? true : false}
                        >
                          {item.num} - {item.razonSocial} - {item.rut}
                        </option>
                      ))
                    ) : (
                      <option></option>
                    )}
                  </select>
                  <div className='flex flex-col gap-3 mt-24'>
                    <Title className='text-lg'>Agregar respaldo</Title>

                    <label
                      htmlFor='file-upload'
                      className='custom-file-upload cursor-pointer shadow-5xl border-none rounded-2xl w-[445px] h-[112px] bg-white flex flex-col items-center justify-center'
                    >
                      <img className='w-11 h-11' src={iconClip} />
                      <p className='text-gray-400'>documentos permitidos pdf, png, jpg, jpeg </p>
                    </label>
                    <input id='file-upload' type='file' className='hidden' />
                  </div>
                </div>
              </section>
            </div>
            <div className='flex justify-end gap-3 mr-10 mb-5 '>
              <Button
                onClick={handleClose}
                className='uppercase text-sm bg-primary text-white'
                title='cancelar'
              />
              <Button
                disabled={dataCustom?.validation ? false : true}
                onClick={toastMessageEdit}
                className={`uppercase text-sm text-white ${
                  dataCustom?.validation ? 'bg-accent2 ' : 'bg-[#00000030]'
                }`}
                title='guardar cambios'
              />
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
      <Modal open={modalOpen} setOpen={setModalOpen} />
    </div>
  )
}

export default ModalDialogMotionEditForm
