import { useMutation, useQuery } from "@tanstack/react-query"
import RolesApi from "../api/roles.api"
import { QueryKeys } from "./querykeys"
import { ICreateRole } from "../models/Roles"


export const useQueryGetRoles = () => {
  return useQuery({
    queryKey: [QueryKeys.GET_ROLES],
    queryFn: async () => {
      try {
        const res = await RolesApi.getRoles()
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useQueryGetRoleById = (id: number) => {
  return useQuery({
    queryKey: [QueryKeys.GET_ROLE, id],
    queryFn: async () => {
      try {
        const res = await RolesApi.getRoleById(id)
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationCreateRole = () => {
  return useMutation({
    mutationFn: async (createRolePayload: ICreateRole) => {
      try {
        const res = await RolesApi.createRole(createRolePayload)
        if (res.status === 200 || res.status === 201) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationUpdateRole = () => {
  return useMutation({
    mutationFn: async (updateRolePayload: Partial<ICreateRole>) => {
      try {
        const res = await RolesApi.updateRole(updateRolePayload)
        if (res.status === 200) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}


export const useMutationDeleteRole = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      try {
        const res = await RolesApi.deleteRole(id)
        if (res.status === 200 || res.status === 201) {
          return res.data
        }
      } catch (error) {
        return null
      }
    }
  })
}
