const colsIngresos = [
  '',
  'Folio',
  'Cliente',
  'Fecha',
  'Clasificación',
  'Monto',
  'Status',
  'Acciones'
]
const colsEgresos = [
  '',
  'Folio',
  'Proveedor',
  'Fecha',
  'Clasificación',
  'Monto',
  'Status',
  'Acciones'
]
const colsMovimientos = [
  '',
  'Fecha',
  'Cuenta Corriente',
  'Descripción',
  'Abono',
  'Cargo',
  'Status',
  'Acciones'
]

const colsLibroDiario = [
  'Fecha',
  'Tipo',
  'Comprobante',
  'Cuenta',
  'Rut',
  'Razon Social',
  'Documento',
  'Glosa',
  'Debe',
  'Haber'
]

const colsLibroMayor = [
  'Fecha',
  'Tipo',
  'Comprobante',
  'Rut',
  'Razon Social',
  'Documento',
  'Debe',
  'Haber',
  'Saldo',
  'Glosa'
]

const colsPresupuesto = [
  'Cuentas',
  'Total',
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const colsAnualPresupuesto = ['Cuentas', 'Total', '2024', '2025', '2026']

const colsBalanceGeneral = [
  'N° Cuenta',
  'Nombre Cuenta',
  'Sumas',
  'Saldos',
  'Inventario',
  'Resultado'
]

export {
  colsIngresos,
  colsEgresos,
  colsMovimientos,
  colsLibroDiario,
  colsLibroMayor,
  colsPresupuesto,
  colsAnualPresupuesto,
  colsBalanceGeneral
}
