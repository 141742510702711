import { Form, Formik } from 'formik'
import { useLucaSignUp } from '../hooks/useLucaSignUp'
import * as Yup from 'yup'
import { useSwiper } from 'swiper/react'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const step3ValidationSchema = Yup.object({
  userType: Yup.string().required('Selecciona un tipo de usuario')
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export function StepForm3() {
  const addAccountProfileValues = useLucaSignUp(state => state.setAccountProfile)
  const accountProfile = useLucaSignUp(state => state.accountProfile)
  const swiper = useSwiper()

  const step3Values = {
    userType: accountProfile?.userType ?? ''
  }

  const handleNext = (values, actions) => {
    addAccountProfileValues(values)
    swiper.slideNext()
    actions.setSubmitting(true)
  }

  return (
    <Formik
      validationSchema={step3ValidationSchema}
      initialValues={step3Values}
      onSubmit={handleNext}
    >
      {({ setFieldValue, values }) => (
        <Form className='px-1'>
          <h3 className='mb-8 text-3xl font-bold text-center'>¿Qué tipo de Organización es?</h3>
          <div className='grid grid-cols-2 gap-x-8 gap-y-3'>
            <button
              type='button'
              onClick={() => setFieldValue('userType', 'contador')}
              className={`btn-light-alt ${values?.userType === 'contador' && 'bg-primary/50 text-slate-800 focus:ring-primary/30 hover:bg-primary/60'}`}
            >
              Contador/Empresa Contable
            </button>
            <button
              type='button'
              onClick={() => setFieldValue('userType', 'empresa')}
              className={`btn-light-alt ${values?.userType === 'empresa' && 'bg-primary/50 text-slate-800 focus:ring-primary/30 hover:bg-primary/60'
                }`}
            >
              Socio/Empresa
            </button>
          </div>

          <div className='flex flex-col gap-2 mt-8 w-2/3 mx-auto'>
            <div className='flex items-center justify-center gap-8'>
              <button onClick={() => swiper.slidePrev()} type='button' className='btn-light-alt'>
                <FontAwesomeIcon size='lg' icon={faArrowLeft} />
                <span>Volver</span>
              </button>
              <button type='submit' className='btn-primary'>
                <span>Continuar</span>
                <FontAwesomeIcon size='lg' icon={faArrowRight} />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
