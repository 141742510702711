import clsx from 'clsx'
import { Text } from '../ui'
import { ExportCSV, ExportXLS } from './ExportData'
import ExportPDFMirrorTable from './typesExportPDF/ExportPDFMirrorTable'
import {
  formatCurrency,
  formatDateShort,
  formatInitalMayus,
  formatRut
} from '../../utils/formatter'

const ExportButtons = ({ porCobrarPagar, cobradasPagadas }) => {
  const arr = porCobrarPagar && cobradasPagadas && [...porCobrarPagar, ...cobradasPagadas]
  return (
    <div className='flex w-full z-10 justify-end px-8 -my-4 space-x-3'>
      <>
        <ExportCSV data={arr || []} />
        <ExportXLS data={arr || []} />

        {/* Pendiente armar datos pdf */}
        <ExportPDFMirrorTable data={arr || []} />
      </>
    </div>
  )
}

const styles = {
  th: 'title-table tracking-wider text-left px-3 my-0',
  td: 'first-line-table font-light py-2 px-3 overflow-x-auto no-scrollbar',
  razonSocial: 'max-w-40 whitespace-nowrap',
  rut: 'w-28 whitespace-nowrap',
  total: 'max-w-40 whitespace-nowrap text-right',
  mora: 'w-10 whitespace-nowrap text-center'
}

const EmptyRow = () => (
  <tr className='hover:bg-gray-100/50'>
    <td className={styles.td}>
      <Text className='ml-8'>-</Text>
    </td>
    <td className={styles.td}>
      <Text className='text-right mr-4'>-</Text>
    </td>
    <td className={styles.td}>
      <Text className='text-right mr-4'>-</Text>
    </td>
    <td className={styles.td}>
      <Text className='text-right mr-4'>-</Text>
    </td>
  </tr>
)

const MirrorTable = ({
  totalPorCobrarPagar = { data: [], total: 0 },
  totalCobradasPagadas = { data: [], total: 0 },
  topData = { data: [], total: 0 },
  bottomData = { data: [], total: 0 },
  title1,
  title2
}) => {
  const emptyRows = data => Math.max(0, 3 - data?.length)
  return (
    <div className='flex flex-col justify-between w-full max-w-5xl mx-auto'>
      <div className='flex flex-col justify-between h-full bg-white rounded-t-3xl border-[6px] border-accent1'>
        <div className='px-6 py-2 overflow-x-auto'>
          <table className='w-full table-auto'>
            <thead>
              <tr className='border-b-4 border-b-secondary'>
                <th className={styles.th}>Razón Social</th>
                <th className={styles.th}>Rut</th>
                <th className={clsx(styles.th, 'text-center')}>Monto Total</th>
                <th className={styles.th}>Mora</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 text-sm font-light'>
              {topData?.data?.[0] ? (
                <>
                  {topData.data.map((cuenta, index) => (
                    <tr key={index} className='hover:bg-gray-100/50'>
                      <td className={clsx(styles.td, styles.razonSocial)}>
                        {cuenta.razonSocial && formatInitalMayus(cuenta.razonSocial)}
                      </td>
                      <td className={clsx(styles.td, styles.rut)}>{formatRut(cuenta.rut)}</td>
                      <td className={clsx(styles.td, styles.total)}>
                        {formatCurrency(cuenta.total)}
                      </td>
                      <td className={clsx(styles.td, styles.mora)}>{cuenta.date}</td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRows(topData?.data) }).map((_, index) => (
                    <EmptyRow key={`empty-${index}`} />
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td className={styles.td} colSpan='4'>
                      <Text className='text-lg text-center -my-[5px]'>No hay datos</Text>
                    </td>
                  </tr>
                  {Array.from({ length: emptyRows(topData?.data) })
                    .slice(1)
                    .map((_, index) => (
                      <EmptyRow key={`empty-${index}`} />
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className='flex bg-accent1 items-center px-6 py-2'>
          <Text className='text-2xl font-thin text-white w-[150px]'>{title1}</Text>
          <Text className='text-2xl font-bold text-white'>
            {formatCurrency(topData?.total) || '$ 0'}
          </Text>
        </div>
      </div>
      <ExportButtons
        porCobrarPagar={totalPorCobrarPagar?.data}
        cobradasPagadas={totalCobradasPagadas?.data}
      />
      <div className='flex flex-col h-full bg-white rounded-b-3xl border-[6px] border-accent2'>
        <div className='flex bg-accent2 items-center px-6 py-2'>
          <Text className='text-2xl font-thin text-white w-[150px]'>{title2}</Text>
          <Text className='text-2xl font-bold text-white'>
            {formatCurrency(bottomData?.total) || '$ 0'}
          </Text>
        </div>
        <div className='px-6 py-2 overflow-x-auto'>
          <table className='w-full table-auto'>
            <thead>
              <tr className='border-b-4 border-b-secondary'>
                <th className={styles.th}>Razón Social</th>
                <th className={styles.th}>Rut</th>
                <th className={clsx(styles.th, 'text-center')}>Monto Total</th>
                <th className={styles.th}>Pago</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 text-sm font-light'>
              {bottomData?.data?.[0] ? (
                <>
                  {bottomData.data.map((cuenta, index) => (
                    <tr key={index} className='hover:bg-gray-100/50'>
                      <td className={clsx(styles.td, styles.razonSocial)}>
                        {cuenta.razonSocial && formatInitalMayus(cuenta.razonSocial)}
                      </td>
                      <td className={clsx(styles.td, styles.rut)}>{formatRut(cuenta.rut)}</td>
                      <td className={clsx(styles.td, styles.total)}>
                        {formatCurrency(cuenta.total)}
                      </td>
                      <td className={clsx(styles.td, styles.mora)}>
                        {formatDateShort(cuenta.fechaPago)}
                      </td>
                    </tr>
                  ))}
                  {Array.from({ length: emptyRows(topData?.data) }).map((_, index) => (
                    <EmptyRow key={`empty-${index}`} />
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td className={styles.td} colSpan='4'>
                      <Text className='text-lg text-center -my-[5px]'>No hay datos</Text>
                    </td>
                  </tr>
                  {Array.from({ length: emptyRows(bottomData?.data) })
                    .slice(1)
                    .map((_, index) => (
                      <EmptyRow key={`empty-${index}`} />
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MirrorTable
