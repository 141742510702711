export const HISTORICAL_SEARCH_KEYS = {
  '1D': '1D',
  '1S': '1S',
  '1M': '1M',
  '1Q': '1Q',
  '2Q': '2Q',
  '3Q': '3Q',
  '4Q': '4Q',
  '1A': '1A',
  'TD': 'TD'
}
