import { FormInviteUser } from './FormInvitateUser'
import { UsersList } from './UsersList'


export const Users = () => {
  return (
    <div className='py-2'>
      <h3 className='font-bold text-xl mb-4 text-center'>Usuarios</h3>
      <div className='flex flex-col gap-12'>
        <FormInviteUser />
        <UsersList />
      </div>
    </div>
  )
}
