//react
import { useEffect, useState, useContext } from 'react'
import GlobalContext from '../../../../../../../context/global-context'
import { toast } from 'react-toastify'

//api
import { BankAPI } from '../../../../../../../api/bancos.api'

//components
import { Button } from '../../../../../ui/index'
import ModalDialogIndexConect from '../connect/ModalDialogIndexConect'
import ModalEditAcounts from '../modalDialogEditAcounts/ModalEditAcounts'
import List from './List'

//utils
import { formatCurrency } from '../../../../../../utils/formatter'
import { currentMonth, currentYear } from '../../../../../../utils/constant'

//data
import { dataInputsBanks, firstObjectListAcountBank } from '../data'

const BancosTableInsights = ({ setConnectBank }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openModalEditAcounts, setOpenModalEditAcounts] = useState(false)
  const [deudasPagos, setDataDeudasPagos] = useState({})
  const [acounts, setAcounts] = useState([])
  const [transactionsAcount, setTransactionsAcount] = useState([])
  const [currentAcount, setCurrentAcount] = useState(acounts[0] || null)

  const { currentBusiness } = useContext(GlobalContext)
  const bankAPI = new BankAPI()

  // función para identificar la cuenta y el banco de búsqueda
  const handleItemSelection = event => {
    const { value } = event.target
    const selectedAcount = acounts.find(acount => acount.id === parseInt(value))
    setCurrentAcount(selectedAcount)
  }

  const fetchData = async () => {
    try {
      const [totalDeudasPagosResponse, listAcountBankResponse] = await Promise.all([
        bankAPI.getTotalDeudasPagos(currentBusiness.id),
        bankAPI.getListAcountBank(currentBusiness.id)
      ])

      setDataDeudasPagos(totalDeudasPagosResponse.data)

      const temporaryListAcountBank = [firstObjectListAcountBank, ...listAcountBankResponse.data]
      getDataListTransactionsAcount(temporaryListAcountBank[0].id)
      setAcounts(temporaryListAcountBank)
    } catch (error) {
      toast.error(
        'No se pudieron cargar los datos 🤯' + JSON.stringify(error.response.data.message)
      )
      console.error('Error:', error)
    }
  }

  // función trae lista de transacciones
  const getDataListTransactionsAcount = async defaultId => {
    try {
      const limit = 100
      const monthFormatter =
        currentMonth.toString().length === 1 ? `0${currentMonth}` : currentMonth
      const responseGetDataListTransactionsAcount = await bankAPI.getTransactionsAcount(
        currentBusiness.id,
        defaultId,
        limit,
        currentYear,
        monthFormatter
      )
      setTransactionsAcount(responseGetDataListTransactionsAcount.data)
    } catch (error) {
      console.log('Error:', error)
    }
  }

  useEffect(() => {
    if (acounts.length > 0) {
      setCurrentAcount(acounts[0])
      toast.success('Datos cargados exitosamente... 👌')
    }
  }, [acounts])

  useEffect(() => {
    fetchData()
  }, [currentBusiness])

  return (
    <div className='my-10 space-y-6 '>
      <div className='grid grid-cols-3 gap-6'>
        <section className='flex flex-col shadow border border-gray-300 p-4 rounded-2xl'>
          <h2 className='text-2xl'>Facturas por pagar</h2>
          <p className='text-[#8E9AB1] font-bold'>
            {deudasPagos.porPagar ? formatCurrency(deudasPagos.porPagar) : '$ 0'}
          </p>
          <p className='uppercase text-sm font-semibold text-accent6'>total</p>
        </section>
        <section className='flex flex-col shadow border border-gray-300 p-4 rounded-2xl'>
          <h1 className='text-2xl'>Facturas por cobrar</h1>
          <p className='text-[#8E9AB1] font-bold'>
            {deudasPagos.porCobrar ? formatCurrency(deudasPagos.porCobrar) : '$ 0'}
          </p>
          <p className='uppercase text-sm font-semibold text-accent6'>total</p>
        </section>
        <section className='flex flex-col shadow border border-gray-300 p-4 rounded-2xl'>
          <h1 className='text-2xl'>Saldo en cuenta</h1>
          <p className='text-[#8E9AB1] font-bold'>
            {currentAcount?.saldo ? formatCurrency(currentAcount?.saldo) : '$ 0'}
          </p>
          <p className='uppercase text-sm font-semibold text-accent6'>total</p>
        </section>
      </div>

      <div className='grid grid-cols-4 gap-6'>
        <select
          className='appearance-none focus:outline-none focus:ring-0 border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-full shadow leading-tight'
          name='acount'
          onChange={handleItemSelection}
          value={currentAcount?.id || ''}
        >
          {acounts.map((unAcounts, index) => (
            <option key={unAcounts.id} value={unAcounts.id}>
              {index === 0
                ? 'Selecciona Banco'
                : `${unAcounts.bancoCuenta.bank.name}, ${unAcounts.cuentaTipo.name}, ${unAcounts.numeroCuenta}`}
            </option>
          ))}
        </select>
        <section className='border border-gray-300 rounded-full shadow leading-tight px-4 py-2 pr-8'>
          {currentAcount?.id === 0 ? (
            <span>Todas las cuentas</span>
          ) : (
            <>
              <span>{currentAcount?.bancoCuenta.bank.name}, </span>
              <span>{currentAcount?.cuentaTipo.name}, </span>
              <span>{currentAcount?.numeroCuenta}</span>
            </>
          )}
        </section>
        <Button
          onClick={setConnectBank}
          className='shadow-5xl border-none px-8 py-3 rounded-2xl uppercase bg-accent2 text-white font-bold text-xs'
          title='agregar nueva cuenta'
        />
        <Button
          onClick={e => {
            setOpenModalEditAcounts(true)
          }}
          className='shadow-5xl border-none px-8 py-3 rounded-2xl uppercase bg-accent6 text-white font-bold text-xs'
          title='editar cuentas'
        />
      </div>

      <List data={transactionsAcount} currentAcount={currentAcount} />
      {openModal && (
        <ModalDialogIndexConect
          setConnectBank={setConnectBank}
          open={openModal}
          setOpenModal={setOpenModal}
          dataInputsBanks={dataInputsBanks}
        />
      )}
      {openModalEditAcounts && (
        <ModalEditAcounts
          acounts={acounts}
          open={openModalEditAcounts}
          setOpenModal={setOpenModalEditAcounts}
        />
      )}
    </div>
  )
}
export default BancosTableInsights
