import clsx from 'clsx'
import { useState } from 'react'

//utils
import { formatCurrency } from '../../../components/utils/formatter'

//mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

//data
import { subColsMap, styles, orderableCols } from './data'

//components
import { colsBalanceGeneral as cols } from '../../../components/modules/data/headersTables'
import { Text } from '../../../components/modules/ui'

const Table = ({ rows }) => {
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

  // Función para manejar la ordenacion
  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  const searchColsIndex = col => orderableCols.find(item => item.col === col)

  if (!rows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>
  else
    return (
      <div className=''>
        <div className='overflow-x-auto'>
          <table className={[styles.table]}>
            <thead>
              <tr>
                {cols?.map((col, i) => (
                  <th
                    className={clsx(styles.th, { 'cursor-pointer': searchColsIndex(col) })}
                    onClick={() => searchColsIndex(col) && handleSort(searchColsIndex(col))}
                    key={i}
                    colSpan={subColsMap[col] ? subColsMap[col].length : 1}
                  >
                    {col}
                    {searchColsIndex(col) && (
                      <KeyboardArrowDownIcon
                        className={clsx('transition-all ml-2 cursor-pointer', {
                          'opacity-40': order.col !== col,
                          'rotate-180':
                            searchColsIndex(col).col === order.col && order.direction === 'desc'
                        })}
                      />
                    )}
                  </th>
                ))}
              </tr>
              <tr>
                {cols?.map((col, i) =>
                  subColsMap[col] ? (
                    subColsMap[col].map(subCol => (
                      <th className={styles.th} key={`${col}-${subCol}`}>
                        {subCol.charAt(0).toUpperCase() + subCol.slice(1)}
                      </th>
                    ))
                  ) : (
                    <th className={styles.th} key={i} />
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr className={styles.tr} key={i}>
                  {/* N° Cuenta */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.numCuenta ? row.numCuenta : ''}</Text>
                  </td>
                  {/* Nombre Cuenta */}
                  <td className={clsx(styles.td)}>
                    <Text className={styles.boldText}>{row.nameCuenta}</Text>
                  </td>
                  {cols?.map(col =>
                    subColsMap[col]
                      ? subColsMap[col].map(subCol => (
                          <td className={styles.td} key={`${col}-${subCol}`}>
                            <Text className={styles.text}>{formatCurrency(row[subCol])}</Text>
                          </td>
                        ))
                      : null
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
}

export default Table
