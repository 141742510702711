//components
import { VisionDelNegocio } from '../components/modules/views/dashboard/BusinessInsights/VisionDelNegocio/VisionDelNegocio'
import Tabs from '../components/modules/tabs/Tabs'
import Presupuesto from '../components/modules/views/dashboard/BusinessInsights/Presupuesto/Index'
import Reportes from '../components/modules/views/dashboard/BusinessInsights/Reportes/Reportes'
import Layout from '../components/layouts/index'

const data = [
  {
    title: 'Visión del negocio',
    content: <VisionDelNegocio />
  },
  {
    title: 'Presupuesto',
    content: <Presupuesto />
  },
  {
    title: 'Reportes',
    content: <Reportes />
  }
]

/**
 *
 * @returns {JSX.Element}
 */
export default function BusinessInsights() {
  return (
    <Layout>
      <div className=''>
        <Tabs tabs={data} />
      </div>
    </Layout>
  )
}
