import { FC } from "react"
import { useQueryGetRoles } from "../../../../../../queries/roleQueries"
import { useQueryGetAllBusinesses } from "../../../../../../queries/businessQueries"
import { BaseTableBody } from "../../../../../modules/ui/tables/baseTable/BaseTableBody"
import { Field, Form, Formik } from "formik"


export const Roles: FC = () => {
  const { data = [] } = useQueryGetRoles()
  const { data: business = [], isFetching } = useQueryGetAllBusinesses()
  return (
    <div className='py-2 pr-7'>
      <h3 className='font-bold text-xl mb-4 text-center'>Roles</h3>
      <div className='flex flex-col gap-8'>
        {data?.map((role) => (
          <Formik
            key={role.id}
            initialValues={{}}
            onSubmit={async () => { }}
          >
            {() => (
              <Form>
                <BaseTableBody
                  data={{
                    tBodyitems: business ?? [],
                    tHeadItems: [role?.name ?? '', "Ver", "Editar", "Eliminar"],
                  }}
                  // hasFooter={false}
                  isRoundedTop={true}
                  skeleton={{ isLoading: isFetching, numberOfRows: 5 }}
                >
                  {({ TRow, item: business }) => (
                    <TRow>
                      <td className="px-6 py-4 text-ellipsis overflow-hidden">
                        <div>{business?.name}</div>
                        <div className="text-sm text-gray-500">{business?.rut}</div>
                      </td>
                      <td className="px-6 py-4 text-ellipsis overflow-hidden">
                        <Field
                          type="checkbox"
                          name={`businessTable.business-${business.id}.ver`}
                          className="checkbox-secondary"
                        />
                      </td>
                      <td className="px-6 py-4 text-ellipsis overflow-hidden">
                        <Field
                          type="checkbox"
                          name={`businessTable.business-${business.id}.edtiar`}
                          className="checkbox-secondary"
                        />
                      </td>
                      <td className="px-6 py-4 text-ellipsis overflow-hidden">
                        <Field
                          type="checkbox"
                          name={`businessTable.business-${business.id}.eliminar`}
                          className="checkbox-secondary"
                        />
                      </td>
                    </TRow>
                  )}
                </BaseTableBody>
              </Form>
            )}
          </Formik>
        ))}
      </div>
    </div>
  )
}
