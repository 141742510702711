import clsx from 'clsx'

const Paper = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx('px-6 py-10 my-7', className, {
        'bg-white shadow-full-xs rounded-3xl': !className
      })}
      {...props}
    >
      {children}
    </div>
  )
}

export default Paper
