import { useState, useEffect } from 'react'
// import { useOnlyAcount } from '../useBanks.hook'

//components
import { contentModalDialogHeader } from './contentModalDialog'

//material
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import ClearIcon from '@mui/icons-material/Clear'
import { useBanks } from './../useBanks.hook'

//components
import ContentModialDialogAddBank from './ContentModialDialogAddBank'
// import ContentModalDialogAddAcount from './ContentModalDialogAddAcount'

const ModalDialogIndexConect = ({ open, setOpenModal, dataBankDialog }) => {
  const [page, setPage] = useState(1)
  const { iconBank, nameBank } = dataBankDialog
  const [formData, setFormData] = useState({
    rutUsuario: '',
    password: '',
    rol: '',
    agreement: '',
    accountNumber: '',
    accountType: ''
  })

  const addBankCount = useBanks(state => state.addBankCount)

  const handleCloseForm = () => {
    setFormData({
      rutUsuario: '',
      password: '',
      rol: '',
      agreement: '',
      accountNumber: '',
      accountType: ''
    })
    setOpenModal(false)
    // setOnlyAcount(false)
    setPage(1)
  }

  useEffect(() => {
    if (page === 3) {
      setTimeout(() => {
        setOpenModal(false)
        setFormData({
          rutUsuario: '',
          password: '',
          rol: '',
          agreement: '',
          accountNumber: '',
          accountType: ''
        })
        addBankCount()
      }, 3000)
    }
  }, [page])

  return (
    <Dialog
      open={open}
      scroll='body'
      onClose={handleCloseForm}
      PaperProps={{
        sx: {
          borderRadius: '30px'
        }
      }}
    >
      <div className='p-6'>
        <div className='cursor-pointer flex justify-end items-center' onClick={handleCloseForm}>
          <ClearIcon style={{ color: '#CCCCCC' }} sx={{ fontSize: 40 }} />
        </div>
        <div className='flex flex-col items-center'>
          <div className='flex shadow-5xl border-none py-4 px-8 rounded-2xl w-[76%] items-center justify-start gap-5 my-10'>
            <img className='w-20 h-20' src={iconBank} alt='iconBank' />
            <p>{nameBank}</p>
          </div>
          <DialogContent className='flex flex-col items-center justify-center w-full gap-10'>
            {page === 1 && (
              <ContentModialDialogAddBank
                formData={formData}
                setFormData={setFormData}
                dataBankDialog={dataBankDialog}
                setPage={setPage}
                setOpenModal={setOpenModal}
              />
            )}
            {page === 2 && contentModalDialogHeader()}
            {page === 3 && contentModalDialogHeader('successConnect')}
          </DialogContent>
        </div>
      </div>
    </Dialog>
  )
}

export default ModalDialogIndexConect
