import { useEffect } from 'react'
//ui
import { Paper, Title, SearchInput } from '../../../../ui/index'

//assets
import iconMas from '../../../../../../assets/iconos/iconmas.png'
import iconPuntos from '../../../../../../assets/iconos/iconPuntos.png'
import iconPregunta from '../../../../../../assets/iconos/repgunta.png'
import StarFullIcon from '@mui/icons-material/StarOutlined'

// hooks
import { useContentTypeReportes } from '../../../../../../pages/Reportes/useReportes.hook'

const FavoriteNavBar = () => {
  const styleImage = 'cursor-pointer w-10 h-10'
  const favoriteIcons = useContentTypeReportes(state => state.favoriteIcons)
  const removeFavoriteIcon = useContentTypeReportes(state => state.removeFavoriteIcon)
  const setFavoriteContent = useContentTypeReportes(state => state.setFavoriteContent)
  const resetTypeContent = useContentTypeReportes(state => state.resetTypeContent)

  useEffect(() => {
    resetTypeContent()
  }, [resetTypeContent])

  const handleIconClick = (icon) => {
    setFavoriteContent(icon.id)
  }

  return (
    <div>
      { favoriteIcons.length === 0 ? (
      <p></p>
        ) : (
      <Paper>
        <section className='flex items-center justify-between'>
          <Title>Favoritos</Title>
          <SearchInput placeholder='Buscar reporte' classNameBorder='rounded-full' />
        </section>
        <section className='grid grid-rows-2 grid-flow-col gap-2 py-4'>
          {favoriteIcons.map((icon, index) => (
            <div key={index} className='flex flex-col items-center justify-center shadow-md border rounded-2xl pb-2'
            onClick={() => handleIconClick(icon)} >
              <div className='flex items-center justify-between rounded-full'>
                <img className={styleImage} src={iconPregunta} alt='icon' />
                <StarFullIcon className={styleImage} style={{ color: 'orange' }}
                  alt='icon' onClick={(e) => {e.stopPropagation(); removeFavoriteIcon(icon.id)}} />
                <img className={styleImage} src={iconPuntos} alt='icon' />
                <img className={styleImage} src={iconMas} alt='icon' />
              </div>
              <img className='w-20 h-20 cursor-pointer' src={icon.icon} alt={icon.title} />
              <p className='text-center text-xs'>{icon.title}</p>
            </div>
          ))}
        </section>
      </Paper>
      )}
    </div>
  )
}

export default FavoriteNavBar