import Wellcome from './Wellcome'
import { usePresupuesto } from './usePresupuesto.hook'
import PresupuestoTableInsights from './PresupuestoTableInsights'

const Presupuesto = () => {
  const presupuestoCount = usePresupuesto(state => state.presupuestoCount)

  const Content = () => {
    if (presupuestoCount > 0) {
      return <PresupuestoTableInsights />
    }
    return <Wellcome />
  }
  return (
    <div>
      <Content />
    </div>
  )
}

export default Presupuesto
