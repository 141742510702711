/* eslint-disable */
// const REACT_APP_BASE_URL = 'http://localhost:8080'
const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

// Months Array and Options for Select
const monthsArr = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  monthsOptions = Object.freeze(
    monthsArr.map((month, i) => {
      return { value: i + 1, label: month }
    })
  ),
  currentMonth = new Date().getMonth() + 1

// Years Array and Options for Select
const currentYear = new Date().getFullYear(),
  yearsArr = Array.from({ length: currentYear - 1989 }, (_, index) => currentYear - index),
  yearsOptions = Object.freeze(
    yearsArr.map(year => {
      return { value: year, label: year }
    })
  )

const getCurrentDate = () => {
  const today = new Date()
  const month = String(today.getMonth() + 1).padStart(2, '0')
  const year = today.getFullYear()
  const date = String(today.getDate()).padStart(2, '0')
  return `${date}-${month}-${year}`
}

export {
  REACT_APP_BASE_URL,
  currentYear,
  currentMonth,
  monthsOptions,
  yearsOptions,
  getCurrentDate
}
