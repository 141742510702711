import { create } from 'zustand'
import { ICreateUser } from '../../../../../models/User';
import { ICreateOrganization } from '../../../../../models/Organization';


type AccountProfile = Partial<ICreateUser & ICreateOrganization>

interface ILucaSignUpState {
  slide: number;
  nextSlide: () => void;
  accountProfile: AccountProfile;
  setAccountProfile: (accountProfile: Partial<AccountProfile>) => void;
}

export const useLucaSignUp = create<ILucaSignUpState>(set => ({
  slide: 0,
  nextSlide: () => set(prev => ({ ...prev, slide: prev.slide + 1 })),

  accountProfile: {
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: undefined,

    organizationName: '',
    userType: '',
    rut: '',
    siiPassword: ''
  },

  setAccountProfile: accountProfile =>
    set(prev => ({ ...prev, accountProfile: { ...prev.accountProfile, ...accountProfile } }))
}))
