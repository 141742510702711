import { ErrorMessage, Field, useFormikContext } from 'formik'
import { useState } from 'react'



export function FieldWithOutLabel({ name, type, placeholder, isDisabled = false }) {
  const { errors, touched } = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className='flex items-center gap-5 w-full'>
      <div className='pl-1 w-full'>
        <div className='relative w-full'>
          <Field
            disabled={isDisabled}
            type={showPassword ? 'text' : type}
            id={name}
            name={name}
            className={`w-full ${touched[name] && errors[name] ? 'input-error' : 'input-primary border-transparent shadow-none bg-gray-50'}`}
            placeholder={placeholder}
          />

          {type === 'password' && (
            <button
              type='button'
              className='absolute right-2 top-1/2 translate-y-[-50%] text-sm font-bold'
              onClick={isDisabled ? null : () => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          )}
        </div>

        <div className='h-4'>
          <ErrorMessage name={name}>
            {msg => <p className='text-orange-400 text-sm'>{msg}</p>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  )
}
