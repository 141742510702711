import { FC } from "react";
import iconLapiz from '../../../../../../assets/iconos/iconLapiz.png'
import { useQueryGetUsersAll } from "../../../../../../queries/userQueries";


const thTable = [{ name: 'Nombre' }, { name: 'Perfil de usuario' }, { name: 'Editar' }]


export const UsersList: FC = () => {
  const { data: userOrganizations = [] } = useQueryGetUsersAll()

  return (
    <table className="mr-6">
      <thead>
        <tr>
          {thTable?.map((head, index) => (
            <th key={index} className="text-lg text-start pl-4">
              {head.name}
            </th>
          ))}
        </tr>
      </thead>

      <tbody className='border-t-4 border-gray-200 mt-6'>
        {userOrganizations?.map((unTdTable, index) => (
          <tr key={index} className="">
            <td className='py-3 pl-4'>{unTdTable.user?.firstname}</td>
            <td className='py-3 pl-4'>{unTdTable.role?.name}</td>
            <td className='w-[20%] py-3 cursor-pointer'>
              <img className='ml-6 w-[30%] h-[30%] rounded-full shadow-full-xs' src={iconLapiz} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
