export enum QueryKeys {
  GET_ROLES = 'GET_ROLES',
  GET_ROLE = 'GET_ROLE',
  CREATE_ROLE = 'CREATE_ROLE',
  UPDATE_ROLE = 'UPDATE_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
  INVITE_USER = 'INVITE_USER',
  GET_USER_ME = 'GET_USER_ME',
  GET_USERS_ALL = 'GET_USERS',
  GET_BUSINESSES_ALL = 'GET_BUSINESS',
  GET_BUSINESS_BY_ID = 'GET_BUSINESS_BY_ID',
}
