import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatCurrency, formatDate } from '../../../utils/formatter'

const cols = [
  { header: 'Fecha', width: 80 },
  { header: 'Tipo de Comprobante', width: 80 },
  { header: 'Comprobante', width: 50 },
  { header: 'Nombre Cuenta', width: 120 },
  { header: 'Rut', width: 80 },
  { header: 'Razon Social', width: 120 },
  { header: 'Documento', width: 50 },
  { header: 'Glosa', width: 80 },
  { header: 'Debe', width: 80 },
  { header: 'Haber', width: 80 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginVertical: '16px',
    marginHorizontal: 'auto',
    fontSize: 8
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center'
  },
  col: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 4
  },
  boldText: {
    fontWeight: '700'
  },
  text: {
    fontSize: 5,
    color: '#5a5a5a'
  }
})

const ExportPDFReportesLibroDiario = ({ data, title, year, month, currentBusiness, rut }) => {
  const MyDocument = () => {
    const rowsPerPage = 10 // Ajusta este valor según la cantidad de filas que caben en una página

    const pageCount = Math.ceil(data.length / rowsPerPage)

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.section}>
              {pageIndex === 0 && (
                <Text> {`Resumen de movimientos ${currentBusiness} ${rut}`}</Text>
              )}
              <View style={styles.table}>
                <View style={{ ...styles.row, backgroundColor: '#FFFFE0' }}>
                  {cols?.map((col, i) => (
                    <View style={{ ...styles.col, width: col.width, fontWeight: 'bold' }} key={i}>
                      <Text style={styles.boldText}>{col.header}</Text>
                    </View>
                  ))}
                </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatDate(row.fecha)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>
                          {row.tipoComprobante.nombreTipoComprobante}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 50 }}>
                        <Text style={styles.text}>{row.idOrigin}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.boldText}>{row.cuentaContable.nombreCuenta}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={[styles.boldText, { textAlign: 'right' }]}>
                          {row.informacion?.rutEmpresa}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.text}>{row.informacion?.razonSocial}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 50 }}>
                        <Text style={styles.text}>{row.numero}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.glosa}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{formatCurrency(row.debe)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{formatCurrency(row.haber)}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  }

  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`${title}_${currentBusiness}_${month}_${year}.pdf`}
    >
      PDF
    </PDFDownloadLink>
  )
}

export default ExportPDFReportesLibroDiario
