import { AxiosInstance } from "axios";
import { ICreateRole, IRole } from "../models/Roles";
import { ICreateInvitation } from "../models/User";
import { BaseHTTP } from "./base.http"


export class RolesApi {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  async getRoles() {
    return this.http.get<IRole[]>('v1/roles')
  }

  async getRoleById(id: number) {
    return this.http.get<IRole>(`v1/roles/${id}`);
  }

  async createRole(createRolePayload: ICreateRole) {
    return this.http.post('v1/roles', createRolePayload);
  }

  async updateRole(updateRolePayload: Partial<ICreateRole>) {
    return this.http.patch('v1/roles', updateRolePayload);
  }

  async deleteRole(id: number) {
    return this.http.delete(`v1/roles/${id}`);
  }

  async invitateUserWithEmail(createInvitationPayload: ICreateInvitation) {
    return this.http.post<void>('v1/users/invitations', createInvitationPayload);
  }
}

export default new RolesApi()
