import { ErrorMessage, Field, useFormikContext } from 'formik'
import iconLapiz from '../../../assets/iconos/iconLapiz.png'
import { TooltipButton } from '../authentication/SignUp/components/Tooltip'
import iconoCandadoGris from '../../../assets/iconos/iconoCandadoGris.jpeg'
import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import GlobalContext from '../../../context/global-context'
import { REACT_APP_BASE_URL } from '../../utils/constant'
import { BaseHTTP } from '../../../api/base.http'

/**
 * @typedef {Object} FieldWithErrorMessageProps
 * @property {string} name - The name of the field.
 * @property {string} type - The type of the field.
 * @property {string} placeholder - The placeholder for the field.
 * @property {string} label - The label for the field.
 * @property {boolean} tooltip
 * @property {boolean} isDisabled
 */

/**
 * A component that renders a Formik field with an error message.
 * @param {FieldWithErrorMessageProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export function FieldWithToggleButton({
  name,
  type,
  placeholder,
  label,
  tooltip,
  isDisabled = false
}) {
  const { errors, touched, values } = useFormikContext()
  const { currentBusiness, setCurrentBusiness } = useContext(GlobalContext)
  const [showPassword, setShowPassword] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const handleEditableInputFocus = () => {
    setIsEditable(true)
    if (!isEditable) {
      setTimeout(() => {
        document.getElementById(name)?.focus()
      }, 200)
    }
  }

  const handleSaveValue = async () => {
    try {
      if (touched[name] && !errors[name]) {
        setIsEditable(false)
        const patchRequest = BaseHTTP.getInstance().http.patch(
          `${REACT_APP_BASE_URL}/v1/business/${currentBusiness.id}`,
          { [name]: values[name] }
        )
        await toast.promise(patchRequest, {
          pending: `Guardando ${label}... 🚀`,
          success: `${label} ${label === 'Dirección' ? 'guardada' : 'guardado'} exitosamente 👌`,
          error: `No se pudo guadar ${label} 🤯`
        })
        setCurrentBusiness({ ...currentBusiness, [name]: values[name] })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='flex items-center gap-5'>
      <div className='pl-1'>
        <div className='flex items-center gap-2'>
          <label htmlFor={name} className='input-label normal-case'>
            <span>{label}</span>
          </label>

          <div>{tooltip && <TooltipButton />}</div>
        </div>

        <div className='relative'>
          {isDisabled ? (
            <Field
              disabled={true}
              type={showPassword ? 'text' : type}
              id={name}
              name={name}
              className={`w-52 ${touched[name] && errors[name]
                ? 'input-error'
                : 'cursor-not-allowed input-primary border-transparent shadow-none bg-gray-50'
                }`}
              placeholder={placeholder}
            />
          ) : (
            <Field
              disabled={!isEditable}
              type={showPassword ? 'text' : type}
              id={name}
              name={name}
              className={`w-52 ${touched[name] && errors[name]
                ? 'input-error'
                : 'input-primary border-transparent shadow-none bg-gray-50'
                }`}
              placeholder={placeholder}
            />
          )}
          {type === 'password' && (
            <button
              type='button'
              className='absolute right-2 top-1/2 translate-y-[-50%] text-sm font-bold'
              onClick={isDisabled ? null : () => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          )}
        </div>

        <div className='h-4'>
          <ErrorMessage name={name}>
            {msg => <p className='text-orange-400 text-sm'>{msg}</p>}
          </ErrorMessage>
        </div>
      </div>

      <div className='w-16 flex justify-center items-center'>
        {!isEditable ? (
          <button
            className={isDisabled ? 'cursor-not-allowed' : ''}
            type='button'
            onClick={isDisabled ? null : handleEditableInputFocus}
          >
            <img
              className='bg-white rounded-full w-9 h-9 shadow-md'
              src={isDisabled ? iconoCandadoGris : iconLapiz}
              alt='edit-icon'
            />
          </button>
        ) : (
          <button
            type='button'
            onClick={handleSaveValue}
            className='hover:bg-gray-200 rounded p-3 text-sm font-bold'
          >
            Guardar
          </button>
        )}
      </div>
    </div>
  )
}
