import { useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//hooks
import { useUpdateDataAdministracion } from '../../../../../../pages/useAdministracion.hooks'

// MUI
import { Backdrop } from '@mui/material'

// components
import { HeadingTitle, Paper, Text } from '../../../../ui/index'
import { BusinessAPI } from '../../../../../../api/business.api'
import TableEgresos from './TableEgresos'
import Select from '../../../../ui/Select'
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
  yearsOptions
} from '../../../../../utils/constant'
import { ExportCSV, ExportXLS } from '../../../../table/ExportData'
import ExportPDF from '../../../../table/typesExportPDF/ExportPDF'
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import CustomButton from '../../../../ui/CustomButton'
import { tipoDocumento } from '../../../../data/dataTipoDocument'
import FilterPagination from '../../../../table/FilterPagination'

const ListEgresos = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [opciones, setOpcionesFilter] = useState([])
  const [initialFilterDocumento, setInitialFilterDocumento] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)

  //hooks
  const updateData = useUpdateDataAdministracion(state => state.updateData)
  const setUpdateData = useUpdateDataAdministracion(state => state.setUpdateData)


  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      const businessAPI = new BusinessAPI()
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getListSummaryMovements({
          type: 'egreso',
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter,
          code: opciones || null ? opciones : data
        })
        .then(({ data }) => {
          setData(data)
          setUpdateData(false)
          if (initialFilterDocumento.length === 0) {
            setInitialFilterDocumento([
              { value: '', label: 'Todos los documentos' },
              ...tipoDocumento.filter(doc => data.some(item => item.nombreFolio === doc.label))
            ])
          }
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => {
    return {
      'Nombre folio': item.nombreFolio,
      'Número folio': item.numeroFolio,
      'Razon Social': item.razonSocial,
      RUT: item.rut && formatRut(item.rut),
      Fecha: item.fecha && formatDate(item.fecha),
      'Fecha de vencimiento': item.fechaVencimiento && formatDate(item.fechaVencimiento),
      'Días hasta vencimiento': item.diasHastaVencimiento,
      Clasificación: item.nombreCuenta,
      'Codigo de cuenta': item.codigoCuenta,
      'Codigo cuenta': item.codigoCuenta,
      'Número comprobante': item.id,
      'Usuario': ' @' + (item.usuarioClasificador || 'Luca'),
      Monto: formatCurrency(item.montoTotal),
      'IVA/RETENIDO': formatCurrency(item.montoIvaRetenido),
      'NETO/LIQUIDO': formatCurrency(item.montoNetoLiquido),
      Status: item.status,
      // 'Fecha pago': item.fechaPago ? formatDate(item.fechaPago) : 'Sin pagos',
      Aprobado: item.aprobado ? 'Si' : 'No'
    }
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='Engresos/Honorarios'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  const filtroDocumento = useMemo(() => {
    return initialFilterDocumento
  }, [initialFilterDocumento])

  useEffect(() => {
    getData()
  }, [currentBusiness])

  useEffect(() => {
    getData()
  }, [updateData === true])


  return (
    <>
      <Paper>
        <HeadingTitle title='Resumen de movimientos' rightIcons={icons} iconHeight={32} />
        <div>
          <div className='flex gap-2 items-end'>
            <Select
              options={monthsOptions}
              defaultValue={monthFilter}
              onChange={e => setMonthFilter(e.target.value)}
            />
            <Select
              // TODO: Eliminar filter luego de presentar
              options={yearsOptions.filter(year => year.value === 2024)}
              defaultValue={yearFilter}
              onChange={e => setYearFilter(e.target.value)}
            />
            <Select
              options={filtroDocumento}
              defaultValue=""
              onChange={e => setOpcionesFilter(e.target.value ? [e.target.value] : [])}
            />
            <FilterPagination
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={setRowsPerPage}
            />
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ borderRadius: 100 }}
            label='Mes'
            views={['month']}
            onMonthChange={e => setMonthFilter(e.$M + 1)}
          />
          <DatePicker
            sx={{ borderRadius: 100 }}
            label='Año'
            views={['year']}
            onYearChange={e => setYearFilter(e.$y)}
          />
        </LocalizationProvider> */}
        </div>
        <TableEgresos rows={data} getData={getData} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListEgresos
