import clsx from 'clsx'
import { Text } from '../../../../../../ui'
import { formatCurrency } from '../../../../../../../utils/formatter'

const color = {
  primary: 'bg-primary',
  secondary: 'bg-secondary',
  accent1: 'bg-accent1',
  accent2: 'bg-accent2',
  accent3: 'bg-accent3',
  accent4: 'bg-accent4',
  accent5: 'bg-accent5',
  accent6: 'bg-accent6'
}

const HorizontalBar = ({ value = 0, width, height = 5, label = false, barColor, textColor, stacked }) => {
  if (value === 0) {
    return (
      <span
        className={clsx(
          {
            [color[barColor]]: barColor
          },
          'rounded-l transition-all duration-500 ease-in-out'
        )}
        style={{ width: 5, height, marginTop: stacked && -height / 2 }}
      />
    )
  }

  return (
    <div className='relative flex justify-start items-center'> {/* Cambiar a items-center para alineación */}
      <span
        className={clsx(
          {
            [color[barColor]]: barColor
          },
          'rounded-l transition-all duration-500 ease-in-out'
        )}
        style={{ 
          width: width > 5 ? width : 5, 
          height, 
          marginTop: stacked && -height / 6,
          transform: 'scaleX(-1)'
        }}
      />
      {label && (
        <Text
          className={clsx(
            'absolute left-16 transform -translate-x-1/2 text-lg font-bold text-shadow', // Centrar texto horizontalmente
            textColor || 'text-white',
            'whitespace-nowrap'
          )}
        >
          {formatCurrency(value)}
        </Text>
      )}
    </div>
  )
}

export { HorizontalBar }
