import { useState, useEffect, useContext } from 'react'
import GlobalContext from '../../../../../../context/global-context'
import { useBanks } from './useBanks.hook'

//api
import { BankAPI } from '../../../../../../api/bancos.api'

//componenets
import Wellcome from './Wellcome'
import DashboardConnectBank from './connect/DashboardConnectBank'
import IndexTableMovements from './table/BancosTableInsights'

const Index = () => {
  const { ui, currentBusiness, setCurrentBank } = useContext(GlobalContext)
  const bankAPI = new BankAPI()
  //const [hasBanks, setHasBanks] = useState(false)
  const [connectBank, setConnectBank] = useState(false)

  const banksCount = useBanks(state => state.banksCount)
  const setBanksCount = useBanks(state => state.setBanksCount)

  // primero se muestra loading
  // se hace requests de bancos
  // una vez que carga la request, se ve el largo de la respuesta para generar hasbanks
  // hasBanks = response.lenght > 0; // si es mayor a 0, true, si no, false
  // set loading to false

  const getData = async () => {
    ui.setLoader({ visible: true, text: 'cargando datos' })
    try {
      const responseGetListBank = await bankAPI.getListBank(currentBusiness.id)
      setCurrentBank(responseGetListBank.data[0])
      setBanksCount(responseGetListBank.data.length)
    } catch (error) {
      // Manejo de errores
      banksCount > 0 &&
        ui.setDialog({
          title: 'Error',
          body: error.response?.data?.message || 'Error al realizar la solicitud',
          btnText: 'Cerrar',
          open: true
        })
      console.log('Error:', error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness, banksCount])

  const Content = () => {
    if (connectBank) {
      return (
        <DashboardConnectBank
          setConnectBank={() => {
            setConnectBank(false)
          }}
        />
      )
    }
    if (banksCount > 0) {
      return <IndexTableMovements setConnectBank={() => setConnectBank(true)} />
    }
    return <Wellcome setConnectBank={() => setConnectBank(true)} />
  }

  return <Content />
}

export default Index
