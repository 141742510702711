import { FC } from "react";
import iconDialogo from '../../../../assets/iconos/iconDialogo.png'
import iconCelu from '../../../../assets/iconos/iconCelu.png'
import iconUbicacion from '../../../../assets/iconos/iconUbicacion.png'
import { ReactComponent as IsoLogo } from '../../../../assets/isoLogo1.svg'
import { ReactComponent as LogoLuca } from '../../../../assets/logoLuca.svg'
import { contentModalDialogFooter } from '../../views/dashboard/Administracion/bancos/connect/contentModalDialog'
import { Outlet } from "react-router-dom";


export const AuthLayout: FC = () => {
  return (
    <div className='font-sans h-screen bg-slate-50 flex flex-col justify-between' >
      <header className='bg-primary h-[74px] w-full px-12 flex items-center shadow'>

        <div className='flex gap-2'>
          <IsoLogo className='text-slate-50 w-10 h-10' />
          <LogoLuca className='text-slate-50 w-[120px] h-auto' />
        </div>
      </header>

      <div className='flex-1 flex gap-24 items-center justify-center relative'>
        <Outlet />
      </div>
    </div>
  )
}


export const ContactInfo: FC = () => (
  <div className='flex flex-col gap-9  mb-20'>
    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconDialogo} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>hablemos</p>
        <p>contacto@tryluca.com</p>
      </div>
    </div>

    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconCelu} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>llamanos</p>
        <p>+56 9 8662 5874</p>
      </div>
    </div>

    <div className='flex gap-2 items-center'>
      <img className='w-12 h-12' src={iconUbicacion} alt='iconUbicacion' />
      <div>
        <p className='font-bold uppercase'>visitanos</p>
        <p>
          Santa Magdalena 75, <br /> Providencia Santiago <br />
          Chile.
        </p>
      </div>
    </div>
  </div>
)

export const SecurityTSL: FC = () => (
  <div className='w-1/4'>
    {contentModalDialogFooter()}
  </div>
)
