import { useState } from 'react'
import infoIcon from '../../../../../assets/iconos/circle-info-solid.svg'


export function TooltipButton() {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className='relative block'>
      {isVisible && (
        <div
          role='tooltip'
          className={`
        absolute block w-52 bottom-8 z-10 p-4 text-sm font-medium transition-opacity duration-300 bg-slate-950/65 border text-slate-100  border-slate-500  rounded-lg shadow-lg`}
        >
          <h2 className="mb-2 text-lg font-bold text-center">Requisitos</h2>
          <ul className="flex flex-col gap-2">
            <li className="flex items-center leading-4">
              <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              8 caracteres mínimo
            </li>
            <li className="flex items-center leading-4">
              <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              Al menos una letra mayúscula
            </li>
            <li className="flex items-center leading-4">
              <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
              </svg>
              Al menos una letra minúscula
            </li>
          </ul>

        </div>
      )}
      <img
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className='z-30 h-5 w-5 cursor-pointer relative bottom-1' src={infoIcon} alt="info-icon"
      />
    </div>
  )
}
