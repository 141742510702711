import { createContext } from 'react';
import { IGlobalContext } from '../models/Global-Context';



const GlobalContext = createContext<IGlobalContext>({
  ui: {
    loader: { visible: false, text: '' },
    setLoader: () => {},
    dialog: { title: '', body: '', btnText: '', open: false },
    setDialog: () => {},
    dialogForm: { title: '', body: '', btnTextClose: '', btnTextOpen: '', open: false },
    setDialogForm: () => {},
  },
  business: null,
  setBusiness: () => {},
  currentBusiness: undefined,
  setCurrentBusiness: () => {},
  currentBank: undefined,
  setCurrentBank: () => {},
});

export default GlobalContext;

