import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatCurrency, formatDate, formatRut } from '../../../utils/formatter'
// import { CircularProgress } from '@mui/material'

const cols = [
  { header: 'Folio', width: 120 },
  { header: 'Cliente/Proveedor', width: 170 },
  { header: 'Fecha', width: 80 },
  { header: 'Clasificación', width: 170 },
  { header: 'Monto', width: 120 },
  { header: 'Status', width: 80 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginVertical: '10px',
    marginHorizontal: 'auto',
    fontSize: 12
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center',
    breakInside: 'avoid'
  },
  col: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 3
  },
  boldText: {
    fontWeight: '700',
    fontSize: 10 
  },
  text: {
    fontSize: 9,
    color: '#5a5a5a'
  }
})

const ExportPDF = ({ data, title, year, month, currentBusiness, rut }) => {
  const MyDocument = () => {
    const rowsPerPage = 10 // Ajusta este valor según la cantidad de filas que caben en una página

    const pageCount = Math.ceil(data.length / rowsPerPage)
    // const pages = Array.from({ length: pageCount }, (_, i) =>
    //   data.slice(i * rowsPerPage, i * rowsPerPage + rowsPerPage)
    // )

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.section}>
              {pageIndex === 0 && <Text>
                {`Resumen de movimientos
                ${currentBusiness}
                ${rut}`} </Text>}
              <View style={styles.table}>
                <View style={{ ...styles.row, backgroundColor: '#FFFFE0' }}>
                  {cols?.map((col, i) => (
                    <View style={{ ...styles.col, width: col.width, fontWeight: 'bold' }} key={i}>
                      <Text style={styles.boldText}>{col.header}</Text>
                    </View>
                  ))}
                </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.boldText}>{row.nombreFolio}</Text>
                        <Text style={styles.text}>{row.numeroFolio}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.boldText}>{row.razonSocial}</Text>
                        <Text style={styles.text}>{row.rut && formatRut(row.rut)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatDate(row.fecha)}</Text>
                        <Text style={styles.text}>{formatDate(row.fechaVencimiento)}</Text>
                        <Text style={styles.text}>{row.diasHastaVencimiento}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.boldText}>{row.nombreCuenta}</Text>
                        <Text style={styles.text}>{row.codigoCuenta}</Text>
                        <Text style={styles.text}>{row.id} {' @' + (row.usuarioClasificador || 'Luca')}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={[styles.boldText, { textAlign: 'right' }]}>
                          {formatCurrency(row.montoTotal)}
                        </Text>
                        <Text style={[styles.text, { textAlign: 'right', color: '#ff0000', fontSize: styles.boldText.fontSize }]}>
                          IVA: {formatCurrency(row.montoIvaRetenido)}
                        </Text>
                        <Text style={[styles.boldText, { textAlign: 'right', fontSize: styles.boldText.fontSize }]}>
                          NETO: {formatCurrency(row.montoNetoLiquido)}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{row.status}</Text>
                        <Text style={styles.text}>
                          {row.fechaPago && formatDate(row.fechaPago)}
                        </Text>
                        <Text style={styles.text}>{row.aprobado ? 'Aprobado' : 'Aprobar'}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  }

  // const onClick = () => {
  //   setShowModal({
  //     show: true,
  //     title: 'Exportar a PDF',
  //     body: () => (
  //       <PDFDownloadLink orientation='landscape' document={<MyDocument />} fileName='data.pdf'>
  //         {({ loading }) =>
  //           loading ? <CircularProgress color='primary' thickness={5} size={60} /> : 'Descargar PDF'
  //         }
  //       </PDFDownloadLink>
  //     )
  //   })
  // }
  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`movimientos_${title}_${currentBusiness}_${rut}_${month}_${year}.pdf`}
    >
      {/* {({ loading }) =>
        loading ? <CircularProgress color='primary' thickness={3} size={20} /> : 'PDF'
      } */}
      PDF
    </PDFDownloadLink>
  )
  //   <>
  //     <button
  //       onClick={onClick}
  //       className='w-10 h-10 text-blue-700 font-bold cursor-pointer rounded-full shadow-full-xs bg-white'
  //     >
  //       PDF
  //     </button>
  //   </>
  // )
}

export default ExportPDF
