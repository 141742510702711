/* eslint-disable no-console */
import { AxiosInstance } from 'axios'
import { BaseHTTP } from './base.http'


export class TablesAPI {

  private http !: AxiosInstance

  constructor() {
    this.http = BaseHTTP.getInstance().http
  }

  getDataHistoricalSales(params) {
    const path = `v1/business/${params}/resume`
    return this.http.get(path)
  }

  getDataBalance(params) {
    const { businessId, year } = params
    const path = `v1/business/${businessId}/balance?year=${year}`
    return this.http.get(path)
  }

  patchApproveAll(businessId, ids){
    const payload = { ids: ids }
    const path = `v1/business/${businessId}/approve-all`
    return this.http.patch(path, payload)
  }
}

export default new TablesAPI()
