/* eslint-disable */

export const dataInputsBanks = [
  {
    id: '1',
    nameInput: 'bancoBCI',
    name: 'Banco BCI',
    icono: require('../../../../../../assets/iconosBancos/bancoBCI.jpeg'),
    nameBD: 'bci'
  },
  {
    id: '2',
    nameInput: 'bancoConsorcio',
    name: 'Banco Consorcio',
    icono: require('../../../../../../assets/iconosBancos/bancoConsorcio.jpeg'),
    nameBD: 'consorcio'
  },
  {
    id: '3',
    nameInput: 'bancoSantander',
    name: 'Banco Santander',
    icono: require('../../../../../../assets/iconosBancos/bancoSantander.jpeg'),
    nameBD: 'santander'
  },
  {
    id: '4',
    nameInput: 'bancoItau',
    name: 'Banco Itau',
    icono: require('../../../../../../assets/iconosBancos/bancoItau.jpeg'),
    nameBD: 'itau'
  },
  {
    id: '5',
    nameInput: 'bancoSecurity',
    name: 'Banco Security',
    icono: require('../../../../../../assets/iconosBancos/bancoSecurity.jpeg'),
    nameBD: 'bancosecurity'
  },
  {
    id: '6',
    nameInput: 'bancoChile',
    name: 'Banco Chile',
    icono: require('../../../../../../assets/iconosBancos/bancoChile.jpeg'),
    nameBD: 'bancochile'
  },
  {
    id: '7',
    nameInput: 'bancoEstado',
    name: 'Banco Estado',
    icono: require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
    nameBD: 'bancoestado'
  },
  {
    id: '8',
    nameInput: 'bancoInternacional',
    name: 'Banco Internacional',
    icono: require('../../../../../../assets/iconosBancos/bancoInternacional.jpeg'),
    nameBD: 'internacional'
  },
  {
    id: '9',
    nameInput: 'bancoScotiabank',
    name: 'Banco Scotiabank',
    icono: require('../../../../../../assets/iconosBancos/bancoScotiabank.jpeg'),
    nameBD: 'scotiabank'
  },
  {
    id: '10',
    nameInput: 'bancoBice',
    name: 'Banco Bice',
    icono: require('../../../../../../assets/iconosBancos/bancoBice.jpeg'),
    nameBD: 'bice'
  }
]

export const firstObjectListAcountBank = {
  id: 0,
  bancoCuentaId: null,
  numeroCuenta: null,
  cuentaTipoId: null,
  tipoDivisaId: null,
  saldo: null,
  updatedAt: null,
  cuentaTipo: { id: null, name: null },
  tipoDivisa: { id: null, name: null },
  bancoCuenta: {
    id: null,
    businessId: null,
    bankId: null,
    rutPersona: null,
    role: null,
    convenio: null,
    bank: { id: null, name: null }
  }
}

export const bankIcons = {
  'Banco BICE': require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  'Banco de Chile': require('../../../../../../assets/iconosBancos/bancoChile.jpeg'),
  'Banco Estado': require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  'Banco Internaci': require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  'Banco Santander': require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  'Banco Security': require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  BCI: require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  Consorcio: require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  Itaú: require('../../../../../../assets/iconosBancos/bancoEstado.jpeg'),
  Scotiabank: require('../../../../../../assets/iconosBancos/bancoEstado.jpeg')
}
