//assets
import imgBancosBienvenida from '../../../../../../assets/businessInsights/Recurso 3.svg'

//hooks
import { usePresupuesto } from './usePresupuesto.hook'

//components
import { Text, Button } from '../../../../ui'

const Wellcome = () => {
  const setPresupuestoCount = usePresupuesto(state => state.setPresupuestoCount)

  return (
    <div className='grid grid-cols-2 pt-16 gap-10'>
      <div className='flex flex-col gap-10'>
        <Text className='text-4xl mb-6'>Planifica tus finanzas</Text>
        <Text className='text-2xl'>
          Obtén visibilidad de tus finanzas priorizando gastos y fijandote algunas metas.
        </Text>
        <ul className='list-disc flex flex-col gap-3 pl-5 text-2xl'>
          <li>Crea objetivos y comparalos periodicamente.</li>
          <li>Compara tus resultados con tus metas.</li>
        </ul>
        <Button
          className='font-bold bg-accent2 text-white mt-6 w-[30%]'
          title='Crear Presupuesto'
          onClick={() => setPresupuestoCount(1)}
        />
      </div>
      <div>
        <img className='w-full md:w-7/12' src={imgBancosBienvenida}></img>
      </div>
    </div>
  )
}

export default Wellcome
