import VerticalStackedBarsChart from './verticalStackedBarsChart/VerticalStackedBarsChart'
import { Paper } from '../../../../../ui'
import MirrorTable from '../../../../../table/MirrorTable'
import { useGlobalContext } from '../../../../../../../context/GlobalState'
import { BusinessAPI } from '../../../../../../../api/business.api'
import { useEffect, useState } from 'react'
import TableTop5 from '../../../../../table/TableTop5'
import { BarFilterHistoricalSearch } from '../../../../../ui/BarFilterHistoricalSearch'
import { HISTORICAL_SEARCH_KEYS } from '../../../../../data/dataHistoricalSearchKeys'
import MirrorChart from '../../../../../charts/MirrorChart'

const defaultData = {
  historicalVsCurrent: [],
  topClients: [],
  porCobrar: [],
  cobradas: [],
  totalPorCobrar: [],
  totalCobradas: []
}

/**
 * Renders the IngresosInsights component.
 * This component displays insights related to income for a specific business.
 */
const IngresosInsights = () => {
  const { currentBusiness } = useGlobalContext()
  const [allData, setAllData] = useState(defaultData)
  const [period, setPeriod] = useState(HISTORICAL_SEARCH_KEYS['1M'])

  /**
   * Fetches the data for the insights from the API.
   * If there is a current business selected, it makes multiple API calls to retrieve the necessary data.
   * Sets the fetched data in the state.
   */
  const getData = async () => {
    if (!currentBusiness.id) {
      console.error('No hay un negocio seleccionado')
      return
    }
  
    try {
      const businessAPI = new BusinessAPI()
      const [
        historicalVsCurrentResponse,
        topClientsResponse,
        porCobrarResponse,
        cobradasResponse,
        totalPorCobrarResponse,
        totalCobradasResponse
      ] = await Promise.all([
        businessAPI.getHistoricalVsCurrentIncome({ 
          businessId: currentBusiness.id, 
          period }),
        businessAPI.getTopFive({ 
          businessId: currentBusiness.id, 
          type: 'cliente', 
          period }),
        businessAPI.getPorCobrarCobradas({ 
          businessId: currentBusiness.id, 
          limit: 3, type: 'boleta',
          state: 'por-pagar',
          order: 'ASC' }),
        businessAPI.getPorCobrarCobradas({
           businessId: currentBusiness.id,
          limit: 3,
          type: 'boleta',
          state: 'pagada',
          order: 'DESC' }),
        businessAPI.getPorCobrarCobradas({ 
          businessId: currentBusiness.id,
          limit: 1000,
          type: 'boleta',
          state: 'por-pagar',
          order: 'ASC' }),
        businessAPI.getPorCobrarCobradas({ 
          businessId: currentBusiness.id,
          limit: 1000,
          type: 'boleta',
          state: 'pagada',
          order: 'DESC' })
      ])
  
      if (historicalVsCurrentResponse?.data) {
        setAllData({
          historicalVsCurrent: historicalVsCurrentResponse.data,
          topClients: topClientsResponse?.data || [],
          porCobrar: porCobrarResponse?.data || [],
          cobradas: cobradasResponse?.data || [],
          totalPorCobrar: totalPorCobrarResponse?.data || [],
          totalCobradas: totalCobradasResponse?.data || []
        })
      } else {
        console.warn('Respuesta de datos inválida o vacía')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }
  
  useEffect(() => {
    getData()
  }, [currentBusiness?.id, period])
  return (
    <div className='mt-16'>
      <Paper>
        <div className='relative'>
          <BarFilterHistoricalSearch
            position='top'
            short
            currentOptionKey={period}
            onSelectOptionKey={setPeriod}
          />
        </div>
        <div className='max-xl:flex-1 xl:grid xl:grid-cols-2 gap-6 max-xl:space-y-6 -mb-3 xl:-mb-11'>
          <VerticalStackedBarsChart data={allData?.historicalVsCurrent} period={period} />
          <MirrorChart data={allData?.historicalVsCurrent} period={period}/>
        </div>
      </Paper>
      <div className='max-xl:flex-1 xl:grid xl:grid-cols-2 gap-6 max-xl:space-y-6 my-6'>
        <TableTop5
          data={allData?.topClients}
          title='Top 5 clientes'
          header1='Cliente'
          header2='Total ventas'
        />
        <MirrorTable
          totalPorCobrarPagar={allData?.totalPorCobrar}
          totalCobradasPagadas={allData?.totalCobradas}
          topData={allData?.porCobrar}
          bottomData={allData?.cobradas}
          title1='Por cobrar'
          title2='Cobradas'
        />
      </div>
    </div>
  )
}

export default IngresosInsights
