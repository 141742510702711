import clsx from 'clsx'

const CustomButton = ({
  title,
  className,
  onClick,
  imageSrc,
  color,
  disabled,
  imgStyle,
  ...rest
}) => {
  const colors = {
    primary: 'bg-primary text-white',
    secondary: 'bg-secondary text-white',
    accent1: 'bg-accent1 text-white',
    accent2: 'bg-accent2 text-white',
    accent3: 'bg-accent3 text-white',
    accent4: 'bg-accent4 text-white',
    danger: 'bg-danger text-white',
    warning: 'bg-warning text-white',
    success: 'bg-success text-white',
    disabled: 'bg-[#00000030] text-white'
  }
  const buttonStyles = clsx(
    'px-4 py-2',
    className,
    colors[disabled ? 'disabled' : color] || `bg-${color}`,
    {
      'shadow hover:shadow-lg hover:bg-opacity-90': !disabled
    }
  )

  return (
    <button className={buttonStyles} onClick={onClick} disabled={disabled} {...rest}>
      <div className='flex items-center justify-center'>
        {title}
        {imageSrc && <img src={imageSrc} alt='' className={imgStyle || 'mr-2'} />}
      </div>
    </button>
  )
}

export default CustomButton
