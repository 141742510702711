import { Outlet, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../context/GlobalState'
import { useEffect } from 'react'
import { UserAuth } from '../context/AuthContext'
import { useQueryGetAllBusinesses } from '../queries/businessQueries'


const AuthGuard = () => {
  const { setBusiness, currentBusiness, setCurrentBusiness } = useGlobalContext()
  const { isAuthenticated, isLoaded, isError, user } = UserAuth()
  const { data: business = [], isFetched, refetch } = useQueryGetAllBusinesses()
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      if (!isAuthenticated) return
      try {
        if (business) {
          setBusiness(business)
          setCurrentBusiness(business.at(0)) // we have at least one business
        }
        else {
          refetch()
        }
      } catch (error) {
        return null
      }
    })()
  }, [isAuthenticated, isFetched])

  useEffect(() => {
    if (isLoaded && !isError && !user && !isAuthenticated) {
      navigate('/auth/signin')
    }
  }, [user, isLoaded, isError])


  if (isAuthenticated && currentBusiness) {
    return <Outlet />
  }
  return null
}

export default AuthGuard
