import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { styled, lighten, darken } from '@mui/system'

const AutoCompleteHeader = ({ currentBusiness, businessData, onClick }) => {
  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8)
  }))

  const options = businessData?.map(option => ({
    name: option.name,
    firstLetter: option.name?.[0]?.toUpperCase(),
    ...option
  }))

  const handleInputChange = (event, newValue) => {
    event.preventDefault()
    onClick(newValue || 'ingresa una busqueda ')
  }

  return (
    <>
      <Autocomplete
        id='grouped-demo'
        // size='small'
        options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
        groupBy={option => option.firstLetter}
        getOptionLabel={option => option.name}
        value={currentBusiness}
        onChange={handleInputChange}
        sx={{ width: 280, fontSize: 15 }}
        style={{ fontSize: 15 }}
        renderInput={params => <TextField {...params} label='Empresas' />}
        renderGroup={params => (
          <li key={params.key}>
            <GroupHeader sx={{ fontSize: 15 }}>{params.group}</GroupHeader>
            <ul className='p-0 text-sm'>{params.children}</ul>
          </li>
        )}
        defaultValue={currentBusiness}
        clearIcon={null}
      />
    </>
  )
}

export default AutoCompleteHeader
