import { useMutation } from '@tanstack/react-query'
import userApi from '../../../../../api/user.api'
import { ICreateUser } from '../../../../../models/User'


export const useMutationCreateUser = () => {
  return useMutation({
    mutationFn: async (payload: ICreateUser) => {
      const res = await userApi.createUser(payload)
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        throw new Error('Error al crear la organización')
      }
    },
    retry: 2,
    retryDelay: 1_000 * 6
  })
}
