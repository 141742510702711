import axios, { AxiosInstance } from 'axios'
import { REACT_APP_BASE_URL } from '../components/utils/constant'

/**
 *
 * @description singleton class
 */
export class BaseHTTP {

  public http: AxiosInstance;
  private static _instance: BaseHTTP;

  private constructor() {
    this.http = axios.create({
      baseURL: REACT_APP_BASE_URL,
      timeout: 1_000 * 15, // 15 seconds,
    });
  }

  public static getInstance(): BaseHTTP {
    if (!BaseHTTP._instance) {
      BaseHTTP._instance = new BaseHTTP();
    }
    return BaseHTTP._instance;
  }

  public setBackendToken(accessToken: string) {
    this.http.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }
}
