import clsx from 'clsx'
import { formatCurrency } from '../../../../../../utils/formatter'
import { Paper, Text } from '../../../../../ui'

const ChartEgresos = ({ data }) => {
  const orderedData = data?.data?.sort((a, b) => b.current - a.current)

  const colors = [
    'bg-teal-500',
    'bg-blue-900',
    'bg-green-500',
    'bg-gray-500',
    'bg-red-500',
    'bg-red-700',
    'bg-yellow-500',
    'bg-indigo-500',
    'bg-pink-500',
    'bg-purple-500'
  ]
  const textColors = [
    'text-teal-500',
    'text-blue-900',
    'text-green-500',
    'text-gray-500',
    'text-red-500',
    'text-red-700',
    'text-yellow-500',
    'text-indigo-500',
    'text-pink-500',
    'text-purple-500'
  ]

  // const total = data?.data?.reduce((acc, curr) => acc + curr.current, 0)
  const percentage = value => ((value / data.total) * 100).toFixed(2)
  const isPositive = data?.percent > 0
  return (
    <Paper className='bg-white shadow-full-xs rounded-3xl py-5'>
      <div className='mb-4'>
        <Text className='text-xl font-bold'>{formatCurrency(data?.total || 0)}</Text>
        <Text
          className={clsx('text-md', { 'text-accent2': isPositive, 'text-accent3': !isPositive })}
        >
          {isPositive ? '+' + data?.percent?.toFixed(2) : data?.percent?.toFixed(2) || 0}%
        </Text>
        <Text className='text-xs text-accent6'>Total</Text>
      </div>
      {orderedData?.length > 0 ? (
        <>
          <div className='flex h-16 w-full my-12 -ml-6 relative'>
            {orderedData?.map((item, index) => (
              <div
                key={index}
                className={`${colors[index]} pr-1`}
                style={{ width: `${percentage(item?.current)}%` }}
              />
            ))}
          </div>
          <div className='grid grid-cols-2 gap-y-2'>
            {orderedData?.map((item, index) => (
              <div
                key={index}
                className={`flex items-center odd:pr-6 ${
                  index < orderedData.length - 2 ? 'pb-2 border-b-2 border-secondary' : ''
                }`}
              >
                <div className={`min-w-2.5 min-h-2.5 rounded-full mr-4 ${colors[index]}`}></div>
                <div className={`text-sm font-bold ${textColors[index]}`}>
                  {item.name.toUpperCase()}
                </div>
                <div className='text-sm text-gray-600 ml-auto whitespace-nowrap'>
                  {formatCurrency(item.current)}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <Text className='grid place-items-center py-12 text-lg'>No hay datos</Text>
      )}
    </Paper>
  )
}

export default ChartEgresos
