// import { useOnlyAcount } from '../useBanks.hook'

//components
import { bankIcons } from '../data'

//material
import Dialog from '@mui/material/Dialog'
import ClearIcon from '@mui/icons-material/Clear'

// import ContentModalDialogAddAcount from './ContentModalDialogAddAcount'

const ModalEditAcounts = ({ acounts, open, setOpenModal }) => {
  const newAcounts = acounts.slice(1)
  const handleCloseForm = () => {
    setOpenModal(false)
  }

  return (
    <Dialog
      open={open}
      scroll='body'
      onClose={handleCloseForm}
      PaperProps={{
        sx: {
          borderRadius: '30px'
        }
      }}
    >
      <div className='p-6'>
        <div className='cursor-pointer flex justify-end items-center' onClick={handleCloseForm}>
          <ClearIcon style={{ color: '#CCCCCC' }} sx={{ fontSize: 40 }} />
        </div>
        <div className='flex flex-col items-center'>
          <div className='flex shadow-5xl border-none py-4 px-8 rounded-2xl items-center justify-start gap-5 my-10'>
            {newAcounts.map((unNewAcounts, index) => (
              <div key={index} className='flex items-center justify-center'>
                <img className='w-8 h-8' src={bankIcons[unNewAcounts.bancoCuenta.bank.name]} />
                <h3>{unNewAcounts.bancoCuenta.bank.name}</h3>
                <div>
                  <h3>{unNewAcounts.cuentaTipo.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default ModalEditAcounts
