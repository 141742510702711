import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Spinner } from './Spinner';
import { FieldWithErrorMessageProps } from './IFied';
import { ItemDropDown } from './IItemDropDown';


export interface SelectFieldWithSearchProps extends Omit<FieldWithErrorMessageProps, 'type'> {
  isLoading?: boolean,
  options?: ItemDropDown[],
  secondaryButton?: { text: string, link?: string, onClick?: () => void }
  onChange?: (value: string) => void,
  onSelect?: (args: { option: ItemDropDown, fieldName: string }) => void
}

/**
 *
 * @description custom HTML Elements for a selector and with a button in the bottom
 */
export function SelectFieldWithSearch({
  label,
  options = [],
  placeholder,
  secondaryButton,
  name,
  isLoading = false,
  onChange = () => { },
  onSelect = () => { }
}: SelectFieldWithSearchProps) {
  const { setFieldValue, touched, errors, values, getFieldProps } = useFormikContext<{ [key: string]: string }>();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const fieldProps = getFieldProps(name);

  return (
    <div className="relative">
      <label htmlFor={name} className="input-label">{label}</label>

      <div className="relative">
        <input
          autoComplete="off"
          data-type="custom-input"
          onClick={() => setToggleDropdown(prev => !prev)}
          id={name}
          value={(
            typeof fieldProps.value === 'object' ?
              fieldProps.value?.['name']
              :
              fieldProps.value
          )}
          type="text"
          name={name}
          onChange={(e) => {
            onChange(e.target.value);
            fieldProps.onChange(e);
          }}
          placeholder={placeholder}
          className={`${touched[name] && errors[name] ? 'input-error' : 'input-primary'}`}
        />

        <button type="button" className="absolute top-1/2 -translate-y-1/2 right-2 p-2">
          <FontAwesomeIcon
            className="hover:text-blue-vlg-900 transition-colors duration-200"
            size="lg"
            icon={faMagnifyingGlass}
          />
        </button>
      </div>

      {toggleDropdown && (
        <>
          <div
            onClick={() => setToggleDropdown(false)}
            className="right-0 top-0 w-screen h-screen fixed z-10 bg-transparent"
          />
          <div className="absolute top-[74px] z-10 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-full">
            <ul className="my-1 min-h-28 max-h-[200px] overflow-y-scroll custom-scrollbar">
              {isLoading ? (
                <Spinner />
              ) :
                (values[name] && !options.length) ? (
                  <p className="px-4 py-2">No se encontraton items</p>
                ) : (
                  options.map(option => (
                    <li
                      onClick={() => {
                        setFieldValue(name, option);
                        onSelect({ option, fieldName: name });
                        setToggleDropdown(false);
                      }}
                      key={option.id}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    >
                      {option.name}
                    </li>
                  ))
                )}
            </ul>

            {secondaryButton && (
              <Link
                onClick={secondaryButton?.onClick} to={secondaryButton?.link ?? ''}
                className="flex px-4 py-3 hover:bg-gray-100 gap-3 justify-center items-center text-blue-600 font-medium"
              >
                <FontAwesomeIcon size="lg" icon={faPlus} />
                <span>{secondaryButton?.text}</span>
              </Link>
            )}
          </div>
        </>
      )}

      <div className="h-4">
        <ErrorMessage name={name}>
          {(msg) => (
            <p className="text-red-vlg-500">{msg}</p>
          )}
        </ErrorMessage>
      </div>
    </div>
  );
}
