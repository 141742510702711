import { useState, useEffect, useContext, Fragment } from 'react'
import GlobalContext from '../../../../context/global-context'

//utils
import { formatDate } from '../../../utils/formatter'

import { OrganizationAPI } from '../../../../api/organization.api'
//material
import { Input, Button } from '../../ui/index'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box
} from '@mui/material'
import ModalDialogMotionEditForm from '../../modalDialogEditarMovimiento/ModalDialogMotionEditForm'

const ModalDialogComprobanteForm = ({
  openComprobante,
  setOpenComprobante,
  libroId,
  type,
  status,
  initialDescripcion,
  setSwitchGetData
}) => {
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [data, setData] = useState()
  const organizationAPI = new OrganizationAPI()
  const [descripcion, setDescripcion] = useState(initialDescripcion || '')
  const [openEditModal, setOpenEditModal] = useState(false)
  const [refreshData, setRefreshData] = useState(false)

  const handleInputChange = event => {
    setDescripcion(event.target.value)
  }

  const handleOpenEdit = () => {
    setOpenEditModal(true)
  }

  const handleCloseComprobante = () => {
    setOpenComprobante(false)
    setSwitchGetData(true)
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        ui.setLoader({ visible: true, text: 'cargado datos porfavor espera' })
        const response = await organizationAPI.getComprobante({
          businessId: currentBusiness.id,
          comprobanteId: libroId,
          type: type
        })
        setData(response.data)
      } catch (error) {
        console.error(error)
      } finally {
        ui.setLoader({ visible: false })
        setRefreshData(false)
      }
    }
    fetchData()
  }, [refreshData])

  useEffect(() => {
    if (data?.descripcion) {
      setDescripcion(data.descripcion)
    }
  }, [data?.descripcion])

  const totalDebe = data?.data?.reduce((sum, item) => sum + item.debe, 0) || 0
  const totalHaber = data?.data?.reduce((sum, item) => sum + item.haber, 0) || 0

  return (
    <div>
      <Fragment>
        <Dialog open={openComprobante} onClose={handleCloseComprobante} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title' className='text-sm font-semibold'>
            Comprobante
          </DialogTitle>
          <DialogContent>
            <div className='text-sm grid grid-rows-3 grid-flow-col gap-4'>
              <Input
                label='Comprobante'
                disabled={true}
                value={data?.comprobanteNumero}
                name='comprobante'
                className='w-full'
              />
              <Input
                label='Empresa'
                disabled={true}
                name='empresa'
                value={data?.razonSocial}
                fullWidth
                className='w-full'
              />
              <Input 
                label='Rut' 
                value={data?.rut} 
                disabled={true} 
                name='rut' 
                className='w-full' />
              <Input
                label='Fecha'
                value={formatDate(data?.fecha)}
                disabled={true}
                name='fecha'
                className='w-full'
              />
              <Input
                label='Tipo'
                value={data?.tipoComprobante}
                disabled={true}
                name='tipo'
                className='w-full'
              />
              <Input
                label='Descripción'
                value={descripcion}
                disabled={false}
                name='descripcion'
                onChange={handleInputChange}
                className='w-full'
              />
            </div>
            <TableContainer component={Paper} className='mt-4'>
              {data?.data?.length ? (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cuenta</TableCell>
                      <TableCell>Glosa</TableCell>
                      <TableCell>Debe</TableCell>
                      <TableCell>Haber</TableCell>
                      <TableCell>Usuario</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.data.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell className='text-xs'>
                          <Box display='flex' flexDirection='column'>
                            <span>{item.nombreCuenta}</span>
                            <span>{item.codigoCuenta}</span>
                          </Box>
                        </TableCell>
                        <TableCell>{item.glosa}</TableCell>
                        <TableCell>{item.debe}</TableCell>
                        <TableCell>{item.haber}</TableCell>
                        <TableCell>{'@' + (item.usuarioClasificador || 'Luca')}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2}>Total</TableCell>
                      <TableCell>{totalDebe}</TableCell>
                      <TableCell>{totalHaber}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                <Typography variant='h6' align='center' className='py-5'>
                  No hay datos
                </Typography>
              )}
            </TableContainer>
          </DialogContent>
          <DialogActions>
          <Button
              className='bg-aqua-green text-white font-bold px-6'
              onClick={handleOpenEdit}
              title='Editar'
            >
              Editar
            </Button>
            <Button
              className='bg-red-600 text-white font-bold px-6'
              onClick={handleCloseComprobante}
              title='Cerrar'
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
      {openEditModal && (
        <ModalDialogMotionEditForm
          title='Editar movimiento'
          open={openEditModal}
          setOpen={setOpenEditModal}
          idLibro={libroId}
          status={status}
          type={type}
          setSwitchGetData={setRefreshData}
        />
      )}
    </div>
  )
}

export default ModalDialogComprobanteForm
