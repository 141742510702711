// Filas principales y sub-filas
export const dataTableDefault = [
  {
    name: 'Ingresos',
    subRows: [
      {
        name: 'Resultado Operacional',
        subRows: [
          { name: 'Ingresos de Explotación', subRows: [{ name: 'Ingresos del Giro' }] },
          {
            name: 'Ingresos Financieros',
            subRows: [{ name: 'Intereses Cobrados a Clientes' }]
          }
        ]
      }
    ]
  },
  {
    name: 'Costos',
    subRows: [
      {
        name: 'Costo de Explotación',
        subRows: [{ name: 'Costo de venta' }]
      }
    ]
  },
  {
    name: 'Gastos',
    subRows: [
      {
        name: 'Gastos de Remuneraciones',
        subRows: [
          { name: 'Sueldo base' },
          { name: 'Comisiones por Ventar' },
          { name: 'Bonos imponibles' },
          { name: 'Horas Exraordinarias' },
          { name: 'Gratificación' },
          { name: 'Aguinaldos' },
          { name: 'Asignación de Movilización' },
          { name: 'Asignación de Colación' },
          { name: 'Otro Conceptos No imponibles' }
        ]
      },
      {
        name: 'Gastos de Administración',
        subRows: [
          { name: 'Gastos de Uniformes y Equipos' },
          { name: 'Gastos de Capacitación' },
          { name: 'Asesorpias Profesionales' },
          { name: 'Boletas de Honorarios' },
          { name: 'Gastos Legales y Notariales' },
          { name: 'Consumos Basios(luz, agua y gas)' },
          { name: 'Gastos de Mantención y Remodelación' },
          { name: 'Depreciación del Ejercicio' },
          { name: 'Mantención de Equipos y Maquinarias' },
          { name: 'Gastos Oficina' },
          { name: 'Gastos de Suscripciones' },
          { name: 'Gastos de Publicidad y Marketing' },
          { name: 'Finiquitos' },
          { name: 'Grastos de Software' },
          { name: 'Seguros' },
          { name: 'Arriendos' },
          { name: 'Gastos Comunes' },
          { name: 'Gastos de Logística y Fletes' },
          { name: 'Amortizaciones varias del Ejercicios' },
          { name: 'Gastos de Telefonía e Internet' },
          { name: 'Beneficios al Personal' },
          { name: 'Gastos de Vehiculos(Combustibles - TAG - Mantención)' },
          { name: 'Gastos de Viajes(Pasajes - Hotel - Arriendo Auto)' },
          { name: 'Corrección Monetaria' },
          { name: 'Gatos de Representación(Restaurantes, Cafetería y Similares)' },
          { name: 'Gastos Patente Municipal' },
          { name: 'Gastos de Leasing' },
          { name: 'Otros Impuestos' },
          { name: 'Gastos de Mercaderías Obsoletas' },
          { name: 'Gastos Deudores Incobrables' },
          { name: 'Otros Gastos de Administración' },
          { name: 'Gastos Generales' },
          { name: 'I.V.A No Recuperable' }
        ]
      },
      {
        name: 'Gastos Financieros',
        subRows: [
          { name: 'Gastos Bancarios' },
          { name: 'Intereses Bancarios' },
          { name: 'Comisiones Medios de Pago' },
          { name: 'Comisiones MarketPlace' },
          { name: 'Intereses pagados' }
        ]
      }
    ]
  }
]
