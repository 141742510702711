import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatCurrency, formatDate } from '../../../utils/formatter'

const cols = [
  { header: 'Fecha', width: 80 },
  { header: 'Tipo de Comprobante', width: 120 },
  { header: 'Numero Comprobante', width: 80 },
  { header: 'Rut', width: 80 },
  { header: 'Razon Social', width: 120 },
  { header: 'Numero Documento', width: 80 },
  { header: 'Debe', width: 80 },
  { header: 'Haber', width: 80 },
  { header: 'Saldo', width: 80 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginVertical: '16px',
    marginHorizontal: 'auto',
    fontSize: 12
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center'
  },
  col: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 4
  },
  boldText: {
    fontWeight: '700'
  },
  text: {
    fontSize: 10,
    color: '#5a5a5a'
  }
})

const ExportPDFReportesLibroMayor = ({ data, title, dateFrom, dateTo }) => {
  const MyDocument = () => {
    const rowsPerPage = 10 // Ajusta este valor según la cantidad de filas que caben en una página

    const pageCount = Math.ceil(data.length / rowsPerPage)

    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.section}>
              {pageIndex === 0 && <Text>Resumen de movimientos</Text>}
              <View style={styles.table}>
                <View style={{ ...styles.row, backgroundColor: '#FFFFE0' }}>
                  {cols?.map((col, i) => (
                    <View style={{ ...styles.col, width: col.width, fontWeight: 'bold' }} key={i}>
                      <Text style={styles.boldText}>{col.header}</Text>
                    </View>
                  ))}
                </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatDate(row.fecha)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.boldText}>
                          {row.tipoComprobante.nombreTipoComprobante}
                        </Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{row.id}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{row.informacion.rutEmpresa}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 120 }}>
                        <Text style={styles.boldText}>{row.informacion.razonSocial}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{row.numero}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.text}>{formatCurrency(row.debe)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatCurrency(row.haber)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 80 }}>
                        <Text style={styles.boldText}>{formatCurrency(row.saldo)}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  }

  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`${title}_${dateFrom.month}_${dateFrom.year}_${dateTo.month}_${dateTo.year}.pdf`}
    >
      PDF
    </PDFDownloadLink>
  )
}

export default ExportPDFReportesLibroMayor
