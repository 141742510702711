import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../../../context/AuthContext";
import { StepForm2 } from "./Step2Form";
import { StepForm3 } from "./Step3Form";
import { StepForm4 } from "./Step4Form";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContactInfo, SecurityTSL } from "../../components/SignUpLayout";
import { useLucaAuth } from "../hooks/useAuth";


// step forms accountProfile sign up
const stepForms = [
  <StepForm2 key={1} />,
  <StepForm3 key={2} />,
  <StepForm4 key={3} />,
]

export const CompleteSignUp: FC = () => {
  const navigate = useNavigate()
  const { apiToken } = useLucaAuth(state => state.accountProfile)
  const { user, isAuthenticated, isLoaded } = UserAuth()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!user && !apiToken && isLoaded) {
      navigate('/auth/signup', { replace: true })
    }
  }, [user, isAuthenticated, isLoaded])

  return (
    <>
      <div
        className='relative px-7 py-9 w-[570px] h-min[460px] bg-white rounded-lg shadow-md'
      >
        <Swiper
          onSlideChange={(sw) => setActiveIndex(sw?.activeIndex)}
          spaceBetween={30}
          allowTouchMove={false}
        >
          {stepForms.map((step, index) => (
            <SwiperSlide className='mb-1' key={index}>
              {step}
            </SwiperSlide>
          ))}
        </Swiper>

      </div>

      {activeIndex === (stepForms.length - 1) ? (
        <SecurityTSL />
      ) : (
        <ContactInfo />
      )}
    </>
  )
}
