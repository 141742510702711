import iconConciliacionBancaria from '../../assets/iconosCardInformes/informe_4.png'
import iconEstadoResultado from '../../assets/iconosCardInformes/informe_2.png'
import iconFlujoCaja from '../../assets/iconosCardInformes/informe_11.png'
import iconCobranza from '../../assets/iconosCardInformes/informe_5.png'
import iconBalanceGeneral from '../../assets/iconosCardInformes/informe_5.png'
import iconLibroDiario from '../../assets/iconosCardInformes/informe_3.png'

export const arrayIcons = [
    {
      icon: iconFlujoCaja,
      title: 'Flujo de caja',
      id: '1'
    },
    {
      icon: iconEstadoResultado,
      title: 'Estado de resultados',
      id: '2'
    },
    {
      icon: iconConciliacionBancaria,
      title: 'Conciliación Bancaria',
      id: '3'
    },
    {
      icon: iconCobranza,
      title: 'Cobranza',
      id: '4'
    },
    {
      icon: iconBalanceGeneral,
      title: 'Balance general',
      id: '5'
    },
    {
      icon: iconLibroDiario,
      title: 'Libro Diario',
      id: '6'
    },
    {
      icon: iconLibroDiario,
      title: 'Libro Mayor',
      id: '7'
    },
    {
      icon: iconLibroDiario,
      title: 'Libro Auxiliar de cliente',
      id: '8'
    },
    {
      icon: iconLibroDiario,
      title: 'Libro Auxiliar de proveedores',
      id: '9'
    }
  ]
  