import clsx from 'clsx'
import { useState } from 'react'

//utils
import { formatCurrency, formatDate } from '../../../components/utils/formatter'

//mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

//components
import { colsLibroMayor as cols } from '../../../components/modules/data/headersTables'
import CustomPagination from '../../../components/modules/table/CustomPagination'
import { Text } from '../../../components/modules/ui'

const Table = ({ rows, rowsPerPage, setRowsPerPage }) => {
  const [page, setPage] = useState(0)

  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  // Función para manejar la ordenacion
  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  // Función de ordenacion
  const sortRows = () => {
    return rows.sort((a, b) => {
      if (order.direction === 'asc') {
        return a[order.field] < b[order.field] ? -1 : 1
      } else {
        return a[order.field] > b[order.field] ? -1 : 1
      }
    })
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500'
  }

  const orderableCols = [
    { col: 'Fecha', field: 'fecha' },
    // { col: 'Tipo', field: 'nombreTipoComprobante' },
    // { col: 'Comprobante', field: 'id' },
    // { col: 'Cuenta', field: 'nombreCuenta' },
    { col: 'Glosa', field: 'glosa' },
    { col: 'Debe', field: 'debe' },
    { col: 'Haber', field: 'haber' }
  ]

  const searchColsIndex = col => orderableCols.find(item => item.col === col)

  if (!rows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>
  else
    return (
      <div className=''>
        <div className='overflow-x-auto'>
          <table className={[styles.table]}>
            <thead>
              <tr>
                {cols?.map((col, i) => (
                  <th
                    className={clsx(styles.th, { 'cursor-pointer': searchColsIndex(col) })}
                    onClick={() => searchColsIndex(col) && handleSort(searchColsIndex(col))}
                    key={i}
                  >
                    {col}
                    {searchColsIndex(col) && (
                      <KeyboardArrowDownIcon
                        className={clsx('transition-all ml-2 cursor-pointer', {
                          'opacity-40': order.col !== col,
                          'rotate-180':
                            searchColsIndex(col).col === order.col && order.direction === 'desc'
                        })}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? sortRows()?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : sortRows()
              )?.map((row, i) => (
                <tr className={styles.tr} key={i}>
                  {/* Fecha */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                  </td>
                  {/* Tipo */}
                  <td className={clsx(styles.td)}>
                    <Text className={styles.boldText}>
                      {row.tipoComprobante.nombreTipoComprobante}
                    </Text>
                  </td>
                  {/* Comprobante numero */}
                  <td className={styles.td}>
                    <Text className={styles.text}>{row.idOrigin}</Text>
                  </td>
                  {/* rut  */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.informacion?.rutEmpresa}</Text>
                  </td>
                  {/*razon social*/}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.informacion?.razonSocial}</Text>
                  </td>
                  {/* Numero doc */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {row.numero}
                    </Text>
                  </td>
                  {/* Debe */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.debe)}
                    </Text>
                  </td>
                  {/* Haber */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.haber)}
                    </Text>
                  </td>
                  {/* Saldo */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.saldo)}
                    </Text>
                  </td>
                  {/* Glosa*/}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.glosa}</Text>
                  </td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr className={styles.tr} style={{ height: 89 * emptyRows }}>
                  <td className={styles.td} colSpan={cols?.length} aria-hidden />
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    )
}

export default Table
