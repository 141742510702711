import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'
import { formatCurrency, formatRut } from '../../../utils/formatter'
import { CircularProgress } from '@mui/material'

const cols = [
  { header: 'Razón Social', width: 170 },
  { header: 'rut', width: 170 },
  { header: 'Monto Total', width: 170 },
  { header: 'Mora', width: 170 }
]

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#bfbfbf',
    marginVertical: '16px',
    marginHorizontal: 'auto',
    fontSize: 12
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    alignItems: 'center'
  },
  col: {
    height: '100%',
    borderRightWidth: 1,
    borderRightColor: '#000',
    padding: 4
  },
  boldText: {
    fontWeight: '700'
  },
  text: {
    fontSize: 10,
    color: '#5a5a5a'
  }
})

const ExportPDFMirrorTable = ({ data, title }) => {
  const MyDocument = () => {
    const rowsPerPage = 10 // Ajusta este valor según la cantidad de filas que caben en una página

    const pageCount = Math.ceil(data.length / rowsPerPage)
    // const pages = Array.from({ length: pageCount }, (_, i) =>
    //   data.slice(i * rowsPerPage, i * rowsPerPage + rowsPerPage)
    // )
    return (
      <Document>
        {[...Array(pageCount)].map((_, pageIndex) => (
          <Page key={pageIndex} orientation='landscape' size='A4' style={styles.page}>
            <View style={styles.section}>
              {pageIndex === 0 && <Text>Resumen de movimientos</Text>}
              <View style={styles.table}>
                <View style={{ ...styles.row, backgroundColor: '#FFFFE0' }}>
                  {cols?.map((col, i) => (
                    <View style={{ ...styles.col, width: col.width, fontWeight: 'bold' }} key={i}>
                      <Text style={styles.boldText}>{col.header}</Text>
                    </View>
                  ))}
                </View>
                {data
                  .slice(pageIndex * rowsPerPage, (pageIndex + 1) * rowsPerPage)
                  .map((row, i) => (
                    <View key={i} style={styles.row}>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.boldText}>{row.razonSocial}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.text}>{row.rut && formatRut(row.rut)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.boldText}>{formatCurrency(row.total)}</Text>
                      </View>
                      <View style={{ ...styles.col, width: 170 }}>
                        <Text style={styles.text}>{row.date}</Text>
                      </View>
                    </View>
                  ))}
              </View>
            </View>
          </Page>
        ))}
      </Document>
    )
  }

  // const onClick = () => {
  //   setShowModal({
  //     show: true,
  //     title: 'Exportar a PDF',
  //     body: () => (
  //       <PDFDownloadLink orientation='landscape' document={<MyDocument />} fileName='data.pdf'>
  //         {({ loading }) =>
  //           loading ? <CircularProgress color='primary' thickness={5} size={60} /> : 'Descargar PDF'
  //         }
  //       </PDFDownloadLink>
  //     )
  //   })
  // }
  if (!data) return null
  return (
    <PDFDownloadLink
      className='flex items-center justify-center w-10 h-10 text-red-700 font-bold tracking-tighter cursor-pointer rounded-full shadow-full-sm bg-white'
      orientation='landscape'
      document={<MyDocument />}
      fileName={`pdf_${title}.pdf`}
    >
      {({ loading }) =>
        loading ? <CircularProgress color='primary' thickness={3} size={20} /> : 'PDF'
      }
    </PDFDownloadLink>
  )
  //   <>
  //     <button
  //       onClick={onClick}
  //       className='w-10 h-10 text-blue-700 font-bold cursor-pointer rounded-full shadow-full-xs bg-white'
  //     >
  //       PDF
  //     </button>
  //   </>
  // )
}

export default ExportPDFMirrorTable
