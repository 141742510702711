import clsx from 'clsx'
import { formatCurrency } from '../../../../../../../utils/formatter'
import { Paper, Text } from '../../../../../../ui'
import * as Bar from './VerticalBar'

// Objeto que define el orden de los meses
const monthOrder = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12
}

const VerticalStackedBarsChart = ({ data: props, period }) => {
  const data = props?.data || []
  const currentMonth = new Date().getMonth() + 1
  
  const filterDataByPeriod = () => {
    switch (period) {
      case '1M':
        return data.filter(item => monthOrder[item.name] === currentMonth)
      case '1Q':
        return data.filter(item => monthOrder[item.name] >= 1 && monthOrder[item.name] <= 3)
      case '2Q':
        return data.filter(item => monthOrder[item.name] >= 4 && monthOrder[item.name] <= 6)
      case '3Q':
        return data.filter(item => monthOrder[item.name] >= 7 && monthOrder[item.name] <= 9)
      case '4Q':
        return data.filter(item => monthOrder[item.name] >= 10 && monthOrder[item.name] <= 12)
      case '1A':
        return data
      default:
        return []
    }
  }

  const filteredData = filterDataByPeriod()

  if (filteredData.length === 0) {
    return <div className='grid place-items-center py-12'>No hay datos disponibles para el periodo seleccionado</div>
  }

  const lastIndex = filteredData.length - 1
  const current = filteredData[lastIndex]?.current || 0
  const historical = filteredData[lastIndex]?.historical || 0

  const percentage = () => {
    // Verifica si hay datos suficientes para calcular el histórico
    const valorHistorical = historical || filteredData?.[lastIndex - 1]?.current || 0
    if (valorHistorical === 0) return '0%' // Evita dividir por cero

    const valor = ((current - valorHistorical) / valorHistorical) * 100
    if (valor === Infinity) return '+100%'
    if (valor > 0) return `+${valor.toFixed(2)}%`
    else return `${valor.toFixed(2) || 0}%`
  }

  const compareMonths = (a, b) => monthOrder[a.name] - monthOrder[b.name]
  filteredData.sort(compareMonths)

  const maxValue = Math.max(...filteredData.reduce((acc, item) => [...acc, item.current, item.historical], []))

  return (
    <Paper className='bg-white shadow-full-xs rounded-3xl'>
      <div className='flex justify-between max-w-[500px] mx-auto mb-4'>
        <div className=''>
          {filteredData?.[0] && (
            <>
              <Text className='text-lg font-bold'>{formatCurrency(current)}</Text>
              <Text
                className={clsx('text-lg', {
                  'text-accent2': percentage().slice(0, 1) === '+',
                  'text-accent3': percentage().slice(0, 1) === '-'
                })}
              >
                {percentage()}
                {historical === 0 && lastIndex > 0 && filteredData?.[lastIndex - 1]?.current && (
                  <i className='text-base text-accent6/50'> (mes anterior)</i>
                )}
              </Text>
              <Text className='text-xs'>Total</Text>
            </>
          )}
        </div>
        <div className='flex gap-x-2'>
          <Text className='text-lg font-bold text-primary'>Histórico</Text>
          <Text className='text-lg text-accent3 pt-1'>vs</Text>
          <Text className='text-lg font-bold text-accent2'>Actual</Text>
        </div>
      </div>
      <div className='overflow-x-auto'>
        <div className='flex justify-center items-end' style={{ minWidth: `${filteredData.length * 100}px` }}>
          <div className='flex items-end gap-8'>
            {filteredData.map((item, index) => {
              const height1 = (item.historical / maxValue) * 200
              const height2 = (item.current / maxValue) * 200

              return (
                <div key={index} className='flex flex-col items-center flex-shrink-0'>
                  <div className='flex items-end justify-end'>
                    <Bar.VerticalBar 
                      value={item.historical} 
                      width={40} 
                      height={height1} 
                      barColor={'primary'} 
                    />
                    <Bar.VerticalBar 
                      value={item.current} 
                      width={40} 
                      height={height2} 
                      barColor={'accent2'} 
                      label={true} 
                      stacked 
                      textColor={'primary'} 
                    />
                  </div>

                  <Text className='mt-2'>{item.name.slice(0, 3)}</Text>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default VerticalStackedBarsChart
