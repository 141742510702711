import clsx from 'clsx'
import { useState, useEffect } from 'react'

//utils
import { formatCurrency, formatDate } from '../../../../../../utils/formatter'

//mui
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneAllIcon from '@mui/icons-material/DoneAll'

//data
import { colsMovimientos as cols } from '../../../../../data/headersTables'
import { bankIcons } from '../data'

//components
import { Text } from '../../../../../ui'
import CustomPagination from '../../../../../table/CustomPagination'
import ModalDialogMotionEditForm from '../../../../../modalDialogEditarMovBancario/ModalDialogMotionEditForm'

//assets
import imgDefaultBank from '../../../../../../../assets/iconos/iconCasa.png'

const TableMovements = ({ rows, getData, rowsPerPage, setRowsPerPage }) => {
  const [page, setPage] = useState(0)

  // variables de estado para modal de edicion
  const [idLibro, setIdLibro] = useState()
  const [open, setOpen] = useState(false)

  // variable para llamar a getdata desde el modal
  const [switchGetData, setSwitchGetData] = useState(false)

  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  // Función para manejar la ordenacion
  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  // Función de ordenacion
  const sortRows = () => {
    return rows.sort((a, b) => {
      if (order.direction === 'asc') {
        return a[order.field] < b[order.field] ? -1 : 1
      } else {
        return a[order.field] > b[order.field] ? -1 : 1
      }
    })
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-fixed',
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500'
  }

  const orderableCols = [
    { col: 'Fecha', field: 'fecha' },
    { col: 'Cuenta Corriente', field: 'nombreCuentaBanco' },
    { col: 'Abono', field: 'montoAbono' },
    { col: 'Cargo', field: 'montoCargo' },
    { col: 'Status', field: 'status' },
    { col: 'Acciones', field: 'aprobado' }
  ]

  // funcion para abrir formulario de edicion
  const handleClickOpen = idLibro => {
    // console.log(idLibro)
    setIdLibro(idLibro)
    // setStatus(status)
    setOpen(true)
  }

  useEffect(() => {
    getData()
    setSwitchGetData(false)
  }, [switchGetData === true])

  const searchColsIndex = col => orderableCols.find(item => item.col === col)


  if (!rows?.[0]) return <Text className='grid place-items-center py-12'>No hay datos</Text>
  else
    return (
      <div className=''>
        <div className='overflow-x-auto'>
          <table className={[styles.table]}>
            <thead>
              <tr>
                {cols?.map((col, i) => (
                  <th
                    className={clsx(styles.th, { 'cursor-pointer': searchColsIndex(col) })}
                    onClick={() => searchColsIndex(col) && handleSort(searchColsIndex(col))}
                    key={i}
                  >
                    {col}
                    {searchColsIndex(col) && (
                      <KeyboardArrowDownIcon
                        className={clsx('transition-all ml-2 cursor-pointer', {
                          'opacity-40': order.col !== col,
                          'rotate-180':
                            searchColsIndex(col).col === order.col && order.direction === 'desc'
                        })}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? sortRows()?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : sortRows()
              )?.map((row, i) => (
                <tr className={styles.tr} key={i}>
                  {/* Nombre origen */}
                  <td className={styles.td}>
                    <img
                      src={bankIcons[row.nombreCuentaBanco] || imgDefaultBank}
                      className='min-w-8 h-8 mt-2'
                    />
                  </td>
                  {/* Fecha */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                  </td>
                  {/* Cuenta Corriente */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.nombreCuentaBanco}</Text>
                    <Text className={styles.text}>{row.numeroCuentaBanco}</Text>
                  </td>
                  {/* descripcion */}
                  <td className={clsx(styles.td)}>
                    <Text className={styles.boldText}>{row.descripcion || '<descripcion>'}</Text>
                  </td>
                  {/* Abono */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.montoAbono)}
                    </Text>
                  </td>
                  {/* Cargo */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.montoCargo)}
                    </Text>
                  </td>
                  {/* Status */}
                  <td className={styles.td}>
                    <Text
                      className={clsx(styles.boldText, 'tracking-wider', {
                        'text-accent3': row.status === 'PENDIENTE',
                        'text-accent2': row.status === 'CONCILIADO'
                      })}
                    >
                      {row.status}
                    </Text>
                  </td>
                  {/* Acciones */}
                  <td>
                    <div className='flex h-full items-center justify-center space-x-3'>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center'>
                        <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                          <div
                            onClick={() => handleClickOpen(row.id)}
                            className='bg-[#fff] flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                          >
                            <EditOutlinedIcon sx={{ color: 'inherit' }} />
                          </div>
                          <Text className='mt-2'>Editar</Text>
                        </div>
                      </div>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center'>
                        <button
                          className={`${
                            row.aprobado !== 0
                              ? 'bg-accent2 text-white hover:bg-white hover:text-slate-400'
                              : 'bg-[#fff] text-slate-400 hover:bg-slate-100/50 '
                          } flex  shadow-full-xs rounded-full items-center justify-center w-9 h-9 transition-colors duration-150`}
                        >
                          {row.aprobado !== 0 ? <DoneAllIcon /> : <CheckOutlinedIcon />}
                        </button>
                        {row.aprobado !== 0 ? (
                          <Text className='mt-2'>Aprobado</Text>
                        ) : (
                          <Text className='mt-2'>Aprobar</Text>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr className={styles.tr} style={{ height: 89 * emptyRows }}>
                  <td className={styles.td} colSpan={cols?.length} aria-hidden />
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {open && (
          <ModalDialogMotionEditForm
            open={open}
            setOpen={setOpen}
            idLibro={idLibro}
            // status={status}
            type={'transaccion'}
            setSwitchGetData={setSwitchGetData}
          />
        )}
      </div>
    )
}

export default TableMovements
