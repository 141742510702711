import { useState, forwardRef, Fragment, useEffect, useContext } from 'react'
import GlobalContext from '../../../context/global-context'

//assets
import logoMiniSII from '../../../assets/logoMiniSII.png'
import iconCalendario from '../../../assets/iconos/iconCalendario.png'

//api
import { OrganizationAPI } from '../../../api/organization.api'

// components
import { CustomSelect, Button, Text, InputCheckBox } from '../ui/index'
import Modal from './ModalDialogAddProps'
import { formatDate, formatCurrency } from '../../utils/formatter'

// material
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'

//charts
import CircleCharts from '../charts/CircleChart'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ModalDialogMotionEditForm = ({
  open,
  setOpen,
  idLibro,
  type,
  status,
  setSwitchGetData,
  title
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [data, setData] = useState({})
  const [dataCuentas, setDataCuentas] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const organizationAPI = new OrganizationAPI()
  const [centrosDeCosto, setCentrosDeCosto] = useState([])
  const [switchDate, setSwitchDate] = useState(false)
  const [comentario, setComentario] = useState(data.glosa || '')
  const [errorComentario, setErrorComentario] = useState('')
  const [val, setVal] = useState([{ value: 100 }])
  const [selectedCuentaEgresos, setSelectedCuentaEgresos] = useState(null)
  const [selectedExistencias, setSelectedExistencias] = useState(null)
  const [selectedActivosFijos, setSelectedActivosFijos] = useState(null)
  const [selectedActivosIntangibles, setSelectedActivosIntangibles] = useState(null)
  const [selectedCuentaVenta, setSelectedCuentaVenta] = useState(null)

  const handleSliderChange = (index, newValue) => {
    const newVal = [...val]
    newVal[index].value = newValue
    if (index === 0 && val.length > 1) {
      newVal[1].value = 100 - newValue
    }
    setVal(newVal)
  }

  function formatValueLabel(value) {
    return `${value}%`
  }

  const handleClose = () => {
    setOpen(false)
  }
  const functionGetDataLibroContable = async () => {
    try {
      ui.setLoader({ visible: true, text: 'cargando datos' })
      const response = await organizationAPI.getDataLibroContable(currentBusiness.id, idLibro, type)
      const responseCuentas = await organizationAPI.getAllCuentas()
      console.info(response)
      setSwitchDate(false)
      setData(response.data)
      setDataCuentas(responseCuentas.data)
    } catch (error) {
      console.error(error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  const handleSubmit = async () => {
    try {
      ui.setLoader({ visible: true, text: 'editando datos' })

      const cuentaContableId = type === 'venta'
        ? selectedCuentaVenta
        : selectedCuentaEgresos || selectedExistencias || selectedActivosFijos || selectedActivosIntangibles

      const updateData = {
        cuentaContableId: cuentaContableId,
        glosa: comentario,
        vencimiento: data.fechaVencimiento,
        validation: data.validation || 1
      }
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness?.id,
        idLibro,
        updateData
      )
      console.info(response)
      setSwitchGetData(true)
      functionGetDataLibroContable()
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  useEffect(() => {
    functionGetDataLibroContable()
  }, [])

  const handleOptionSelect = e => {
    const { name, value } = e.target
    // if (name === 'idCuenta')
    // setData({ ...data, [name]: +value })
    // else {
    //   setData({ ...data, [name]: value })
    // }
    // if (name === 'idCuenta') return setData({ ...data, [name]: value })
    // if (name === 'validation') return setData({ ...data, [name]: value })
    if (value === '0') return setModalOpen(true)
    if (name === 'fechaVencimiento') {
      setSwitchDate(true)
      setData({ ...data, [name]: value })
    } else {
      setData({ ...data, [name]: value })
    }

    switch (name) {
      case 'idCuentaEgresos':
        setSelectedCuentaEgresos(value);
        break;
      case 'idExistencias':
        setSelectedExistencias(value);
        break;
      case 'idActivosFijos':
        setSelectedActivosFijos(value);
        break;
      case 'idActivosIntangibles':
        setSelectedActivosIntangibles(value);
        break;
      case 'idCuentaVenta':
        setSelectedCuentaVenta(value);
        break;
      default:
        break;
    }
  }

  const handleNoConsiderar = e => {
    const { name, value } = e.target
    if (data.validation === -5) {
      setData({ ...data, [name]: 1 })
    } else {
      setData({ ...data, [name]: parseInt(value) })
    }
  }

  const options2 = [{ label: '   ', value: '    ' }]

  const cuentasIngresos = [184, 186, 189, 190]
  const cuentasEgresos = [192, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217,
  218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 239, 240, 241, 242]
  const existencias = [68, 69, 70]
  const activosFijos = [79, 82, 83, 86, 87, 88, 89, 90]
  const activosIntangibles = [104, 105]

  const dataClasificacion =
    type === 'venta'
      ? dataCuentas.filter(item => cuentasIngresos.includes(item.id))
      : dataCuentas.filter(item => cuentasEgresos.includes(item.id))

  const dataExistencias = dataCuentas.filter(item => existencias.includes(item.id))
  const dataActivosFijos = dataCuentas.filter(item => activosFijos.includes(item.id))
  const dataActivosIntangibles = dataCuentas.filter(item => activosIntangibles.includes(item.id))

  const handleAgregarCentroCosto = () => {
    if (centrosDeCosto.length < 1) {
      setCentrosDeCosto([...centrosDeCosto, {}])
      setVal([...val, { value: 0 }])
    }
  }

  const formatDateToShow = dateString => {
    if (!dateString) return ''
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    let day = switchDate ? date.getDate() + 1 : date.getDate()
    day = day.toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleComentario = e => {
    const valorNuevo = e.target.value
    if (valorNuevo.length <= 40) {
      setComentario(valorNuevo)
      setErrorComentario('')
    } else {
      setErrorComentario('El comentario no puede exceder los 40 caracteres.')
    }
  }

  useEffect(() => {
    setComentario(data.glosa || '')
  }, [data.glosa])

  return (
    <div>
      <Fragment>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth='lg'
          scroll='body'
          PaperProps={{
            // Utilizamos PaperProps para aplicar estilos al contenido del diálogo
            sx: {
              backgroundColor: '#f2f2f2' // Aquí aplicamos el borderRadius al contenido
            }
          }}
        >
          <DialogContent>
            <div className='bg-[#f2f2f2] flex justify-end items-center'>
              <button type='button' onClick={handleClose}>
                <ClearIcon sx={{ fontSize: 40 }} />
              </button>
            </div>
            <div className='px-10'>
              <h1 className='text-lg font-semibold mb-7'>{title}</h1>
              <section className='flex flex-col bg-white shadow-lg px-4 py-2 rounded-lg gap-5'>
                <section className='flex justify-between items-center gap-60'>
                  <div className='flex items-start gap-10'>
                    <div>
                      <div className='flex justify-center items-center'>
                        <img className='w-8 h-8' src={logoMiniSII} alt='' />
                        <p className='text-m font-bold uppercase'>
                          {type === 'honorario' ? data.nombreCuenta : data.nombreCodigoDocumento}
                          {type === 'honorario' ? data.codigoCuenta : `(${data.codigoDocumento})`}
                        </p>
                      </div>
                      {type !== 'honorario' && (
                        <p className='text-sm font-bold uppercase pl-8'>folio {data.folio}</p>
                      )}
                    </div>
                    <div className='flex justify-center items-center gap-2'>
                      <img className='w-8 h-8' src={iconCalendario} alt='' />
                      <p className='text-sm font-bold uppercase text-center'>
                        {formatDate(data.fecha)}
                      </p>
                    </div>
                  </div>
                  <div className='flex flex-col items-end pt-2'>
                    <p className='text-lg font-bold uppercase'>{formatCurrency(data.montoTotal)}</p>
                    <Text className='text-sm  uppercase text-accent3'>
                      {type === 'honorario' ? 'retenido:' : 'iva:'}
                      {formatCurrency(data.montoIva)}
                    </Text>
                    <Text className='text-sm'>
                      {type !== 'honorario' && `Neto:${formatCurrency(data.montoNeto)}`}
                    </Text>
                  </div>
                </section>
                <section className='flex justify-between items-center gap-96'>
                  <div>
                    <p className='text-sm font-bold uppercase'>{data.razonSocial}</p>
                    <p className='text-sm font-bold uppercase'>{data.rut}</p>
                  </div>
                  <Text
                    className={
                      status === 'POR PAGAR' ? 'text-accent3 font-bold' : 'text-accent2 font-bold'
                    }
                  >
                    {status}
                  </Text>
                </section>
              </section>
              <section className='flex px-10 py-5 justify-between gap-6 mt-7'>
                <div className='flex justify-stretch'>
                  <div>
                    <label
                      htmlFor='idCuenta'
                      className='block text-gray-700 text-sm font-bold mb-2 ml-5'
                    >
                      Clasificacion
                    </label>
                    {type === 'venta' ? (
                    <select
                      className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-lg'
                      name='idCuentaVenta'
                      onChange={handleOptionSelect}
                      disabled={data.validation === -5}
                      value={selectedCuentaVenta || ''}
                    >
                      {dataClasificacion.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.nombreCuenta}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <>
                      <label className='block text-gray-700 text-xs font-bold mb-2 ml-5'>Gastos</label>
                      <select
                        className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-lg mb-2'
                        name='idCuentaEgresos'
                        onChange={handleOptionSelect}
                        disabled={data.validation === -5}
                        value={selectedCuentaEgresos || ''} 
                      >
                        <option value=''>Seleccione una opción</option>  {/* Opción vacía */}
                        {dataClasificacion.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nombreCuenta}
                          </option>
                        ))}
                      </select>

                      <label className='block text-gray-700 text-xs font-bold mb-2 ml-5'>Existencias</label>
                      <select
                        className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-lg mb-2'
                        name='idExistencias'
                        onChange={handleOptionSelect}
                        disabled={data.validation === -5}
                        value={selectedExistencias || ''}
                      >
                        <option value=''>Seleccione una opción</option>
                        {dataExistencias.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nombreCuenta}
                          </option>
                        ))}
                      </select>

                      <label className='block text-gray-700 text-xs font-bold mb-2 ml-5'>Activos Fijos</label>
                      <select
                        className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-lg mb-2'
                        name='idActivosFijos'
                        onChange={handleOptionSelect}
                        disabled={data.validation === -5}
                        value={selectedActivosFijos || ''}
                      >
                        <option value=''>Seleccione una opción</option>
                        {dataActivosFijos.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nombreCuenta}
                          </option>
                        ))}
                      </select>

                      <label className='block text-gray-700 text-xs font-bold mb-2 ml-5'>Activos Intangibles</label>
                      <select
                        className='w-80 h-9 block border border-gray-300 px-4 py-2 rounded-lg'
                        name='idActivosIntangibles'
                        onChange={handleOptionSelect}
                        disabled={data.validation === -5}
                        value={selectedActivosIntangibles || ''}
                      >
                        <option value=''>Seleccione una opción</option>
                        {dataActivosIntangibles.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nombreCuenta}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                    <div className='flex items-center my-3 gap-2'>
                      <select
                        className='w-80 h-9 appearance-none focus:outline-none focus:ring-0 block border border-gray-300 hover:border-gray-400 focus:border-gray-400 px-4 py-2 pr-8 rounded-lg shadow leading-tight '
                        name='validation'
                        id='validation'
                        onChange={e => handleOptionSelect(e)}
                        disabled={data.validation === -5}
                      >
                        <option selected disabled>
                          Seleccione temporalidad de la nueva clasificación
                        </option>
                        <option value={1}>Aplicar sólo a este registro</option>
                        <option value={2}>Recordar esta selección en el futuro</option>
                        <option value={3}>Aplicar esta selección a todos los registros</option>
                      </select>
                    </div>
                    <div className='flex justify-center items-center my-3 pt-10'>
                      <CircleCharts data={val.map(slider => slider.value)} />
                    </div>
                  </div>
                </div>
                <div className='flex flex-col gap-3'>
                  <div className='flex justify-between pe-14'>
                    <label htmlFor='date' className='flex flex-col text-gray-700 text-sm font-bold'>
                      <p className='mb-2 ml-3'>Fecha de vencimiento</p>
                      <input
                        className='border border-gray-300 rounded-lg h-9 focus:outline-none focus:ring-0 hover:border-gray-400'
                        type='date'
                        name='fechaVencimiento'
                        value={formatDateToShow(data.fechaVencimiento)}
                        onChange={e => handleOptionSelect(e)}
                        disabled={data.validation === -5}
                      />
                    </label>
                    <div className='flex justify-between'>
                      <CustomSelect
                        id='sucursal'
                        label='Sucursal'
                        options={options2}
                        onChange={e => handleOptionSelect(e)}
                      />
                    </div>
                  </div>
                  <div className='flex justify-between mt-2'>
                    <CustomSelect
                      name='centroCosto'
                      label='Centro de costo'
                      options={options2}
                      onChange={e => handleOptionSelect(0, e.target.value)}
                    />
                    <Box sx={{ width: 150 }}>
                      <Box>
                        <label className='flex flex-col text-gray-700 text-sm font-bold'>
                          Porcentaje
                        </label>
                        <Slider
                          value={val[0].value}
                          onChange={(e, newValue) => handleSliderChange(0, newValue)}
                          aria-labelledby='slider-0'
                          valueLabelDisplay='auto'
                          valueLabelFormat={formatValueLabel}
                          step={1}
                          min={0}
                          max={100}
                        />
                      </Box>
                    </Box>
                  </div>
                  {/* <div className='flex justify-between'> */}
                  <div className='flex flex-col justify-between'>
                    {centrosDeCosto.map((centroDeCosto, index) => (
                      <div key={index} className='flex flex-row justify-between mb-4 mt-4'>
                        <CustomSelect
                          name={`centroCosto${index}`}
                          label={`Centro de costo ${index + 1}`}
                          options={options2}
                          onChange={e => handleOptionSelect(e)}
                          disabled={data.validation === -5}
                        />
                        <Box sx={{ width: 150 }}>
                          <label className='flex flex-col text-gray-700 text-sm font-bold'>
                            {`Porcentaje ${index + 1}`}
                          </label>
                          <Slider
                            value={val[1].value}
                            onChange={(e, newValue) => handleSliderChange(index + 1, newValue)}
                            aria-labelledby={`slider-${index + 1}`}
                            valueLabelDisplay='auto'
                            valueLabelFormat={formatValueLabel}
                            step={1}
                            min={0}
                            max={100}
                            disabled={data.validation === -5}
                          />
                        </Box>
                      </div>
                    ))}
                  </div>
                  {/* </div> */}
                  <button
                    className='text-start text-[#5A7BD6] w-fit px-6'
                    onClick={handleAgregarCentroCosto}
                    disabled={data.validation === -5}
                  >
                    + Agregar centro de costo
                  </button>
                  <div className='flex gap-2 items-center'>
                    <InputCheckBox
                      onClick={handleNoConsiderar}
                      classNameConteiner='flex gap-2'
                      value={-5}
                      name='validation'
                    />
                    <p>No considerar</p>
                  </div>
                  <div className='flex flex-col'>
                    <label htmlFor='comentario'>Comentarios</label>
                    <textarea
                      id='comentario'
                      rows='4'
                      cols='50'
                      value={comentario}
                      onChange={handleComentario}
                      disabled={data.validation === -5}
                    ></textarea>
                    {errorComentario && <span style={{ color: 'red' }}>{errorComentario}</span>}
                    <p>{comentario.length}/40</p>
                  </div>
                </div>
              </section>
            </div>
            <div className='flex justify-end gap-3 mr-16 mb-5'>
              <Button
                onClick={handleClose}
                className='uppercase text-sm bg-primary text-white'
                title='cancelar'
              />
              <Button
                disabled={data?.validation || switchDate ? false : true}
                onClick={handleSubmit}
                className={`uppercase text-sm text-white ${
                  data?.validation || switchDate ? 'bg-accent2 ' : 'bg-[#00000030]'
                }`}
                title='guardar cambios'
              />
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
      <Modal open={modalOpen} setOpen={setModalOpen} />
    </div>
  )
}

export default ModalDialogMotionEditForm
